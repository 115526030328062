<template>
    <el-dialog
            class="BDialog"
            :class="argument.className||''"
            v-loading="argument.loading"
            :visible.sync="show"
            :width="windowWidth < 768 ? '100%' : (argument.width || '30%')"
            :append-to-body="argument.appendToBody || true"
            :fullscreen="argument.fullscreen"
            :custom-class="argument.customClass"
            :show-close="false"
            :close-on-click-modal="argument.closeOnClickModal || false"
            :before-close="closeDialog"
            :top="argument.top">
        <div slot="title">
            <div v-if="argument.isSpecialTitle">
                <slot name="title"></slot>
            </div>
            <div v-else class="dialogTitle" v-html="argument.title || '请指定 Title !!!'"></div>
            <div v-if="argument.showClose !== false" class="closeBtn" @click="closeDialog">
                <i class="el-icon-close"></i>
                <span> 关闭</span>
            </div>
        </div>
        <slot style="margin-top: -60px;"></slot>
    </el-dialog>
</template>

<script>
    export default {
        name: 'Dialog',
        components: {},
        props: {
            argument: {
                type: [Object],
                default: () => {
                    return {
                        show: false,
                        width: '30%',
                        title: '请指定 Title !!!',
                        appendToBody: false,
                        isSpecialTitle: false,
                        enter: null,
                        top:null,
                        className:""
                    }
                }
            }
        },
        data() {
            return {
                windowWidth: window.outerWidth,
            }
        },
        methods: {
            // 关闭弹窗
            closeDialog() {
                if (!this.argument.beforeClose || this.argument.beforeClose()) {
                    this.argument.show = false
                    if (this.argument.closeDialog) this.argument.closeDialog()
                }
            }
        },
        computed: {
            show() {
                return this.argument ? this.argument.show : false
            }
        }
    }
</script>

<style >
    .BDialog {
        margin-bottom: 20px;
    }

	.dialogTitle {
		float: left;
		color: #606266;
	}

    .closeBtn {
        float: right;
        cursor: pointer;
		color: #606266;
    }

   .smallBDialog .el-dialog__body {
        padding: 6px 20px;
    }
</style>

import MainLayout from '@/layout/MainLayout'
import MainContainer from "@/layout/mainContainer/MainContainer";


export default {

    MainLayout,
    MainContainer,

    // 订单调度
    OrderCenterV2: () => import("@/views/factory/commonView/orderCenter/OrderCenterV2"),
    OrderDashboard: () => import("@/views/factory/commonView/orderCenter/OrderDashboard"),
    OrderDispatchCenter: () => import("@/views/factory/commonView/orderCenter/OrderDispatch"),
    OrderInfo: () => import("@/views/factory/commonView/orderCenter/OrderInfo"),
    OrderSearch: () => import("@/views/factory/commonView/orderCenter/OrderSearch"),
    WorkFlowConfig: () => import("@/views/workflow/WorkFlowView"),

    /* 供应商配置 */
    StoreCenter: () => import("@/views/factory/factoryDirector/StoreCenter/StoreCenter"),
    SupplierOrderCenter: () => import("@/views/supplier/SupplierOrderCenter/SupplierOrderCenter"),

    /* 客户管理页面 */
    ClientCenter: () => import("@/views/factory/factoryDirector/clientCenter/ClientCenter"),

    /* 出货中心 */
    ShippingDashboard: () => import("@/views/factory/ShippingClerk/ShippingDashboard/ShippingDashboard"),
    ShippingCenter: () => import("@/views/factory/ShippingClerk/ShippingCenter/ShippingCenter"),
    SendPackItemCenter: () => import("@/views/factory/ShippingClerk/ShippingCenter/SendPackItemCenter"),
    SendPackProduceCenter: () => import("@/views/factory/ShippingClerk/ShippingCenter/SendPackProduceCenter"),

    /* 财务 */
    PaymentRecordView: () => import("@/views/factory/Finance/paymentRecord/PaymentRecordView"),
    FinanceOrderCenter: () => import("@/views/factory/Finance/orderCenter/FinanceOrderCenter"),

    /* 业务页面 */
    SaleClientCenter: () => import("@/views/factory/sale/client/SaleClientCenter"),
    SaleOrderCenter: () => import("@/views/factory/sale/order/SaleOrderCenter"),
    SaleOrderCenterV2: () => import("@/views/factory/sale/order/SaleOrderCenterV2"),
    GenDanOrderCenter: () => import("@/views/factory/sale/order/GenDanOrderCenter"),
    TeamOrderCenter: () => import("@/views/factory/sale/order/TeamOrderCenter"),
    BomDraftOrderOffer: () => import("@/views/factory/sale/order/BomDraftOrderOffer"),
    BomDraftOrderOffer: () => import("@/views/factory/sale/order/BomDraftOrderOffer"),
    AccountStatement: () => import("@/views/factory/sale/order/AccountStatement"),
    SaleCommission: () => import("@/views/factory/sale/order/SaleCommission"),
    SaleOrderView: () => import("@/views/factory/sale/order/SaleOrderView"),
    SaleMag: () => import("@/views/factory/sale/saleMag/SaleMag"),

    // 工程
    SteelNetAndFixtureCenter: () => import("@/views/factory/Engineer/SteelNetFixtureCenter"),
    PhysicalOperatorCenter: () => import("@/views/factory/Engineer/PhysicalOperatorCenter"),
    SteelNetFixtureDashboard: () => import("@/views/factory/Engineer/SteelNetFixtureDashboard"),
    PrepareMaterialCenter: () => import("@/views/factory/commonView/orderCenter/orderStepComponents/prepareMaterial/PrepareMaterialCenter"),
    PrepareMaterialCenterV2: () => import("@/views/factory/commonView/orderCenter/orderStepComponents/prepareMaterial/PrepareMaterialCenterV2"),

    // 点料
    listMaterialCenter: () => import("@/views/factory/receiver/listMaterialCenter"),
    escrowCenter: () => import("@/views/factory/receiver/escrowCenter"),
    escrowShelf: () => import("@/views/factory/receiver/escrowShelf"),
    OrderReturnMaterial: () => import("@/views/factory/receiver/OrderReturnMaterial"),

    // 下单点料验料工艺中心
    OrderSubmitCenter: () => import("@/views/factory/commonView/orderCenter/orderSubmitCenter/OrderSubmitCenter"),
    OrderListCenter: () => import("@/views/factory/commonView/orderCenter/orderListMaterial/OrderListCenter"),
    OrderCheckCenter: () => import("@/views/factory/commonView/orderCenter/orderCheckMaterial/OrderCheckCenter"),
    OrderCraftCenter: () => import("@/views/factory/commonView/orderCenter/OrderCraftCenter"),

    // 客户看板
    ClientOrderDashboard: () => import("@/views/client/clientOrderDashboard/ClientOrderDashboard"),
    ClientPersonalCenter: () => import("@/views/client/ClientPersonalCenter/ClientPersonalCenter"),

    // 贴片首件QC看板
    SmtFirstTestDashboard: () => import("@/views/factory/commonView/orderCenter/orderStepComponents/smtFirst/SmtFirstTestDashboard"),

    // 回流焊QC看板
    SmtReflowDashboard: () => import("@/views/factory/commonView/orderCenter/orderStepComponents/Reflow/SmtReflowDashboard"),

    // XRay看板
    XRayDashboard: () => import("@/views/factory/commonView/orderCenter/orderStepComponents/xray/XRayDashboard"),

    // AOI看板
    AoiOrderCenter: () => import("@/views/factory/commonView/orderCenter/orderStepComponents/aoi/AoiOrderCenter"),
    AoiCheckCenter: () => import("@/views/factory/commonView/orderCenter/orderStepComponents/aoi/AoiCheckCenter"),

    // 尾机看板
    LastMounterOrderCenter: () => import("@/views/factory/commonView/orderCenter/orderStepComponents/smtMaterialPaste/LastMounterOrderCenter"),

    // 清尾看板
    OrderCleanTailCenter: () => import("@/views/factory/commonView/orderCenter/orderStepComponents/cleanTail/OrderCleanTailCenter"),

    // 印刷看板
    OrderSolderPrintCenter: () => import("@/views/factory/commonView/orderCenter/orderStepComponents/solderPrint/OrderSolderPrintCenter"),

    // 插件看板
    OrderPluginDashboard: () => import("@/views/factory/commonView/orderCenter/orderStepComponents/plugin/OrderPluginDashboard"),

    // 成品检看板  
    PcbaQc: () => import("@/views/factory/commonView/orderCenter/orderStepComponents/pcbaQc/PcbaQc"),

    // 洗板看板
    Washboard: () => import("@/views/factory/commonView/orderCenter/orderStepComponents/Washboard/Washboard.vue"),

    //订单点料中心看板
    OrderListCenterBoard: () => import("@/views/factory/commonView/FlowPathConfig/orderClickMat/OrderListCenterPublish.vue"),

    //2024.01.10 订单验料中心看板
    OrderCheckCenterBoard: () => import("@/views/factory/commonView/FlowPathConfig/orderCheckMat/OrderCheckCenterPublish.vue"),

    //2024.01.16 订单备料中心看板
    PrepareMaterialCenterBoard: () => import("@/views/factory/commonView/FlowPathConfig/prepareMaterial/PrepareMaterialCenterBoard.vue"),

    //2024.01.22 工单看板中心
    WorkOrderCenter: () => import("@/views/factory/commonView/FlowPathConfig/WorkOrderCenter.vue"),

    // 工厂配置
    FactoryConfig: () => import("@/views/factory/factoryDirector/FactoryConfig/FactoryConfig"),

    // PCB中心
    PcbCenter: () => import("@/views/project/PcbCenter.vue"),

}

import {errorNotice, successNotice} from './NoticeUtil'
import FileSaver from 'file-saver'
import axios from 'axios'
import store from "@/store";

export function parseTime(time, cFormat) {
  if (!time) return ''

  if (typeof time === 'string') time = parseInt(time)

  if (arguments.length === 0) {
    return null
  }
  const format = cFormat || '{y}/{m}/{d} {h}:{i}'
  let date
  if (typeof time === 'object') {
    date = time
  } else {
    if (('' + time).length === 10) time = parseInt(time) * 1000
    date = new Date(time)
  }
  const formatObj = {
    y: date.getFullYear(),
    m: date.getMonth() + 1,
    d: date.getDate(),
    h: date.getHours(),
    i: date.getMinutes(),
    s: date.getSeconds(),
    a: date.getDay()
  }
  const time_str = format.replace(/{(y|m|d|h|i|s|a)+}/g, (result, key) => {
    let value = formatObj[key]
    if (key === 'a') return ['一', '二', '三', '四', '五', '六', '日'][value - 1]
    if (result.length > 0 && value < 10) {
      value = '0' + value
    }
    return value || 0
  })
  return time_str
}

export function formatTime(time, option) {
  time = +time * 1000
  const d = new Date(time)
  const now = Date.now()

  const diff = (now - d) / 1000

  if (diff < 30) {
    return '刚刚'
  } else if (diff < 3600) { // less 1 hour
    return Math.ceil(diff / 60) + '分钟前'
  } else if (diff < 3600 * 24) {
    return Math.ceil(diff / 3600) + '小时前'
  } else if (diff < 3600 * 24 * 2) {
    return '1天前'
  }
  if (option) {
    return parseTime(time, option)
  } else {
    return d.getMonth() + 1 + '月' + d.getDate() + '日' + d.getHours() + '时' + d.getMinutes() + '分'
  }
}


/**
 * 将时间戳转换成日期给事
 * @param time
 * @returns {string}
 */
export function formatTimeTwo(time) {
  var timeStr = '未确定'
  if (time) {
    const data = new Date(time)
    const year = data.getFullYear()
    var month = data.getMonth() + 1
    month = month < 10 ? '0' + month : month
    var date = data.getDate()
    date = date < 10 ? '0' + date : date
    // const hour = data.getHours()
    // const minute = data.getMinutes()
    // const second = data.getSeconds()
    timeStr = year + '-' + month + '-' + date
  }
  return timeStr
  return formatTimeTwoDetail(time)
}
/**
 * 将时间戳转换成日期给事
 * @param time
 * @returns {string}
 */
export function formatTimeTwoDetail(time) {
  var timeStr = '未确定'
  if (time) {
    const data = new Date(time)
    const year = data.getFullYear()
    var month = data.getMonth() + 1
    month = month < 10 ? '0' + month : month
    var date = data.getDate()
    date = date < 10 ? '0' + date : date
    var hour = data.getHours()
    hour = hour < 10 ? '0' + hour : hour
    var minute = data.getMinutes()
    minute = minute < 10 ? '0' + minute : minute
    // const second = data.getSeconds()
    timeStr = year + '-' + month + '-' + date + ' ' + hour + ':' + minute
  }
  return timeStr
}

export function getTimeCost(sourceTime, targetTime) {
  if (!sourceTime || !targetTime) return ''

  let startTime = new Date(sourceTime).getTime()
  let endTime = new Date(targetTime).getTime()

  //两个时间戳相差的毫秒数
  let usedTime = (endTime - startTime) / 1000;

  /* 如果小于1分钟，直接返回秒 */
  if (usedTime < 60) return usedTime + '秒'

  //计算相差分钟数
  let minutes = Math.floor(usedTime / 60)
  if (minutes < 60) return minutes + '分钟'

  /* 计算出小时数 */
  let hours = Math.floor(minutes / 60);
  minutes %= 60
  if (hours < 24) return hours + '小时' + minutes + '分钟'

  /* 计算出天数 */
  let days = Math.floor(hours / 24);
  hours %= 24
  return days + '天' + hours + '小时'
}

export function formatterUsedTime(usedTime) {
  /* 如果小于1分钟，直接返回秒 */
  if (usedTime < 60) return usedTime + '秒'

  //计算相差分钟数
  let minutes = Math.floor(usedTime / 60)
  let second = Math.round(usedTime % 60)
  if (minutes < 60) return minutes + '分钟' + (second ? second + '秒' : '')

  /* 计算出小时数 */
  let hours = Math.floor(minutes / 60);
  minutes = Math.round(minutes % 60)
  if (hours < 24) return hours + '小时' + (minutes ? minutes + '分钟' : '') + (second ? second + '秒' : '')

  /* 计算出天数 */
  let days = Math.floor(hours / 24);
  hours = Math.round(hours % 24)
  return days + '天' + (hours ? hours + '小时' : '') + (minutes ? minutes + '分钟' : '') + (second ? second + '秒' : '')
}


/**
 * 下载文件
 */
export function newDownload(BFile,show=true) {
  if (!BFile || BFile.path.trim().length <= 0) return errorNotice('没有需要下载的文件')

  let downloadItem = {
    fileName: BFile.fileName,
    url: process.env.VUE_APP_IMAGE_URL + '/' + BFile.path,
    percentage: 0,
    total: 0,
    loaded: 0,
    isAddInList: false,
    startTime: new Date(),
    endTime: null,
    status: '<span class="normalBtn">待下载</span>'
  }

  /* 新版下载请求 */
  axios({
    url: process.env.VUE_APP_IMAGE_URL + '/' + BFile.path,
    params: {},
    method: 'get',
    responseType: 'blob',
    headers: {},
    onDownloadProgress (progress) {
        if (show) {
            /* 未添加到下载清单，则加入 */
            if (!downloadItem.isAddInList) {
                store.dispatch('addItemInDownloader', downloadItem);
                downloadItem.status = '<span class="blueBtn">下载中...</span>';
            }
            /* 计算下载进度 */
            downloadItem.total = progress.total
            downloadItem.loaded = progress.loaded
            downloadItem.percentage = parseInt((progress.loaded / progress.total * 100).toFixed(0))
        }

    }
  }).then(res => {
    if (res.status === 200) {
      /* 如果下载完成 */
      downloadItem.status = '<span class="blueBtn">下载完成，保存到本地中...</span>'
      /* 保存文件中 */
      // let objectUrl = window.URL.createObjectURL(res.data);
      // let a = document.createElement('a')
      // a.href = objectUrl;
      // a.download = BFile.fileName;
      // a.click();
      FileSaver.saveAs(res.data, BFile.fileName)
      /* 设置保存时间 */
      downloadItem.endTime = new Date()
      /* 如果下载完成 */
      downloadItem.status = '<span class="normalBtn">下载完成，已保存到本地！</span>'
    }
  })
}

/**
 * 下载文件
 */
export function oldDownload(fileName, newFileName) {
  if (!fileName || fileName.trim().length <= 0) return errorNotice('没有需要下载的文件')
  const request = new XMLHttpRequest()
  let url = getUrl(fileName) + fileName
  request.open('GET', url, true)
  request.responseType = 'blob'
  request.onload = () => {
    if (request.status === 200) {
      FileSaver.saveAs(request.response, newFileName || fileName)
    }
  }
  request.send()
}

function getUrl(fileName) {
  let paths = fileName.split('/')
  if (paths.length > 1) return process.env.VUE_APP_NEW_FILE_URL
  else return process.env.VUE_APP_OLD_FILE_URL
}

export function isAddFtp(fileName) {
  let paths = fileName.split('/')
  if (paths.length > 1) return true
  else return false
}

/**
 * 下载文件
 */
export function oldDownloadCallback({fileName, newFileName, noFileErrorMsg='无文件，请先上传！', successMessage='下载成功，请检查游览器左下方下载栏!'}) {
  let getSuffix = (file) => {
    let index = file.lastIndexOf('.')
    return file.substring(index, file.length)
  }

  if (!fileName || fileName == '') return errorNotice(noFileErrorMsg)

  oldDownload(fileName, newFileName + getSuffix(fileName))

  successNotice(successMessage)
}

export function copyToClipboard(content) {
  if (window.clipboardData) {
    window.clipboardData.setData('text', content)
  } else {
    (function (content) {
      document.oncopy = function (e) {
        e.clipboardData.setData('text', content)
        e.preventDefault()
        document.oncopy = null
      }
    })(content)
    document.execCommand('Copy')
  }
}

/*
 * 功能：格式化Byte
 * Author：潘溢林
 */
export function formatterByte(total) {
  let k = (total / 1000).toFixed(2);
  if (k < 1024) return k + ' K';

  let m = (k / 1000).toFixed(2);
  if (m < 1024) return m + ' M'

  let g = (m / 1000).toFixed(2);
  if (g < 1024) return g + ' G'

  return k + ' K'
}

export function convertCurrency(money) {
    //汉字的数字
    var cnNums = new Array('零', '壹', '贰', '叁', '肆', '伍', '陆', '柒', '捌', '玖');
    //基本单位
    var cnIntRadice = new Array('', '拾', '佰', '仟');
    //对应整数部分扩展单位
    var cnIntUnits = new Array('', '万', '亿', '兆');
    //对应小数部分单位
    var cnDecUnits = new Array('角', '分', '毫', '厘');
    //整数金额时后面跟的字符
    var cnInteger = '整';
    //整型完以后的单位
    var cnIntLast = '元';
    //最大处理的数字
    var maxNum = 999999999999999.9999;
    //金额整数部分
    var integerNum;
    //金额小数部分
    var decimalNum;
    //输出的中文金额字符串
    var chineseStr = '';
    //分离金额后用的数组，预定义
    var parts;
    if (money == '') {
        return '';
    }
    money = parseFloat(money);
    if (money >= maxNum) {
        //超出最大处理数字
        return '';
    }
    if (money == 0) {
        chineseStr = cnNums[0] + cnIntLast + cnInteger;
        return chineseStr;
    }
    //转换为字符串
    money = money.toString();
    if (money.indexOf('.') == -1) {
        integerNum = money;
        decimalNum = '';
    } else {
        parts = money.split('.');
        integerNum = parts[0];
        decimalNum = parts[1].substr(0, 4);
    }
    //获取整型部分转换
    if (parseInt(integerNum, 10) > 0) {
        var zeroCount = 0;
        var IntLen = integerNum.length;
        for (var i = 0; i < IntLen; i++) {
            var n = integerNum.substr(i, 1);
            var p = IntLen - i - 1;
            var q = p / 4;
            var m = p % 4;
            if (n == '0') {
                zeroCount++;
            } else {
                if (zeroCount > 0) {
                    chineseStr += cnNums[0];
                }
                //归零
                zeroCount = 0;
                chineseStr += cnNums[parseInt(n)] + cnIntRadice[m];
            }
            if (m == 0 && zeroCount < 4) {
                chineseStr += cnIntUnits[q];
            }
        }
        chineseStr += cnIntLast;
    }
    //小数部分
    if (decimalNum != '') {
        var decLen = decimalNum.length;
        for (var i = 0; i < decLen; i++) {
            var n = decimalNum.substr(i, 1);
            if (n != '0') {
                chineseStr += cnNums[Number(n)] + cnDecUnits[i];
            }
        }
    }
    if (chineseStr == '') {
        chineseStr += cnNums[0] + cnIntLast + cnInteger;
    } else if (decimalNum == '') {
        chineseStr += cnInteger;
    }
    return chineseStr;
}

export function UUID() {
  return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
      var r = Math.random() * 16 | 0,
          v = c == 'x' ? r : (r & 0x3 | 0x8);
      return v.toString(16);
  });
}

export async function wait(pro) {
  return await pro
}
import request from '@/utils/RequestPlatUtil'

//报表请求
export function jasperReq(pactStatusParm) {
	return new Promise((resolve, reject) => {
		request({
			url: 'reportForm/reportFormDataByInputStream',
			method: 'POST',
			data: pactStatusParm
		}).then((response) => {
			//重置访问url
			response.data.jasperPath = process.env.VUE_APP_IMAGE_URL + "/" + response.data.jasperPath;
			console.log("接收后台报表信息", response);
			resolve(response.data);
		}).catch(error => {
			reject(error)
		})
	})
}
export function selectEquipmentByMap(data) {
	return request({
		url: '/equipment/selectEquipmentByMap',
		method: 'post',
		data: data
	})
}

<template>
  <el-dialog :visible.sync="dialogVisible" width="60%" append-to-body class="stationConfigDialog"
    :close-on-click-modal="false">
    <span slot="title">
      系统配置
    </span>
    <el-tabs v-model="activeConfigName" @tab-click="handleClick">
      <el-tab-pane label="工单配置" name="pushWorkOrder"></el-tab-pane>
      <el-tab-pane label="本地打印机配置" name="printerConfig"></el-tab-pane>
      <el-tab-pane label="文件下载" name="systemDownload"></el-tab-pane>
    </el-tabs>
    <component :is="componentName" :ref="componentName + 'Ref'"></component>
    <span slot="footer" class="dialog-footer">
      <el-button size="mini" @click="dialogVisible = false">关 闭</el-button>
    </span>
  </el-dialog>
</template>

<script>
import AbsComponent from "@/components/genericComponents/AbsComponent";
import printerConfig from "./stationConfig/printerConfig"
import pushWorkOrder from "./stationConfig/pushWorkOrder"
import systemDownload from "./stationConfig/systemDownload"



export default {
  name: 'stationConfigDialog',
  desc: '工位配置',
  extends: AbsComponent,
  components: { printerConfig, pushWorkOrder,systemDownload },
  props: {},
  data() {
    return {
      dialogVisible: false,
      activeConfigName: 'pushWorkOrder',
      componentName: 'pushWorkOrder',
    }
  },
  created() {
  },
  mounted() { },
  methods: {
    open() {
      this.dialogVisible = true;
      this.$nextTick(() => {
        this.$refs[this.componentName + 'Ref'].loadData();
      })

    },
    loadData() {
    },
    handleClick(tab, event) {
      this.componentName = tab.name;
      this.$nextTick(() => {
        this.$refs[this.componentName + 'Ref'].loadData();
      })
    }
  },
}
</script>

<style scope>
.stationConfigDialog .el-dialog__body {
  padding: 0 20px;
}
</style>
export default {

    EXCEPTION_ACTION_IMAGE: { businessType: 5502, name: '异常动作图片', businessName: 'EXCEPTION_ACTION_IMAGE' },
    CRAFT_ACTION_GROUP_IMAGE: { businessType: 7001, name: '工艺组动作图片', businessName: 'CRAFT_ACTION_GROUP_IMAGE' },
    PCB_T: { businessType: 8000, name: 'PCB_T面', businessName: 'PCB_T' },
    PCB_B: { businessType: 8001, name: 'PCB_B面', businessName: 'PCB_B' },
    QUESTION_IMAGE: { businessType: 5500, name: '问题图片', businessName: 'QUESTION_IMAGE' },
    CLOCK_IN_IMAGE: { businessType: 9000, name: '打卡记录图片', businessName: 'CLOCK_IN_IMAGE' },
    FIRST_TEST_PROGRAM: { businessType: 8018, name: '首件检测程序', businessName: 'FIRST_TEST_PROGRAM' },
    FIRST_TEST_RESULT: { businessType: 8019, name: '首件检测结果', businessName: 'FIRST_TEST_RESULT' },
    FIRST_TEST_IMAGE: { businessType: 8020, name: '首件检测图片', businessName: 'FIRST_TEST_IMAGE' },
    PLUGIN_FIRST_TEST_T: { businessType: 8021, name: '贴片首件FOR插件T面图片', businessName: 'PLUGIN_FIRST_TEST_T' },
    PLUGIN_FIRST_TEST_B: { businessType: 8022, name: '贴片首件FOR插件B面图片', businessName: 'PLUGIN_FIRST_TEST_B' },
    DRAFT_IMAGE: { businessType: 8006, name: '工艺图片', businessName: 'DRAFT_IMAGE' },
    WORK_ORDER_PROCESS_IMAGE: { businessType: 8007, name: '工单处理图片', businessName: 'WORK_ORDER_PROCESS_IMAGE' },
    PRODUCT_IMAGE: { businessType: 8008, name: '产品图片', businessName: 'PRODUCT_IMAGE' },
    QrCodeStoreImage: { businessType: 8009, name: 'QrCode存储图片', businessName: 'QrCodeStoreImage' },
    QrCodeRepairImage: { businessType: 8010, name: 'QrCode维修图片', businessName: 'QrCodeRepairImage' },
    DeliveryImage: { businessType: 8100, name: '快递图片', businessName: 'DeliveryImage' },
    San_Xing_Program_T: { businessType: 8020, name: '三星程序文件夹', businessName: 'San_Xing_Program_T' },
    San_Xing_Program_B: { businessType: 8021, name: '三星程序文件夹', businessName: 'San_Xing_Program_B' },
    ExpressBillPDF: { businessType: 8101, name: '快递单PDF', businessName: 'ExpressBillPDF' },
    ORDER_PROCESS_EXCEPTION_IMAGE: { businessType: 9001, name: '订单工序异常图片', businessName: 'ORDER_PROCESS_EXCEPTION_IMAGE' },

}

<template>
    <div class="uploadBox">
        <el-upload
                v-loading="loading"
                drag
                name="file"
                :before-upload="beforeUpload"
                :headers="getHeaders()"
                :data="getData()"
                :with-credentials="true"
                :action="getUploadImageUrl()"
                :on-success="onSuccess"
                :on-error="onError"
                :accept="acceptFileType"
                :show-file-list="showFileList"
                multiple>
            <div class="el-icon-upload">&nbsp; 请选择/拖拽上传图片</div>
        </el-upload>

        <el-input class="pasteImageInput" size="mini" placeholder="请截图粘贴图片到此处" @paste.native="pasteImg($event)"></el-input>
    </div>
</template>

<script>
    import {getToken} from '@/utils/CookieUtil'
	import AbsComponent from "@/components/genericComponents/AbsComponent";
    import {uploadFile} from "@/api/OriginFileUploadApi";
    import ImgCompressor from 'image-compressor.js'

    export default {
        name: 'BUploadImage',
		extends: AbsComponent,
        props: {
            businessName: {
                type: String,
                default: () => {
                    return 'temp'
                }
            },
            acceptFileType: {
                type: String,
                default: () => {
                    return null
                }
            },
            showFileList: {
                type: Boolean,
                default: () => {
                    return true
                }
            },
            prop: {
                type: String,
                default: () => {
                    return ''
                }
            }
        },
        data() {
            return {
                loading: false,
            }
        },
        methods: {
            /*
             * 功能：获取文件上传的URL
             * Author：潘溢林
             */
            getUploadImageUrl() {
                return process.env.VUE_APP_REQUEST_URL + 'fileUpload/uploadV3'
            },
            /*
             * 功能：获取请求头部信息
             * Author：潘溢林
             */
            getHeaders() {
                return {
                    token: getToken()
                }
            },
            /*
             * 功能：获取请求数据信息
             * Author：潘溢林
             */
            getData() {
                return {
                    businessName: this.businessName
                }
            },
            /*
             * 功能：粘贴图片
             * Author：潘溢林
             */
            pasteImg(e) {
                let content = e.clipboardData.getData('Text')
                if (content) return

                let items = e.clipboardData.items
                let file = null

                for (let i = 0; i < items.length; i++) {
                    if (items[i].type.indexOf("image") !== -1) {
                        file = items[i].getAsFile();
                        break;
                    }
                }

                if (!file) return console.warn("粘贴内容非图片");

                this.beforeUpload(file)
            },
            /*
             * 功能：文件上传前的钩子
             * Author：潘溢林
             */
            beforeUpload(file) {
                let app = this

                /* 压缩图片 */
                console.log('压缩图片：', file)

                new ImgCompressor(file, {
                        quality: 0.6,
                        success: function (result) {
                            console.log('压缩后图片：', result)

                            let tempFile = new window.File([result], result.name, {type: result.type})

                            app.loadingStart({ message: '上传图片中...' })
                            return uploadFile({businessName: this.businessName, file: tempFile}).then(bFile => {
                                app.loadingEnd()
                                /* 上传成功，返回结果 */
                                app.$emit('uploadSuccess', bFile, app.prop)
                            }).catch(response => {
                                app.loadingEnd()
                                this.onError(response, file)
                            })
                        },
                        error: function (err) {
                            console.log('压缩失败：', err)
                        }
                    }
                )

                return false
            },
            /*
             * 功能：文件上传成功时的钩子
             * Author：潘溢林
             */
            onSuccess(response, file, fileList) {
                /*if (response.code != 0) return this.onError(response, file, fileList)
                this.$emit('uploadSuccess', response.data, this.prop)
				this.loadingEnd()*/
            },
            /*
             * 功能：文件上传失败时的钩子
             * Author：潘溢林
             */
            onError(err, file, fileList) {
				this.loadingEnd()
                console.error(err, file, fileList)
            }
        }
    }
</script>

<style>
    .uploadBox .el-upload {
        width: 100%;
        margin-bottom: 5px;
    }

    .uploadBox .el-upload-dragger {
        width: 100%;
        height: 50px;
        line-height: 50px;
    }

    .uploadBox .el-upload-dragger .el-icon-upload {
        margin: 0;
        line-height: 0;
        font-size: 15px;
    }

    .uploadBox {
        line-height: 0;
        margin-bottom: 10px;
    }

    .pasteImageInput input::placeholder {
        text-align: center;
    }
</style>

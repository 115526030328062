export default {
    token: state => { return state.token },
    userInfo: state => { return state.userInfo },
    accountInfo: state => { return state.accountInfo },
    notice: state => { return state.notice },
    loadingStatus: state => { return state.loadingStatus },
    humanInfo: state => { return state.humanInfo },
    isWeChatBrowser: state => { return state.isWeChatBrowser },
    humanIdentities: state => { return state.humanIdentities },
    workPathMap: state => { return state.workPathMap },
    curWorkPath: state => { return state.curWorkPath },
    curIdentity: state => { return state.curIdentity },
    scanQrCode: state => { return state.scanQrCode },
    audio: state => { return state.audio },
    downloader: state => { return state.downloader },
    curPath: state => { return state.curPath },
    curFactory: state => { return state.curFactory },
}

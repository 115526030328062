import Vue from 'vue'
import App from './App.vue'
import Vant from 'vant';
import UmyUi from 'umy-ui'
import ElementUI from 'element-ui';
import router from './router'
import store from './store'
import {getCurIdentity, getCurWorkPath, getIdentities, getToken, getCurFc } from "@/utils/CookieUtil";
import 'vant/lib/index.css';
import 'element-ui/lib/theme-chalk/index.css';
import './style/blt_display.css';
import '@vant/touch-emulator';
import './icon/iconfont.css';

import dataV from '@jiaminghi/data-view'

Vue.config.productionTip = false
Vue.use(Vant)
Vue.use(ElementUI)
Vue.use(UmyUi);
Vue.use(dataV)

/* 系统初始化，获取登录人信息 */
store.commit('SET_TOKEN', getToken())
store.commit('SET_CUR_FACTORY', getCurFc())
store.commit('SET_HUMAN_IDENTITIES', getIdentities())
store.commit('SET_CUR_IDENTITY', getCurIdentity())
store.commit('SET_CUR_WORK_PATH', getCurWorkPath())

/* 系统初始化，获取游览器类型 */
store.dispatch('updateIsWeChatBrowser').then()

new Vue({
  render: h => h(App),
  router,
  store
}).$mount('#app')

// 在vue  main.js文件中加上这个，然后就可以使用vue的快捷指令
Vue.directive("drag", {
  inserted: function(el){
      let odiv = el;   //获取当前元素
      odiv.onmousedown = (e) => {
          //算出鼠标相对元素的位置
          let disX = e.clientX - odiv.offsetLeft;
          let disY = e.clientY - odiv.offsetTop;
          // 为什么用document：如果绑定到元素本身的情况下，鼠标拖动过快，鼠标会离开拖拽的元素，导致拖拽一段距离，拖拽失效的问题
          document.onmousemove = (e)=>{
              //用鼠标的位置减去鼠标相对元素的位置，得到元素的位置
              let left = e.clientX - disX;
              let top = e.clientY - disY;
              //绑定元素位置到positionX和positionY上面
              // this.positionX = top;
              // this.positionY = left;
              let FWidth = document.body.offsetWidth - el.offsetWidth;
              let FHeight = document.body.offsetHeight - el.offsetHeight;
              //移动当前元素
              // console.log('left' + left);
              // console.log('top' + top);
              // 判断当前元素可以活动的区域
              if (left <= 0) {
                odiv.style.left = 0 + 'px';
              } else if (left >= FWidth) {
                odiv.style.left = FWidth + 'px';
              } else if (left > 0) {
                odiv.style.left = left + 'px';
              }
              if (top <= 0) {
                odiv.style.top = 0 + 'px';
              } else if (top >= FHeight) {
                odiv.style.top = FHeight + 'px';
              }  else if (top > 0) {
                odiv.style.top = top + 'px';
              }
          };
          document.onmouseup = (e) => {
              document.onmousemove = null;
              document.onmouseup = null;
          };
      };
  }
});
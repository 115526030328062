export default {
  SET_TOKEN: (state, token) => {
    state.token = token
  },
  SET_USER_INFO: (state, userInfo) => {
    state.userInfo = userInfo
  },
  SET_ACCOUNT_INFO: (state, accountInfo) => {
    state.accountInfo = accountInfo
  },
  SET_NOTICE: (state, notice) => {
    state.notice = notice
  },
  SET_LOADING_STATUS: (state, loadingStatus) => {
    state.loadingStatus = loadingStatus
  },
  SET_HUMAN_INFO: (state, humanInfo) => {
    state.humanInfo = humanInfo
  },
  SET_HUMAN_IDENTITIES: (state, humanIdentities) => {
    state.humanIdentities = humanIdentities
  },
  SET_IS_WE_CHAT_BROWSER: (state, isWeChatBrowser) => {
    state.isWeChatBrowser = isWeChatBrowser
  },
  SET_CUR_WORK_PATH: (state, curWorkPath) => {
    state.curWorkPath = curWorkPath
  },
  SET_CUR_IDENTITY: (state, curIdentity) => {
    state.curIdentity = curIdentity
  },
  SET_SCAN_QR_CODE: (state, scanQrCode) => {
    state.scanQrCode = scanQrCode
  },
  SET_AUDIO: (state, { src }) => {
    state.audio.src = src
    state.audio.refresh++
  },
  ADD_DOWNLOAD_ITEM: (state, item) => {
    state.downloader.list.push(item);
    state.downloader.show = true
    state.downloader.refresh++
  },
  CLEAR_DOWNLOADED_ITEM: (state) => {
    state.downloader.list = state.downloader.list.filter(item => item.percentage < 100)
    state.downloader.refresh++
  },
  SET_CUR_PATH: (state, curPath) => {
    state.curPath = curPath
  },
  SET_CUR_FACTORY: (state, curFactory) => {
    state.curFactory = curFactory
  },
}

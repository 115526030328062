<template>
    <b-dialog-v2 :argument="dialog">
        <div slot="title">
            <el-row>
                <el-col :span="12">
                    <el-tabs v-model="dialog.tab" @tab-click="onChangeTab">
                        <el-tab-pane name="未处理消息">
                            <span slot="label">
                                <span>未处理消息</span>
                                <span :class="dialog.unReadTotal ? 'redBtn' : 'normalBtn'">【{{dialog.unReadTotal}}】</span>
                            </span>
                        </el-tab-pane>
                        <el-tab-pane label="已处理消息" name="已处理消息"></el-tab-pane>
                    </el-tabs>
                </el-col>
                <el-col :span="12" style="text-align: right;">
                    <el-button size="mini" icon="el-icon-error" @click="closeDialog">关闭</el-button>
                </el-col>
            </el-row>

            <div v-if="isEmptyList(dialog.messages)">
                <el-empty :description="'无' + dialog.tab"></el-empty>
            </div>

            <div v-else>
                <div class="MessageBox" v-for="(message, messageIndex) of dialog.messages">
                    <div class="MessageBoxTitle">
                        <el-row>
                            <el-col :span="12">
                                <span :class="message.isRead === 'N' ? 'redBtn' : 'greenBtn'">{{ message.isRead === 'Y' ? '已处理' : '未处理' }}消息{{ messageIndex + 1 }}、</span>
                                <span>{{ message.messageTitle }}</span>
                            </el-col>
                            <el-col :span="12" style="text-align: right;">
                                <el-button v-if="message.isRead === 'N'" type="primary" size="mini" icon="el-icon-success" @click="onReadMessage(message)"> 消息已处理</el-button>
                            </el-col>
                        </el-row>

                    </div>
                    <div class="MessageBoxContent" v-html="message.messageContent"></div>

                    <div class="normalBtn" style="font-size: 12px; text-align: right;">
                        <span>推送人：{{ message.creatorName }}_{{ formatterTime(message.createTime) }}</span>
                        <el-divider v-if="message.isRead === 'Y'" direction="vertical"></el-divider>
                        <span v-if="message.isRead === 'Y'">已处理：{{ message.messageTargetName }}_{{ formatterTime(message.readTime) }}</span>
                    </div>
                </div>

                <div v-if="dialog.total > 20" style="text-align: right; margin-top: 10px;">
                    <el-pagination background layout="total, prev, pager, next" :current-page="dialog.pageNum" :page-size="dialog.pageSize" :total="dialog.total" @current-change="refresh"></el-pagination>
                </div>
            </div>

            
        </div>
    </b-dialog-v2>
</template>

<script>
    import AbsComponent from "@/components/genericComponents/AbsComponent";
    import BDialogV2 from "../genericComponents/element/BDialogV2.vue";
    import { MessageNoticeRead, MessageNoticeGet } from '@/api/commonApi'

    export default {
        name: "MessageNoticeDialog",
        extends: AbsComponent,
        components: { BDialogV2 },
        data() {
            return {
                dialog: {
                    show: false,
                    appendToBody: true,
                    closeOnClickModal: false,
                    isSpecialTitle: true,
                    showClose: false,
                    width: '800px',
                    tab: '未处理消息',
                    total: null,
                    unReadTotal: null,
                    messages: null,
                    pageNum: 1,
                    pageSize: 20,
                }
            }
        },
        methods: {
            /*
             * 功能：打开弹窗
             * 作者：潘溢林
             * 日期：23/05/18 15:46
             */
            open() {
                this.dialog.show = true
                this.dialog.tab = '未处理消息'
                this.dialog.pageNum = 1
                this.refresh()
            },
            /*
             * 功能：刷新
             * 作者：潘溢林
             * 日期：23/05/18 16:10
             */
            refresh() {
                this.loadingStart({ message: '获取' + this.dialog.tab + '中...' })
                return MessageNoticeGet({
                    isRead: this.dialog.tab === '未处理消息' ? 'N' : 'Y',
                    pageNum: this.dialog.pageNum,
                    pageSize: this.dialog.pageSize,
                }).then(res => {
                    this.dialog.total = res.total
                    this.dialog.unReadTotal = res.unReadTotal
                    this.dialog.messages = res.messages
                })
            },
            /*
             * 功能：关闭弹窗
             * 作者：潘溢林
             * 日期：23/05/18 16:34
             */
            closeDialog() {
                this.dialog.show = false
            },
            /*
             * 功能：已处理消息
             * 作者：潘溢林
             * 日期：23/05/18 17:12
             */
            onReadMessage(message) {
                this.openDialogV2({ title: '消息已处理？', message: '<span class="redBtn">请确保您已处理消息，再进行确认，防止忘记处理！</span>' }).then(res => {
                    this.loadingStart({ message: '已处理消息...' })
                    return MessageNoticeRead({
                        pkMessageNoticeId: message.pkMessageNoticeId
                    }).then(res => {
                        this.refresh()
                    })
                })
            },
            /*
             * 功能：改变Tab事件
             * 作者：潘溢林
             * 日期：23/05/18 17:41
             */
            onChangeTab() {
                this.dialog.pageNum = 1
                this.refresh()
            },
        },
    }
</script>

<style scope>

    .MessageBox {
        padding: 10px;
        font-size: 14px;
        border-bottom: 1px solid #ebebeb;
    }

    .MessageBox:last-child {
        border-bottom: none;
    }
    
    .MessageBox:hover {
        background-color: #fafafa;
    }

    .MessageBoxTitle {
        font-weight: bold;
        margin-bottom: 10px;
    }

    .MessageBoxContent {
        font-size: 12px;
        margin-bottom: 10px;
    }

</style>
<template>
  <div class="printerConfig">
    <el-row :gutter="10">
      <el-col :span="12">
        <el-table :data="tableData" height="300" border>
          <el-table-column prop="ip" align="center" label="打印机IP" width="120">
          </el-table-column>
          <el-table-column prop="printerName" header-align="center" label="打印机名称">
          <template v-slot="{row}">
            <el-button type="text" icon="el-icon-edit" ></el-button>
            {{ row.printerName }}
          </template>
          </el-table-column>
          <el-table-column
            prop="printerPage"
            align="center"
            label="打印机类型"
            width="200"
          >
          </el-table-column>
        </el-table>
      </el-col>
      <el-col :span="12">
        <el-card header="添加打印设备">
          <el-form
            label-position="right"
            label-width="100px"
            size="mini"
            :model="configForm"
            ref="configFormRef"
          >
            <el-form-item
              label="当前设备"
              prop="deviceCode"
              :rules="[{ required: true, message: '请输入当前设备', trigger: 'blur' }]"
            >
              <el-select
                style="width: 100%"
                v-model="configForm.deviceCode"
                placeholder="请选择"
                @change="loadPrinterData"
              >
                <el-option
                  v-for="item in deviceData"
                  :key="item.label"
                  :label="item.label"
                  :value="item.label"
                >
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item
              label="打印机IP"
              prop="ip"
              :rules="[{ required: true, message: '请输入IP', trigger: 'blur' }]"
            >
              <el-input
                v-model="configForm.ip"
                clearable
                :disabled="disabledIP"
              ></el-input>
            </el-form-item>
            <el-form-item
              label="打印机名称"
              prop="printerName"
              :rules="[{ required: true, message: '请输入打印机名称', trigger: 'blur' }]"
            >
              <el-select
                v-model="configForm.printerName"
                placeholder="请选择"
                style="width: 76%"
              >
                <el-option
                  v-for="item in printerData"
                  :key="item"
                  :label="item"
                  :value="item"
                >
                </el-option>
              </el-select>
              <el-button
                type="primary"
                plain
                style="margin-left: 10px"
                @click="getPrinterInfo"
                >查询打印机</el-button
              >
            </el-form-item>
            <el-form-item
              label="打印机类型"
              prop="printerType"
              :rules="[{ required: true, message: '请选择打印机类型', trigger: 'blur' }]"
            >
              <el-cascader
                v-model="configForm.printerType"
                :options="printerLabelData"
                clearable
                placeholder="用户打印机调用使用"
                :props="{ emitPath: false, expandTrigger: 'hover' }"
                style="width: 100%"
              ></el-cascader>
            </el-form-item>
            <el-form-item>
              <el-button type="primary" @click="submitForm">保 存</el-button>
            </el-form-item>
          </el-form>
        </el-card>
      </el-col>
    </el-row>
  </div>
</template>

<script>
import AbsComponent from "@/components/genericComponents/AbsComponent";
import localStoreObj from "@/api/localStorage";
import { getPrinter } from "@/api/localDynamicsHostApi.js";
import {
  getPrinterConfigApi,
  getBoardTypePrinterApi,
  addDevicePrinterApi,
} from "@/api/authCenter.js";
export default {
  name: "printerConfig",
  desc: "工位打印机配置",
  extends: AbsComponent,
  components: {},
  props: {},
  data() {
    return {
      tableData: [],
      showAddConfig: false,
      configForm: {
        ip: "127.0.0.1",
        printerName: "",
        printerType: "",
        deviceCode: null,
      },
      printerLabelData: [],
      printerData: [],
      deviceData: [],
      disabledIP: false,
      boardCode: false,
    };
  },
  created() {
    getPrinterConfigApi().then((rsp) => {
      this.printerLabelData = rsp;
    });
    this.loadData();
  },
  mounted() {},
  methods: {
    loadData(boardCode) {
      //2024.04.23 后台获取
      // const printerConfig = localStoreObj.getPrinter();
      // this.tableData = JSON.parse(printerConfig);
      var deviceInfo = localStoreObj.getDeviceCodeObj();
      if(deviceInfo){
        Object.keys(deviceInfo).map((key) => {
          this.deviceData.push({
            label:"【"+key+"】"+ deviceInfo[key],
            value: deviceInfo[key]
          });
      });
      }
      this.boardCode = boardCode;
      this.loadPrinterData();
    },
    loadPrinterData(){
      var deviceCode=this.configForm.deviceCode;
      deviceCode=deviceCode.substring(deviceCode.indexOf("】")+1);
      getBoardTypePrinterApi(this.boardCode,deviceCode).then((rsp) => {
        this.tableData = rsp;
      });
    },
    submitForm() {
      this.$refs.configFormRef.validate((valid) => {
        if (valid) {
          var data = Object.assign({}, this.configForm);
          data.boardType = this.boardCode;
          addDevicePrinterApi(data).then((rsp) => {
            this.loadData(this.boardCode);
            this.configForm = {
              ip: "127.0.0.1",
              printerName: "",
              printerType: "",
              deviceCode: null,
            };
          });
        } else {
          return false;
        }
      });
    },
    /**
     * 获取打印机配置
     */
    getPrinterInfo() {
      getPrinter(this.configForm.ip).then((rsp) => {
        this.printerData = rsp;
      });
    },
  },
};
</script>

<style scope></style>

<template>
    <b-dialog-v2 :argument="dialog">
        <lookup-factory-material-component />
    </b-dialog-v2>
</template>

<script>
    import BDialogV2 from "@/components/genericComponents/element/BDialogV2";
    import AbsComponent from "@/components/genericComponents/AbsComponent";
    import LookupFactoryMaterialComponent from "@/views/factory/factoryDirector/ProductionSupplement/LookupFactoryMaterialComponent";

    export default {
        name: "FactoryMaterialSearcher",
        extends: AbsComponent,
        components: {BDialogV2, LookupFactoryMaterialComponent},
        data() {
            return {
                dialog: {
                    show: false,
                    width: '500px',
                    title: '厂供料搜索',
                    appendToBody: true,
                    searchContent: '',
                },

            }
        },
        methods: {
            /*
             * 功能：打开弹窗
             * Author：潘溢林
             */
            open() {
                this.dialog.show = true
            },
        }
    }
</script>

<style scoped>
</style>

import { jasperReq, selectEquipmentByMap } from '@/api/printServer/proc'
import { pushToPrinter } from '@/api/printServer/printServer'
import store from '@/store'
import {errorNotification} from "@/utils/NoticeUtil";


/**
 * 打印报表 -> PDF 格式
 * reportData:报表数据
 * printConfigParm：打印配置  { fkFactoryId: 工厂ID, equipmentType: 类型, equipmentNo: 设备编号}
 */
export function printJasperReportByPDF(reportData, printConfigParm) {
	store.dispatch('loading', { loading: true, message: '打印中...' })

	return jasperReq(reportData).then(response => {
		console.log(response)
		selectEquipmentByMap({
			fkFactoryId: printConfigParm.fkFactoryId
		}).then(res => {
			store.dispatch('loading', { loading: false })
			startPrinting(res, response, printConfigParm)
		})
	}).catch(error => {
		store.dispatch('loading', { loading: false })
		console.error(error)
	});
}

/**
 * 打印报表 -> PDF 格式
 * reportData:报表数据
 * printConfigParm：打印配置  {fkFactoryId:工厂ID，equipmentType:类型,equipmentNo:设备编号}
 */
export function printJasperReportByBFile(bFile, printConfig) {
	return selectEquipmentByMap({
		fkFactoryId: bFile.fkFactoryId
	}).then(res => {
		let equipments = res.data[printConfig.equipmentType]
		if (!equipments || !equipments.length) return errorNotification({ message: '工厂为配置打印机类型：' + printConfig.equipmentType })

		/* 获取设备 */
		let equipment = equipments[0]

		/* 获取设备详情 */
		let extendsMessages = equipment.equipmentExtendMessageDoMap
		if (!extendsMessages || !extendsMessages.length) return errorNotification({ message: '未配置打印机详情：' + JSON.stringify(equipment) })

		let equipmentValue = eval('(' + extendsMessages[0].equipmentValue + ')')[0]

		let path = process.env.VUE_APP_IMAGE_URL + '/' + bFile.path

		pushToPrinter({
			equipment,
			content: path,
			options: {
				printerName: equipmentValue.equipmentName,
				orientation: equipmentValue.orientation,
				printFileName: path,
				width: equipmentValue.width,
				height: equipmentValue.height
			}
		})
	})
}

/**
 *  开始打印
 */
function startPrinting(res, response, printConfigParm) {
	let printConfig = getPrintInfo(res.data[printConfigParm.equipmentType], printConfigParm.equipmentNo)
	let config = getPrintDataConfig(printConfig, response)
	config.forEach(k => {
		pushToPrinter({
			equipment: printConfig,
			content: k.printFileName,
			options: k
		})
	});

}

/**
 * 获取打印机
 */
function getPrintInfo(data, code) {
	let printConfig = null
	data.forEach(k => {
		if (k.equipmentNo === code) printConfig = k
	})
	return printConfig
}
/**
 *  获取打印配置信息
 */
function getPrintDataConfig(printConfig, response) {
	let config = []
	printConfig.equipmentExtendMessageDoMap.forEach(row => {
		console.warn(row)
		let str = eval('(' + row.equipmentValue + ')')
		let obj = str.map(k => {
			return {
				printerName: k.equipmentName,
				orientation: k.orientation,
				printFileName: response.jasperPath || response.data.jasperPath,
				width: k.width,
				height: k.height
			}
		})
		config = config.concat(obj)
	})
	return config
}

<template>
    <div class="MenuContainer">
        <!-- 当前路由 -->
        <b-menu-container v-if="curWorkPath" :argument="bMenuContainer"/>
        <!-- 无路由 -->
        <div v-else>
            无路由
        </div>
    </div>
</template>

<script>
    import AbsComponent from "@/components/genericComponents/AbsComponent";
    import BMenuContainer from "@/layout/components/BMenuContainer";
    import { mapGetters } from 'vuex'

    export default {
        name: "MenuContainer",
        extends: AbsComponent,
        components: { BMenuContainer },
        computed: {
            ...mapGetters([
                'curWorkPath' // 工厂ID
            ])
        },
        data() {
            return {
                bMenuContainer: {
                    mode: 'horizontal',
                    collapse: false,
                    activeTextColor: '#409EFF',
                }
            }
        },
    }
</script>

<style scoped>
    .MenuContainer {}
</style>

export default {

    physicalOperator: { code: 'physicalOperator', isFormatter: true },
    Operator: { code: 'operator', isFormatter: true },
    Operators: { code: 'operators', isFormatter: true },
    outLog: { code: 'outLog', isFormatter: false },
    SenderInfo: { code: 'SenderInfo', isFormatter: true },
    HumanCode: { code: 'HumanCode', isFormatter: false },
    historyOperatorCodes: { code: 'historyOperatorCodes', isFormatter: true },
    LoginType: { code: 'loginType', isFormatter: false },

}

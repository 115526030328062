<template>
    <b-form-dialog-v2 :dialog="addOperatorDialog" @enter="saveOperatorName"/>
</template>

<script>
    import AbsComponent from "@/components/genericComponents/AbsComponent";
    import BFormDialogV2 from "@/components/genericComponents/element/BFormDialogV2";
    import CookieEnum from "@/enums/CookieEnum";
    import TypeCodeEnum from "@/enums/TypeCodeEnum";
    import {ElementType} from "@/components/genericComponents/enum/BElementEnum";
    import {setCookie, getCookie} from "@/utils/CookieUtil";
    import {TypeCodeGet} from "@/api/commonApi";
    import {PhysicalOperatorAdd} from "@/api/commonApi";

    export default {
        name: "BindOperatorDialog",
        extends: AbsComponent,
        components: {BFormDialogV2},
        data() {
            return {
                addOperatorDialog: {
                    show: false,
                    width: '500px',
                    title: '本机工位',
                    appendToBody: true,
                    form: {
                        labelWidth: '100px',
                        isShowFooterButton: true,
                        data: {
                            operatorName: null,
                            operatorType: null,
                            operatorMac: null,
                            operatorIp: null,
                            operatorIsStaticIp: 'N',
                            operatorTypes: [],
                            operatorLogin: 'N',
                        },
                        formItems: [
                            {
                                label: '工位名称',
                                prop: 'operatorName',
                                type: ElementType.INPUT,
                                rule: { required: true },
                            },
                            {
                                label: '本机MAC',
                                prop: 'operatorMac',
                                type: ElementType.INPUT,
                                rule: { required: true },
                            },
                            {
                                label: '本机IP',
                                prop: 'operatorIp',
                                type: ElementType.INPUT,
                                rule: { required: true },
                                show: false,
                            },
                            {
                                label: '静态IP',
                                prop: 'operatorIsStaticIp',
                                type: ElementType.Select,
                                rule: { required: true },
                                options: [
                                    { label: '是(静态IP)', value: 'Y' },
                                    { label: '否(动态IP)', value: 'N' },
                                ],
                                change: () => {
                                    this.addOperatorDialog.form.formItems[2].show = this.addOperatorDialog.form.data.operatorIsStaticIp === 'Y'
                                }
                            },
                            {
                                label: '主工位',
                                prop: 'operatorType',
                                type: ElementType.Select,
                                rule: { required: true },
                                options: []
                            },
                            {
                                label: '次工位',
                                prop: 'operatorTypes',
                                type: ElementType.Select,
                                multiple: true,
                                rule: { required: false },
                                options: []
                            },
                            {
                                label: '工位登录',
                                prop: 'operatorLogin',
                                type: ElementType.Select,
                                rule: { required: true },
                                options: [
                                    { label: '开启', value: 'Y' },
                                    { label: '关闭', value: 'N' },
                                ]
                            },
                        ]
                    }
                },
            }
        },
        created() {
            // 获取工位类型
            TypeCodeGet({
                typeCategory: TypeCodeEnum.OperatorType.code
            }).then(res => {
                let fisrtTypeFormItem = this.getFormItem(this.addOperatorDialog.form, '主工位')
                let secondTypeFormItem = this.getFormItem(this.addOperatorDialog.form, '次工位')

                fisrtTypeFormItem.options = res.map(typeCode => {
                    return {
                        label: typeCode.typeDisplayName,
                        value: typeCode.typeCode
                    }
                })

                secondTypeFormItem.options = res.map(typeCode => {
                    return {
                        label: typeCode.typeDisplayName,
                        value: typeCode.typeCode
                    }
                })
            })
        },
        methods: {
            /*
             * 功能：打开弹窗
             * Author：潘溢林
             */
            open() {
                let operator = getCookie(CookieEnum.physicalOperator.code, true)

                this.addOperatorDialog.show = true
                this.addOperatorDialog.form.data.operatorName = operator.operatorName
                this.addOperatorDialog.form.data.operatorType = operator.operatorType
                this.addOperatorDialog.form.data.operatorMac = operator.operatorMac
                this.addOperatorDialog.form.data.operatorIp = operator.operatorIp
                this.addOperatorDialog.form.data.operatorIsStaticIp = operator.operatorIsStaticIp
                this.addOperatorDialog.form.data.operatorLogin = operator.operatorLogin
                this.addOperatorDialog.form.data.operatorTypes = getCookie(CookieEnum.Operators.code, true)
                this.addOperatorDialog.form.formItems[2].show = this.addOperatorDialog.form.data.operatorIsStaticIp === 'Y'
            },
            /*
             * 功能：保存工位名称
             * Author：潘溢林
             */
            saveOperatorName() {
                this.loadingStart({ message: '绑定工位...' })
                return PhysicalOperatorAdd(this.addOperatorDialog.form.data).then(res => {
                    this.addOperatorDialog.show = false
                    this.operator = res
                    this.operatorTypes = this.addOperatorDialog.form.data.operatorTypes.filter(o => o !== res.operatorType)

                    /* 保存到Cookie */
                    setCookie(CookieEnum.physicalOperator.code, res)

                    /* 保存到Cookie */
                    setCookie(CookieEnum.Operators.code, this.operatorTypes || [])

                    this.addOperatorDialog.form.data.operatorName = null
                    this.addOperatorDialog.form.data.operatorType = null
                    this.addOperatorDialog.form.data.operatorMac = null
                    this.addOperatorDialog.form.data.operatorIp = null
                    this.addOperatorDialog.form.data.operatorIsStaticIp = 'N'
                    this.addOperatorDialog.form.data.operatorLogin = 'N'                    
                    this.addOperatorDialog.form.data.operatorTypes = []
                    this.addOperatorDialog.form.formItems[2].show = this.addOperatorDialog.form.data.operatorIsStaticIp === 'Y'

                    this.$emit('saveOperator')
                })
            },
        }
    }
</script>

<style scoped>

</style>

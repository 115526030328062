<template>
    <el-dialog title="下载清单"
               width="100%"
               :append-to-body="true"
               :visible.sync="downloader.show">
        <div v-if="isEmptyList(downloader.list)">占无下载任务！</div>
        <div v-for="(item, itemIndex) of downloader.list"
             :key="itemIndex"
             v-bind:class="item.total === item.loaded ? 'greenBorder' : 'blueBorder'"
             class="downloadItem">
            <table>
                <tr>
                    <td>下载任务{{downloader.list.length - itemIndex}}：</td>
                    <td>{{downloader.list[downloader.list.length - itemIndex - 1].fileName}}</td>
                </tr>
                <!--<tr>
                    <td>下载地址：</td>
                    <td>{{item.url}}</td>
                </tr>-->
                <tr>
                    <td>下载状态：</td>
                    <td>
                        <!-- 进度条 -->
                        <div>
                            <el-progress :percentage="downloader.list[downloader.list.length - itemIndex - 1].percentage" :color="[
                              {color: '#409EFF', percentage: 99},
                              {color: '#67C23A', percentage: 100}
                            ]"></el-progress>
                        </div>
                        <div>
                            <span>文件大小：{{formatterByte(downloader.list[downloader.list.length - itemIndex - 1].total)}}</span>，
                            <span>已下载：{{formatterByte(downloader.list[downloader.list.length - itemIndex - 1].loaded)}}</span>，
                            <span>下载耗时：{{formatterCost(downloader.list[downloader.list.length - itemIndex - 1])}}</span>，
                            <span v-html="downloader.list[downloader.list.length - itemIndex - 1].status"></span>
                        </div>
                    </td>
                </tr>
            </table>
        </div>
    </el-dialog>
</template>

<script>
    import AbsComponent from "@/components/genericComponents/AbsComponent";
    import {mapGetters} from 'vuex'
    import {getTimeCost} from "@/utils";

    export default {
        name: "GlobalDownloader",
        extends: AbsComponent,
        computed: {
            ...mapGetters([
                'downloader'
            ])
        },
        methods: {
            /*
             * 功能：格式化Byte
             * Author：潘溢林
             */
            formatterByte(total) {
                let k = (total / 1000).toFixed(2);
                if (k < 1024) return k + ' K';

                let m = (k / 1000).toFixed(2);
                if (m < 1024) return m + ' M'

                let g = (m / 1000).toFixed(2);
                if (g < 1024) return g + ' G'

                return k + ' K'
            },
            /*
             * 功能：格式化Byte
             * Author：潘溢林
             */
            formatterCost(item) {
                let startTime = item.startTime
                let endTime = item.endTime || new Date()
                return getTimeCost(startTime, endTime)
            },
        },
        watch: {
            'downloader.show': function() {
                // 当显示弹窗，直接返回
                if (this.downloader.show) return
                // 清除已下载的任务
                this.$store.commit('CLEAR_DOWNLOADED_ITEM')
                console.warn('downloader.show：', this.downloader)
            },
        }
    }
</script>

<style scoped>
    .downloadItem {
        padding: 10px;
        margin-bottom: 10px;
        border-radius: 10px;
    }

    .downloadItem tr td:nth-child(1) {
        width: 80px;
        vertical-align: top;
    }

    .blueBorder {
        border: 1px solid #409EFF;
    }

    .greenBorder {
        border: 1px solid #67C23A;
    }
</style>

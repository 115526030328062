import { Notification } from 'element-ui';

export function errorNotification({ title='错误', message, duration=2500 }) {
    Notification({
        title,
        message,
        type: 'error',
        dangerouslyUseHTMLString: true,
        duration
    })
}

export function successNotification({ title='成功', message, duration=2500 }) {
    Notification({
        title,
        message,
        type: 'success',
        dangerouslyUseHTMLString: true,
        duration
    })
}

export function notification({ title='提示', message, type='info', duration=2500 }) {
    Notification({
        title,
        message,
        type,
        dangerouslyUseHTMLString: true,
        duration
    })
}

export function warningNotice(message) {
    notification({ type: 'warning', message })
}

export function errorNotice(message) {
    errorNotification({ message })
}

export function successNotice(message) {
    successNotification({ message })
}

export function successToast(message) {
    successNotification({ message })
}

export function errorToast(message) {
    errorNotification({ message })
}

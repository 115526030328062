/**
 * 功能：推送到打印机
 * Author：潘溢林
 * @param equipment 设备对象
 * @param content 打印的内容 URL
 * @param options 打印的选项
 *
 *  previewFlag  1 ：预览  0 ：打印
 */
export function pushToPrinter({
	equipment,
	content,
	options
}) {
	console.warn(
		'pushToPrinter：',
		equipment,
		content,
		options)

	/* 获取WebSocket实例 */
	let ws = createWs({
		equipment,
		content,
		options
	})
	console.log(process.env.VUE_APP_WEBSOCKET, ws)
	/* 添加监听打开事件 */
	ws.addEventListener('open', function(event) {
		webSocketOnOpen({
			ws,
			event,
			equipment,
			content,
			options
		})
	})

	/* 添加监听关闭事件 */
	ws.addEventListener('close', function(event) {
		webSocketOnClose({
			ws,
			event,
			equipment,
			content,
			options
		})
	})

	/* 添加监听接受信息事件 */
	ws.addEventListener('message', function(event) {
		webSocketOnMessage({
			ws,
			event,
			equipment,
			content,
			options
		})
	})

	/* 添加监听错误事件 */
	ws.addEventListener('error', function(event) {
		webSocketOnError({
			ws,
			event,
			equipment,
			content,
			options
		})
	})

}

/**
 * 功能：创建WebSocket
 * Author：潘溢林
 */
function createWs(data) {
	return new WebSocket(process.env.VUE_APP_WEBSOCKET + '://' + data.equipment.ip + ':' + data.equipment.port)
}

/**
 * 功能：WebSocket打开
 * Author：潘溢林
 */
function webSocketOnOpen({
	ws,
	event,
	equipment,
	content,
	options
}) {
	console.warn('webSocketOnOpen：', ws, event, equipment, content, options)
	ws.send(JSON.stringify(options));
}

/**
 * 功能：WebSocket接收消息
 * Author：潘溢林
 */
function webSocketOnMessage({
	ws,
	event
}) {
	let result = JSON.parse(event.data)
	if (result.resultCode === '0') {
		ws.close()
	}
}

/**
 * 功能：WebSocket关闭
 * Author：潘溢林
 */
function webSocketOnClose({
	ws,
	event,
	equipment,
	content,
	options
}) {
	console.log(ws,
		event,
		equipment,
		content,
		options)
}

/**
 * 功能：WebSocket错误
 * Author：潘溢林
 */
function webSocketOnError({
	ws,
	event,
	equipment,
	content,
	options
}) {
	console.error('webSocketOnError：', ws, event, equipment, content, options)
}

<template>
  <div class="form">
    <el-form class="margin0Form" ref="form" v-loading="formLoading" :model="formData" :label-position="form.labelPosition || 'left'"
             :label-width="form.labelWidth || '100px'">
      <el-row>
        <el-col v-for="(formItem, formItemIndex) of formItems"
                v-if="formItem.show !== false"
                :key="formItemIndex"
                :span="formItem.span || 24">

          <div v-if="formItem.type==getType().Title"
               style="width:100%; color: #409EFF; border-bottom: 1px solid #409EFF;"
               class="title-text">{{formItem.label}}
          </div>

          <el-form-item v-else :rules="getRules(formItem)" :prop="formItem.prop" :label="formItem.label + (formItem.colon || '：')">
            <!--<div slot="label">
              {{formItem.label + (formItem.colon || '：')}}
            </div>-->

            <span v-if="!formItem.type">请指定FormItem.Type属性</span>

            <!-- Rander -->
            <span v-else-if="formItem.type === getType().RENDER">
              <span class="ellipsis" v-if="formItem.formatter" v-html="formItem.formatter()"></span>
              <span class="ellipsis" v-else v-html="formData[formItem.prop]"></span>
            </span>

            <!-- Component -->
            <span v-else-if="formItem.type === getType().Component">
              <component :is="formItem.component" :config="formData[formItem.prop]"/>
            </span>

            <!-- INPUT -->
            <el-input v-else-if="formItem.type === getType().INPUT"
                      v-model="formData[formItem.prop]"
                      :placeholder="getPlaceHolder(formItem)"
                      :size="getSize(formItem)"
                      :disabled="formItem.disabled"
                      :clearable="formItem.clearable || true"
                      :suffix-icon="formItem.suffixIcon"
                      :prefix-icon="formItem.prefixIcon"
                      :fetch-suggestions="formItem.remoteMethod"
                      @change="change(formItem)"
                      @input="input(formItem)"
                      :show-word-limit="formItem.showWordLimit || true">

              <!-- 后置按钮 -->
              <el-button v-if="formItem.appendBtnShow"
                         slot="append"
                         :icon="formItem.appendBtnIcon"
                         :disabled="formItem.appendBtnDisabled"
                         @click="formItem.appendBtnClick(formItem)">
                <span v-html="formItem.appendBtnTxt"></span>
              </el-button>

              <!-- 前置按钮 -->
              <el-button v-if="formItem.prependBtnShow"
                         slot="prepend"
                         :icon="formItem.prependBtnIcon"
                         :disabled="formItem.prependBtnDisabled"
                         @click="formItem.prependBtnClick(formItem)">
                <span v-html="formItem.prependBtnTxt"></span>
              </el-button>

            </el-input>

            <!-- INPUT -->
            <el-input v-else-if="formItem.type === getType().Scan"
                      v-model="formData[formItem.prop]"
                      :placeholder="getPlaceHolder(formItem)"
                      :size="getSize(formItem)"
                      :disabled="formItem.disabled"
                      :clearable="formItem.clearable || true"
                      :suffix-icon="formItem.suffixIcon"
                      :prefix-icon="formItem.prefixIcon"
                      :fetch-suggestions="formItem.remoteMethod"
                      @change="change(formItem)"
                      @input="input(formItem)"
                      :show-word-limit="formItem.showWordLimit || true">
              <!-- 后置按钮 -->
              <span slot="append" class="el-icon-full-screen"
                         @click="openScanDialog(formItem)">
                <span> 扫一扫</span>
              </span>
            </el-input>

            <!-- INPUT -->
            <el-button v-else-if="formItem.type === getType().Button"
                       :size="getSize(formItem)"
                       :disabled="formItem.disabled"
                       :plain="formItem.plain || true"
                       :round="formItem.round"
                       :circle="formItem.circle"
                       :loading="formItem.loading"
                       :icon="formItem.icon"
                       :type="formItem.type || 'primary'"
                       :native-type="formItem.nativeType"
                       @click="click(formItem)">
              <span v-html="formItem.btnTxt"></span>
            </el-button>

            <!-- INPUT -->
            <el-button v-else-if="formItem.type === getType().SendVerifyCode"
                       :size="getSize(formItem)"
                       :type="formItem.type || 'primary'"
                       :disabled="formItem.disabled"
                       :plain="formItem.plain || true"
                       :loading="formItem.loading"
                       :icon="formItem.icon"
                       :native-type="formItem.nativeType"
                       @click="sendVerifyCode(formItem)"
                       v-html="formItem.btnTxt || '发送验证码'">
            </el-button>

            <!-- AutoComplete -->
            <el-autocomplete v-else-if="formItem.type === getType().AutoComplete"
                             v-model="formData[formItem.prop]"
                             :placeholder="getPlaceHolder(formItem)"
                             :size="getSize(formItem)"
                             :disabled="formItem.disabled"
                             :debounce="formItem.debounce || 1000"
                             :clearable="formItem.clearable || true"
                             :suffix-icon="formItem.suffixIcon"
                             :prefix-icon="formItem.prefixIcon"
                             :fetch-suggestions="formItem.remoteMethod"
                             @change="change(formItem)"
                             @input="input(formItem)"
                             @select="formItem.select"
                             :show-word-limit="formItem.showWordLimit || true">
              <template slot-scope="props">
                <div v-html="props.item.html"></div>
              </template>
              <!-- 后置按钮 -->
              <span v-if="formItem.showScan" slot="append" class="el-icon-full-screen"
                    @click="openScanDialog(formItem)">
                <!--<span> 扫一扫</span>-->
              </span>
            </el-autocomplete>

            <!-- TextArea -->
            <el-input v-else-if="formItem.type === getType().TextArea"
                      v-model="formData[formItem.prop]"
                      type="textarea"
                      :size="getSize(formItem)"
                      :placeholder="getPlaceHolder(formItem)"
                      :maxLength="formItem.maxLength || 255"
                      :disabled="formItem.disabled"
                      :clearable="formItem.clearable || true"
                      @change="change(formItem)"
                      @input="input(formItem)"
                      :autosize="formItem.isAutoSize ? { minRows: formItem.minRows || 20, maxRows: formItem.maxRows || 100 } : false"
                      :show-word-limit="formItem.showWordLimit || true">
            </el-input>

            <!-- INPUT-Number -->
            <el-input-number v-else-if="formItem.type === getType().Number"
                             style="width: 100%; text-align: left;"
                             v-model="formData[formItem.prop]"
                             :label="formItem.label"
                             :size="getSize(formItem)"
                             :placeholder="getPlaceHolder(formItem)"
                             :disabled="formItem.disabled"
                             :controls="formItem.controls || false"
                             :min="formItem.min || 0"
                             :max="formItem.max || 9999"
                             :precision="formItem.precision || 0"
                             :clearable="formItem.clearable || true"
                             @change="change(formItem)"
                             :suffix-icon="formItem.suffixIcon"
                             :prefix-icon="formItem.prefixIcon">
            </el-input-number>

            <!-- Switch -->
            <el-switch v-else-if="formItem.type === getType().Switch"
                       v-model="formData[formItem.prop]"
                       :active-color="formItem.activeColor || '#13ce66'"
                       :inactive-color="formItem.inactiveColor || '#ff4949'"
                       :active-text="formItem.activeText"
                       :inactive-text="formItem.inactiveText"
                       :active-value="formItem.activeValue || 'Y'"
                       :inactive-value="formItem.inactiveValue || 'N'"
                       :disabled="formItem.disabled"
                       @change="change(formItem)">
            </el-switch>

            <!-- INPUT-Number -->
            <el-input-number v-else-if="formItem.type === getType().Number"
                             style="width: 100%; text-align: left;"
                             v-model="formData[formItem.prop]"
                             :label="formItem.label"
                             :size="getSize(formItem)"
                             :placeholder="getPlaceHolder(formItem)"
                             :disabled="formItem.disabled"
                             :controls="formItem.controls || false"
                             :min="formItem.min || 0"
                             :max="formItem.max || 9999"
                             :precision="formItem.precision || 0"
                             :clearable="formItem.clearable || true"
                             @change="change(formItem)"
                             :suffix-icon="formItem.suffixIcon"
                             :prefix-icon="formItem.prefixIcon">
            </el-input-number>

            <el-date-picker
              v-model="formData[formItem.prop]"
              v-else-if="formItem.type === getType().DateTime"
              :disabled="formItem.disabled || false"
              :clearable="formItem.clearable || true"
              :type="formItem.dateType || 'date'"
              :align="formItem.align || 'left'"
              :size="getSize(formItem)"
              placeholder="选择日期">
            </el-date-picker>


            <!-- SELECT -->
            <el-select v-else-if="formItem.type === getType().Select"
                       style="width: 100%;"
                       v-model="formData[formItem.prop]"
                       :size="getSize(formItem)"
                       :multiple="formItem.multiple || false"
                       :disabled="getDisabled(formItem)"
                       :clearable="formItem.clearable || true"
                       :allow-create="formItem.allowCreate || false"
                       :filterable="formItem.filterable || false"
                       :default-first-option="formItem.defaultFirstOption !== false"
                       :remote="formItem.remote || false"
                       :loading="formItem.searchLoading"
                       :loading-text="formItem.searchLoadingText"
                       :remote-method="formItem.remoteMethod"
                       @change="change(formItem)"
                       :placeholder="getPlaceHolder(formItem)">
              <!-- 按组进行选择 -->
              <div v-if="formItem.groups">
                <el-option-group
                  v-for="group in formItem.groups"
                  v-if="group.options && group.options.length"
                  :key="group.label"
                  :disabled="group.disabled"
                  :label="group.label">
                  <el-option
                    v-for="option in group.options"
                    :disabled="option.disabled || false"
                    :key="option.value"
                    :label="option.label"
                    :value="option.value">
                    <div v-if="option.html" v-html="option.html"></div>
                  </el-option>
                </el-option-group>
              </div>
              <!-- 不按组进行选择 -->
              <div v-else>
                <el-option
                  v-for="option in formItem.options"
                  :disabled="option.disabled || false"
                  :key="option.value"
                  :label="option.label"
                  :value="option.value">
                  <div v-if="option.html" v-html="option.html"></div>
                </el-option>
              </div>
            </el-select>

            <!-- 级联选择器 -->
            <el-cascader
              style="width: 100%;"
              v-else-if="formItem.type === getType().Cascader"
              v-model="formData[formItem.prop]"
              :size="getSize(formItem)"
              :options="formItem.options"
              :clearable="formItem.clearable || true"
              :disabled="formItem.disabled || false"
              :separator="formItem.separator"
              :filterable="formItem.filterable"
              :show-all-levels="formItem.showAllLevels !== false"
              :change-on-select="formItem.changeOnSelect"
              :checkStrictly="formItem.changeOnSelect"
              :placeholder="getPlaceHolder(formItem)"
              @change="change(formItem)"></el-cascader>

            <!-- 级联选择器 -->
            <div style="width: 100%;" v-else-if="formItem.type === getType().TagSelector">
              <el-tag style="margin-right: 10px;" v-for="(tag, tagIndex) in formData[formItem.prop]" :key="tagIndex"
                      closable @close="closeTag(formItem, tag, tagIndex)">{{tag.txt}}
              </el-tag>
              <div class="tagCascaderBox">
                <el-button type="text" @click="clickTagSelectorAdd(formItem)"><i class="el-icon-plus"></i></el-button>
                <el-cascader
                  class="tagCascader"
                  ref="tagCascader"
                  expand-trigger="hover"
                  :options="formItem.options"
                  v-model="tagCascaderValue"
                  :placeholder="getPlaceHolder(formItem)"
                  :change-on-select="true"
                  @change="changeTagCascader"></el-cascader>
              </div>
            </div>

            <div v-else-if="formItem.type === getType().Checkbox">
              <!--<el-checkbox v-if="formItem.isCanAllCheck" :indeterminate="isIndeterminate" v-model="checkAll"
                           @change="change(formItem)">全选
              </el-checkbox>
              <el-checkbox-group v-model="formData[formItem.prop]" @change="change(formItem)">
                <el-checkbox v-for="(checkbox, checkboxIndex) in formItem.checkboxs" :label="checkbox.value"
                             :key="checkboxIndex">{{checkbox.label}}
                </el-checkbox>
              </el-checkbox-group>-->
              <el-checkbox v-for="(checkbox, checkboxIndex) in formItem.checkboxs"
                           v-model="formData[checkbox.value]" @change="change(formItem)"
                           :label="checkbox.value"
                           :key="checkboxIndex"
                           :true-label="checkbox.trueLabel || 'Y'"
                           :false-label="checkbox.trueLabel || 'N'">
                {{checkbox.label}}
              </el-checkbox>
            </div>

            <!-- 展示图片 -->
            <div v-else-if="formItem.type === getType().Images">
              <div v-for="(imageItem, imageIndex) of formData[formItem.prop]" :key="imageIndex" class="imageLicenseBox">
                <el-image style="width: 100px; height: 100px; position: absolute; z-index: 10;"
                          :src="getImagePath(imageItem)"
                          :preview-src-list="[getImagePath(imageItem)]">
                </el-image>
              </div>
            </div>

            <!-- 上传图片 -->
            <div v-else-if="formItem.type === getType().UploadImage">
              <b-upload-image style="margin-top: 10px;" @uploadSuccess="uploadImageSuccess"
                              :businessName="formItem.businessName || 'temp'"
                              acceptFileType="image/*"
                              :prop="formItem.prop"
                              :show-file-list="false"></b-upload-image>
              <div v-for="(imageItem, imageIndex) of formData[formItem.prop]" :key="imageIndex" class="imageLicenseBox">
                <div class="deleteBtn" @click="deleteImageLicense(imageIndex, formItem.prop)">
                  <i class="el-icon-error"></i> 点击删除
                </div>
                <el-image style="width: 100px; height: 100px; position: absolute; z-index: 10;"
                          :src="getImagePath(imageItem)"
                          :preview-src-list="[getImagePath(imageItem)]">
                </el-image>
              </div>
            </div>

            <!-- 图片预览 -->
            <div v-else-if="formItem.type === getType().UploadImagePreview">
              <div v-for="(imageItem, imageIndex) of formData[formItem.prop]" :key="imageIndex" class="imageLicenseBox">
                <el-image style="width: 100px; height: 100px; position: absolute; z-index: 10;"
                          :src="getImagePath(imageItem)"
                          :preview-src-list="[getImagePath(imageItem)]">
                </el-image>
              </div>
            </div>

            <!-- 上传图片 -->
            <div v-else-if="formItem.type === getType().NewUploadImage">
              <b-upload-image-v3 :images="formData[formItem.prop]"
                                 :argument="{ formItem }"
                                 @uploadImage="uploadImage"/>
            </div>

            <!-- 上传文件 -->
            <div v-else-if="formItem.type === getType().UploadStream">
              <input type="file" @input="uploadFileStream" @click="clickInput(formItem)"/>
            </div>

            <!-- 上传文件 -->
            <div v-else-if="formItem.type === getType().UploadFile">
              <b-upload-file-v2 @uploadSuccess="uploadFileSuccess"
                                :business-name="formItem.businessName"
                                :other-info="formItem"
                                :accept-file-type="formItem.acceptFileType">
                <!--<el-button type="text" icon="el-icon-upload">点击上传{{formItem.label}}</el-button>-->
                <span class="blueBtn el-icon-upload"> 点击上传{{formItem.label}}</span>
              </b-upload-file-v2>
              <div v-if="formData[formItem.prop]">
                <!-- 单文件 -->
                <!--<span class="fileClass"
                      @click="downloadFile(formData[formItem.prop])"
                      v-if="formItem.isMultiple !== true">
                  <span>{{formData[formItem.prop].fileName}} </span>
                  <i class="el-icon-delete" v-if="formData[formItem.prop].fileName"
                     @click="deleteFile(formItem)"></i><br>
                </span>-->

                <!-- 多文件 -->
                <!--<span v-else>-->
                <div class="fileClass" v-for="(file, fileIndex) in formData[formItem.prop]" :key="fileIndex">
                  <span @click="downloadFile(file)">{{file.fileName}} </span>
                  <i class="el-icon-delete" @click="deleteFile(formItem, file, fileIndex)"></i><br>
                </div>
                <!--</span>-->
              </div>
            </div>

            <div v-else-if="formItem.type === getType().Tag">
              <!-- Tag -->
              <el-tag
                v-for="(tag, tagIndex) in formData[formItem.prop]"
                class="tagElement pan_ignore"
                :style="getTagStyle(formItem)"
                :key="tagIndex"
                :type="tag.tagType || ''">
                <i class="el-icon-close" v-show="formItem.closable" @click="removeTag(formItem, tag, tagIndex)"></i>
                <span>{{tag.txt}}</span>
              </el-tag>
              <el-button v-if="formItem.operations && formItem.operations.length === 1"
                         @click="formItem.operations[0].click"
                         class="tagElement"
                         type="primary"
                         size="small"
                         icon="el-icon-plus"/>
              <el-dropdown v-else>
                <el-button class="tagElement" type="primary" size="small" icon="el-icon-plus"></el-button>
                <el-dropdown-menu slot="dropdown">
                  <el-dropdown-item v-for="(operation, operationIndex) in formItem.operations"
                                    @click.native="operation.click"
                                    :key="operationIndex">
                    {{operation.label}}
                  </el-dropdown-item>
                </el-dropdown-menu>
              </el-dropdown>
            </div>

            <span v-if="formItem.example" v-html="formItem.example"></span>
          </el-form-item>
        </el-col>
      </el-row>


      <el-form-item v-show="form.isShowFooterButton !== false" label="" class="clearfix">
        <el-button style="float: left;" size="mini" type="primary" @click="enter">
          确 定
        </el-button>
        <el-button style="float: left;" size="mini" @click="cancel">取 消</el-button>
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
  import { ElementType } from '../enum/BElementEnum'
  import BUploadFileV2 from './BUploadFileV2'
  import BUploadImage from './BUploadFileV3'
  import BUploadImageV3 from "./BUploadImageV3";
  import AbsComponent from "@/components/genericComponents/AbsComponent";
  import ImgCompressor from 'image-compressor.js'
  import {newDownload} from "@/utils";
  import {uploadFile} from "@/api/OriginFileUploadApi";

  export default {
    name: 'BForm',
    components: { BUploadFileV2, BUploadImage, BUploadImageV3 },
    extends: AbsComponent,
    props: {
      loading: {
        type: Boolean,
        default: () => {
          return false
        }
      },
      form: {
        type: Object,
        default: () => {
          return {
            labelWidth: '100px',
            api: null,
            reset: 0
          }
        }
      },
      formData: {
        type: Object,
        default: () => {
          return {}
        }
      },
      formItems: {
        type: Array,
        default: () => {
          return []
        }
      }
    },
    data() {
      return {
        formLoading: false,
        checkAll: false,
        isIndeterminate: false,
        tagCascaderValue: null,
        curClickTagCascaderFormItem: null,
        openScanDialogOfFormItem: null,
        selectInputFormItem: null,
      }
    },
    methods: {
      /*
       * 功能：获取类型
       * Author：潘溢林
       */
      getType() {
        return ElementType
      },
      /*
       * 功能：获取图片URL
       * Author：潘溢林
       */
      getImagePath(imageItem) {
        return this.getImageUrl({ image: imageItem })
      },
      /*
       * 功能：上传图片成功
       * Author：潘溢林
       */
      uploadImageSuccess(data, prop) {
        if (!this.formData[prop]) this.formData[prop] = []
        this.formData[prop].push(data)
        this.$forceUpdate()
      },
      /*
       * 功能：上传图片第三版本
       * Author：潘溢林
       */
      uploadImage({ image, argument }) {
        this.loadingStart({ message: '上传图片中...' })
        return uploadFile({businessName: argument.formItem.businessName || 'temp', file: image }).then(bFile => {
          this.loadingEnd()
          if (argument.formItem.uploadSuccess) {
              argument.formItem.uploadSuccess(bFile)
          } else {
            if (!this.formData[argument.formItem.prop]) this.formData[argument.formItem.prop] = []
            this.formData[argument.formItem.prop].push(bFile)
          }
          this.$forceUpdate()
        }).catch(response => {
          this.loadingEnd()
        })

        /*new ImgCompressor(image, {
          quality: 0.6,
          success: function (result) {
            console.log('压缩后图片：', result)
            let tempFile = new window.File([result], result.name, {type: result.type})

            this.loadingStart({ message: '上传图片中...' })
            return uploadFile({businessName: argument.formItem.businessName || 'temp', file: tempFile}).then(bFile => {
              this.loadingEnd()
              if (!this.formData[argument.formItem.prop]) this.formData[argument.formItem.prop] = []
              this.formData[argument.formItem.prop].push(bFile)
              this.$forceUpdate()
            }).catch(response => {
              this.loadingEnd()
            })
          },
          error: function (err) {
            console.log('压缩失败：', err)
          }
        })*/
      },
      /*
       * 功能：上传图片成功
       * Author：潘溢林
       */
      removeImage(data, prop) {
      },
      /*
       * 功能：移除下单数量变更凭证
       * Author：潘溢林
       */
      deleteImageLicense(index, prop) {
        this.formData[prop].splice(index, 1)
        this.$forceUpdate()
      },
      /*
       * 功能：确认修改
       * Author：潘溢林
       */
      enter() {
        this.validate((valid) => {
          if (!valid) return

          /* 当没有指定API时，直接返回回调接口 */
          if (!this.form.api) {
            this.$emit('enter', this.formData)
            return
          }

          /* 当有指定API时，直接运行API */
          this.formLoading = true
          return new Promise((resolve, reject) => {
            this.form.api(this.formData).then(response => {
              this.$emit('response', response)
              this.formLoading = false
              return (resolve)
            }).catch(error => {
              this.formLoading = false
              reject(error)
            })
          })
        })
      },
      /*
       * 功能：取消弹窗
       * Author：潘溢林
       */
      cancel() {
        this.$emit('cancel')
      },
      /*
       * 功能：校验
       * Author：潘溢林
       */
      validate(callback) {
        return this.$refs.form.validate(callback)
      },
      /*
       * 功能：GetPlaceHolder
       * Author：潘溢林
       */
      getPlaceHolder(formItem) {
        if (formItem.type === this.getType().Select) return formItem.placeholder || ('请选择' + formItem.label)
        return formItem.placeholder || ('请输入' + formItem.label)
      },
      /*
       * 功能：GetSize
       * Author：潘溢林
       */
      getSize(formItem) {
        return formItem.size || 'mini'
      },
      /*
       * 功能：初始化检验规则
       * Author：潘溢林
       */
      getRules(formItem) {
        let rules = []

        if (formItem.rule && formItem.rule.required) rules.push({
          required: true,
          message: '请输入' + (formItem.label || ''),
          trigger: 'change'
        })

        return rules
      },
      /*
       * 功能：当值发生改变时
       * Author：潘溢林
       */
      change(formItem) {
        if (formItem.change && typeof formItem.change == 'function') formItem.change(this.formData, formItem)
      },
      /*
       * 功能：当值发生改变时
       * Author：潘溢林
       */
      input(formItem) {
        if (formItem.input && typeof formItem.input == 'function') formItem.input(this.formData, formItem)
      },
      /*
       * 功能：点击选择Tag添加按钮
       * Author：潘溢林
       */
      clickTagSelectorAdd(formItem) {
        this.curClickTagCascaderFormItem = formItem
        this.$refs.tagCascader[0].$el.click()
      },
      /*
       * 功能：点击选择Tag添加按钮
       * Author：潘溢林
       */
      changeTagCascader(selectValue) {
        let formItem = this.curClickTagCascaderFormItem
        if (formItem.select && typeof formItem.select == 'function') formItem.select(selectValue)

        this.tagCascaderValue = null
      },
      /*
       * 功能：点击选择Tag添加按钮
       * Author：潘溢林
       */
      closeTag(formItem, tag, tagIndex) {
        if (formItem.closeTag && typeof formItem.closeTag == 'function') formItem.closeTag(tag, tagIndex)
      },
      /*
       * 功能：上传文件成功
       * Author：潘溢林
       */
      uploadFileSuccess({ bFile, extra }) {
        /* 上传多文件 */
        if (!this.formData[extra.prop]) this.formData[extra.prop] = []
        // 判断上传单文件，还是多文件
        if (extra.isSingleFile) this.formData[extra.prop].splice(0)
        
        this.formData[extra.prop].push(bFile)
        this.$forceUpdate()
      },
      /*
       * 功能：下载文件
       * Author：潘溢林
       */
      downloadFile(file) {
        newDownload(file)
      },
      /*
       * 功能：移除文件
       * Author：潘溢林
       */
      deleteFile(formItem, file, fileIndex) {
        if (formItem.isMultiple !== true) {
          this.formData[formItem.prop] = null
        } else {
          /* 上传单文件 */
          this.formData[formItem.prop].splice(fileIndex, 1)
        }
        this.$forceUpdate()
      },
      /*
       * 功能：移除Tag
       * Author：潘溢林
       */
      removeTag(formItem, tag, tagIndex) {
        this.formData[formItem.prop].splice(tagIndex, 1)
        this.$forceUpdate()
      },
      /*
       * 功能：获取标记的样式
       * Author：潘溢林
       */
      getTagStyle(formItem) {
        if (formItem.tagMaxWidth) return { 'max-width': formItem.tagMaxWidth }
        let formWidth = this.form.width
        let formLabelWidth = this.form.labelWidth || 100
        return { 'max-width': formWidth ? formWidth - formLabelWidth + 'px' : '200px' }
      },
      /*
       * 功能：重置表单校验结果
       * Author：潘溢林
       */
      resetField() {
        this.$refs.form.resetFields()
      },
      /*
       * 功能：按钮点击事件
       * Author：潘溢林
       */
      click(formItem) {
        if (formItem.click) return formItem.click({ formData: this.formData })
      },
      /*
       * 功能：发送验证码
       * Author：潘溢林
       */
      sendVerifyCode(formItem) {
        if (formItem.callback) formItem.callback({ form: this.form, formData: this.formData })
        formItem.countdown = 60
        this.countdown(formItem)
      },
      /*
       * 功能：倒计时
       * Author：潘溢林
       */
      countdown(formItem) {
        let app = this
        formItem.countdown--

        if (formItem.countdown === 0) {
          formItem.countdown = 60
          formItem.disabled = false
          formItem.btnTxt = '重新发送验证码'
        } else {
          formItem.disabled = true
          formItem.btnTxt = formItem.countdown + '秒后可重发...'

          setTimeout(function () {
            app.countdown(formItem)
          }, 1000)
        }
      },
      /*
       * 功能：打开扫一扫弹窗
       * Author：潘溢林
       */
      openScanDialog(formItem) {
        this.openScanDialogOfFormItem = formItem
        this.$store.dispatch('openScanCodeDialog', {
          callBack: this.scanQrCode,
          version: formItem.scanVersion || 'ListenAll'
        })
      },
      /*
       * 功能：扫描结果
       * Author：潘溢林
       */
      scanQrCode(value) {
        this.formData[this.openScanDialogOfFormItem.prop] = value
        if (this.openScanDialogOfFormItem.scanCallback) this.openScanDialogOfFormItem.scanCallback(value)
        this.$forceUpdate()
      },
      /*
       * 功能：获取禁用状态
       * Author：潘溢林
       */
      getDisabled(formItem) {
        if (!formItem.disabled) return false
        if (typeof formItem.disabled === 'function') return formItem.disabled()
        return formItem.disabled
      },
      /*
       * 功能：上传文件流
       * Author：潘溢林
       */
      uploadFileStream(e) {
        if (this.isEmptyList(e.target.files)) return
        this.formData[this.selectInputFormItem.prop] = e.target.files[0]
      },
      /*
       * 功能：点击Input
       * Author：潘溢林
       */
      clickInput(formItem) {
        this.selectInputFormItem = formItem
      },
    },
    watch: {
      /*
       * 功能：初始化加载状态
       * Author：潘溢林
       */
      loading: function (val) {
        this.formLoading = val
      },
      'form.reset': function () {
        this.resetField()
      },
      'form.refresh': function () {
        this.$forceUpdate()
      },
      'formItems': function () {
        this.$forceUpdate()
      }
    }
  }
</script>

<style type="text/scss">
  .el-input-number.is-without-controls .el-input__inner {
    text-align: left !important;
  }

  .el-form-item__content .el-input-group {
    vertical-align: middle;
  }

  .form .el-form-item{
    margin-bottom: 5px !important;
  }
</style>

<style type="text/scss" scoped>
  .el-form-item {
    margin-bottom: 5px;
  }

  .imageLicenseBox {
    width: 100px;
    height: 100px;
    border: 1px solid #c0ccda;
    position: relative;
    overflow: hidden;
    border-radius: 6px;
    margin-bottom: 5px;
    display: inline-block;
    margin-right: 10px;
  }

  .imageLicenseBox:hover {
    border: 1px solid #409EFF;
  }

  .imageLicenseBox:hover .deleteTip {
    display: block;
  }

  .deleteBtn {
    text-align: center;
    position: absolute;
    background-color: rgba(255,0,0,0.5);
    z-index: 2000;
    width: 100%;
    color: white;
    font-size: 10px;
    cursor: pointer;
  }


  .title-text {
    border-left: 5px solid #409EFF;
    padding: 15px;
    font-size: 16px;
    font-weight: bold;
    background: #f7f7f7;
    margin: 10px 0;
    width: 100%;
    color: #409EFF;
  }

  .tagCascaderBox {
    width: 40px;
    display: inline-block;
    position: relative;
    text-align: center;
  }

  .tagCascader {
    width: 0;
    left: -10px;
    overflow: hidden;
    position: absolute;
  }

  .fileClass {
    cursor: pointer;
  }

  .fileClass:hover {
    text-decoration-line: underline;
  }

  .fileClass .el-icon-delete {
    cursor: pointer;
    color: red;
  }

  .tagElement {
    float: left;
    overflow: hidden;
    margin: 10px 10px 0 0;

  }

  .tagElement .el-icon-close {
    left: 0 !important;;
  }

  .tagElement .el-icon-close:hover {
    color: red;
  }

  .el-autocomplete {
    width: 100%;
  }

  .el-input .el-input-group {
     vertical-align: middle;
  }
</style>

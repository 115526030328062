<template>
    <div class="BUploadImageV3">
        <!-- 图片预览 -->
        <el-tooltip v-for="(image, imageIndex) of images"
                    :key="imageIndex"
                    effect="dark" content="点击预览图片" placement="top-start">
            <div class="imageBox">
                <el-image style="width: 80px; height: 80px" :src="getImageUrl({ image })" :preview-src-list="[getImageUrl({ image })]" fit="fill"></el-image>
                <div v-if="!argument.staticImage" class="deleteBtn el-icon-delete-solid" @click="deleteImage(image, imageIndex)"> 删除</div>
            </div>
        </el-tooltip>

        <!-- 上传图片框 -->
        <el-tooltip v-if="!argument.staticImage" effect="dark" content="双击选择图片 / 拖拽图片 / 截图粘贴图片" placement="top-start">
            <div class="uploadContainer">
                <div class="uploadBox" v-bind:class="dragActive ? 'dragActive' : ''"
                     @paste="pasteImg($event)"
                     @dblclick="onOpenFileSelectDialog"
                     @dragover.prevent="dragEnter"
                     @dragleave.prevent="dragLeave"
                     @drop.prevent="dragImage">
                    <i class="el-icon-picture"></i>
                </div>

                <div v-if="argument.isShowLabel" class="imageLabel">{{argument.labelPrefix}}</div>
            </div>
        </el-tooltip>

        <!-- 图片输入框 -->
        <input v-if="!argument.staticImage" ref="imageInput" class="imageInput" type="file" accept="image/*" @change="selectedFile"/>
    </div>
</template>

<script>
    import AbsComponent from "@/components/genericComponents/AbsComponent";
    import BImageGroup from "@/components/genericComponents/element/BImageGroup";
    import {errorNotice} from "@/utils/NoticeUtil";

    export default {
        name: "BUploadImageV3",
        components: {BImageGroup},
        extends: AbsComponent,
        props: {
            images: {
                type: Array,
                default: () => {
                    return []
                }
            },
            argument: {
                type: Object,
                default: () => {
                    return {
                        staticImage: false,
                        isOnlyOne: false,
                        isShowLabel: false,
                        labelPrefix: null,
                    }
                }
            },
        },
        data() {
            return {
                dragActive: false
            }
        },
        methods: {
            /*
             * 功能：粘贴图片
             * Author：潘溢林
             */
            pasteImg(e) {
                let content = e.clipboardData.getData('Text')
                if (content) return errorNotice('粘贴内容非图片：' + content)

                let items = e.clipboardData.items
                let file = null

                for (let i = 0; i < items.length; i++) {
                    if (items[i].type.indexOf("image") !== -1) {
                        file = items[i].getAsFile();
                        break;
                    }
                }

                if (!file) return errorNotice('粘贴内容非图片！')

                this.uploadImage(file)
            },
            /*
             * 功能：打开文件选择器弹窗
             * Author：潘溢林
             */
            onOpenFileSelectDialog() {
                this.$refs.imageInput.click()
            },
            /*
             * 功能：选中文件
             * Author：潘溢林
             */
            selectedFile(e) {
                this.uploadImage(e.target.files[0])

                /* 清除已选择的图片 */
                e.target.value = null
            },
            /*
             * 功能：拖放图片
             * Author：潘溢林
             */
            dragEnter(e) {
                this.dragActive = true
            },
            /*
             * 功能：拖放图片
             * Author：潘溢林
             */
            dragLeave(e) {
                this.dragActive = false
            },
            /*
             * 功能：拖放图片
             * Author：潘溢林
             */
            dragImage(e) {
                this.dragActive = false
                this.uploadImage(e.dataTransfer.files[0])
            },
            /*
             * 功能：上传文件
             * Author：潘溢林
             */
            uploadImage(image) {
                this.$emit('uploadImage', { image, argument: this.argument })
            },
            /*
             * 功能：确认删除图片
             * Author：潘溢林
             */
            deleteImage(image, imageIndex) {
                if (this.images) this.images.splice(imageIndex, 1)
                this.$emit('deleteImage', { imageIndex, image, argument: this.argument })
            }
        },
        watch: {
            images: function () {
                this.$forceUpdate()
            }
        }
    }
</script>

<style scoped>
    .BUploadImageV3 {
        display: inline-block;
        position: relative;
    }

    .uploadContainer {
        float: left;
    }

    .uploadBox {
        width: 80px;
        height: 80px;
        border: 1px solid #f7f8fa;
        background-color: #f7f8fa;
        cursor: pointer;
        text-align: center;
        line-height: 80px;
        font-size: 30px;
        color: #969799;
        margin-bottom: 0;
    }

    .uploadBox:hover {
        border: 1px solid #1E90FF;
    }

    .imageInput {
        position: absolute;
        opacity: 0;
        width: 0;
        height: 0;
    }

    .uploadContainer {
        text-align: center;
    }

    .imageLabel {
        margin-top: 5px;
        font-size: 10px;
        color: #969799;
    }

    .imageBox {
        width: 80px;
        height: 80px;
        border: 1px solid #f7f8fa;
        background-color: #f7f8fa;
        float: left;
        margin-right: 10px;
        position: relative;
        overflow: hidden;
    }

    .imageBox:hover {
        border: 1px solid #1E90FF;
    }

    .imageBox:hover .deleteBtn {
        display: block;
    }

    .dragActive {
        border: 1px solid #1E90FF;
    }

    .deleteBtn {
        width: 100%;
        height: 20px;
        line-height: 20px;
        color: red;
        text-align: center;
        background-color: rgba(0, 0, 0, 0.5);
        position: absolute;
        left: 0;
        bottom: 0;
        cursor: pointer;
        display: none;
    }
</style>

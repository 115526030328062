import Cookies from 'js-cookie'
import CookieEnum from "@/enums/CookieEnum";

export function saveToken(token) {
    Cookies.set('muguatp_mobile_token', token)
}

export function getToken() {
    let token = Cookies.get('muguatp_mobile_token')
    if (token && token === 'null') token = null
    return token
}

export function saveHumanInfo(humanInfo) {
    /* 保存当前HumanInfo */
    setCookie('muguatp_mobile_humanInfo', humanInfo)
}

export function saveFc(fc) {
    if (fc) setCookie('curFc', fc)
}

export function getHumanInfo() {
    let humanInfo = Cookies.get('muguatp_mobile_humanInfo')
    if (humanInfo) humanInfo = JSON.parse(humanInfo)
    return humanInfo
}

export function saveIdentities(identities) {
    /* 保存当前HumanInfo */
    setCookie('identities', identities)
}

export function getIdentities() {
    let identities = Cookies.get('identities')
    if (identities) identities = JSON.parse(identities)
    return identities
}

export function saveCurIdentity(curIdentity) {
    /* 保存当前HumanInfo */
    setCookie('curIdentity', curIdentity)
}

export function getCurIdentity() {
    let curIdentity = Cookies.get('curIdentity')
    if (curIdentity) curIdentity = JSON.parse(curIdentity)
    return curIdentity
}

export function saveCurWorkPath(curWorkPath) {
    setCookie('curWorkPath', curWorkPath)
}

export function getCurWorkPath() {
    let curWorkPath = Cookies.get('curWorkPath')
    if (curWorkPath) curWorkPath = JSON.parse(curWorkPath)
    return curWorkPath
}

export function getCurFc() {
    let curFc = Cookies.get('curFc')
    if (curFc) curFc = JSON.parse(curFc)
    return curFc
}

export function getHumanInfoHistories() {
    let cookie = Cookies.get('muguatp_mobile_humanInfo_history')
    if (cookie) cookie = JSON.parse(cookie)
    if (!cookie) cookie = []
    return cookie
}

export function getOperators() {
    let value = Cookies.get(CookieEnum.Operators.code)
    if (value && CookieEnum.Operators.isFormatter) value = JSON.parse(value)

    if (value && value.length && typeof value[0] !== 'string') {
        Cookies.set(CookieEnum.Operators.code, [])
        return []
    }

    return value || []
}

export function getOperator() {
    let value = Cookies.get(CookieEnum.physicalOperator.code)
    if (value && CookieEnum.Operator.isFormatter) value = JSON.parse(value)
    return value || {}
}

const DefaultExpiresTime = new Date('Fri, 31 Dec 9999 23:59:59 GMT')

export function setCookie(key, value, expires=DefaultExpiresTime) {
    Cookies.set(key, value, {
        expires
    })
}

export function getCookie(key, isFormatter=false) {
    let value = Cookies.get(key)
    if (value && isFormatter) value = JSON.parse(value)
    return value
}

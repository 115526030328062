<template>
    <b-dialog-v2 :argument="dialog">
        <b-form-v2 :form="dialog.form"
                   :form-items="dialog.form.formItems"
                   :form-data="dialog.form.data"
                   @cancel="cancel"
                   @enter="enter"
                   @response="response">
        </b-form-v2>
    </b-dialog-v2>
</template>

<script>
    import BFormV2 from "@/components/genericComponents/element/BFormV2";
    import BDialogV2 from "@/components/genericComponents/element/BDialogV2";
    import AbsComponent from "@/components/genericComponents/AbsComponent";

    export default {
        name: "BFormDialogV2",
        extends: AbsComponent,
        components: {BFormV2, BDialogV2},
        props: {
            dialog: {
                type: Object,
                default: () => {
                    return {
                        show: false,
                        width: '100%',
                        title: '工艺引擎',
                        appendToBody: true,
                        form: {},
                    }
                }
            }
        },
        methods: {
            enter() {
                this.$emit('enter')
            },
            response() {
                this.$emit('response')
            },
            cancel() {
                this.dialog.show = false
            },
        }
    }
</script>

<style scoped>

</style>

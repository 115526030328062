export const ElementType = {
  INPUT: 'input',
  RENDER: 'render',
  Button: 'Button',
  SendVerifyCode: 'SendVerifyCode',
  Component: 'Component',
  Number: 'number',
  TextArea: 'textArea',
  Select: 'Select',
  Radio: 'Radio',
  Title: 'title',
  Cascader: 'Cascader',
  TagSelector: 'TagSelector',
  Checkbox: 'Checkbox',
  Images: 'Images',
  UploadImage: 'UploadImage',
  NewUploadImage: 'NewUploadImage',
  UploadImagePreview: 'UploadImagePreview',
  UploadFile: 'UploadFile',
  UploadStream: 'UploadStream',
  Tag: 'Tag',
  Sequence: 'Sequence',
  AutoComplete: 'autocomplete',
  DateTime:'dateTime',
  DateTimeRange:'dateTimeRange',
  Suit:'Suit',
  Switch:'Switch',
  Scan: 'Scan'
}

const localStoreData = {

    get: function (key) {
        return localStorage.getItem(key);
    },

    set: function (key, value) {
        localStorage.setItem(key, value);
    },
    getPrinter: function () {
        return localStorage.getItem("localPrinterConfig");
    },

    setPrinter: function (value) {
        localStorage.setItem("localPrinterConfig", value);
    },
    getDeviceCode: function () {
        return localStorage.getItem("DEVICE_CODE");
    },

    getDeviceCodeObj: function () {
        if (localStorage.getItem("DEVICE_CODE")) {
            return JSON.parse(localStorage.getItem("DEVICE_CODE"));
        }
        return null;
    },

    setDeviceCode: function (key, val) {
        if (!this.getDeviceCode()) {
            var data = '{"' + key + '":"' + val + '"}';
            localStorage.setItem("DEVICE_CODE", data);
        } else {
            var deviceCode = Object.assign(JSON.parse(this.getDeviceCode()), JSON.parse('{"' + key + '":"' + val + '"}'));
            localStorage.setItem("DEVICE_CODE", JSON.stringify(deviceCode));
        }

    },
    remove: function (key) {
        localStorage.removeItem(key);
    },
}
export default localStoreData;


<template>
  <div class="systemDownload">
    <el-table :data="tableData" height="300">
      <el-table-column prop="fileType" align="center" label="文件名称" width="200">
      </el-table-column>
      <el-table-column prop="remarks" align="center" label="说明">
        <template v-slot="{ row }">
          <span v-html="row.remarks"></span>
        </template>
      </el-table-column>
      <el-table-column prop="opr" align="center" label="操作" width="200">
        <template v-slot="{ row }">
          <el-button type="text" icon="el-icon-download" @click="download(row)">下 载</el-button>
        </template>
      </el-table-column>
    </el-table>

  </div>
</template>
  
<script>
import AbsComponent from "@/components/genericComponents/AbsComponent";
export default {
  name: 'systemDownload',
  desc: '系统文件下载',
  extends: AbsComponent,
  components: {},
  props: {},
  data() {
    return {
      tableData: [{
        fileType: '收料系统APP',
        fileName: 'mugua_app.zip',
        remarks:'收料系统手机进行安装',
      }, {
        fileType: '打印服务',
        remarks:'本地标签打印服务程序，<b style="color:#E6A23C;">建议安装位置【D:\\soft\\打印服务】</b>',
        fileName: 'local_print_server.zip',
      }, {
        fileType: '设备捆绑配置',
        remarks:'本地看板配置',
        fileName: 'build_device.txt',
      }, {
        fileType: '芯烨 XP-420B',
        remarks:'芯烨条码热转印系列产品驱动2023.2 M-4',
        fileName: 'XPrinter-2023.2 M-4.zip',
      }],
      ftpUrl: process.env.VUE_APP_NEW_FILE_URL + "soft/"
    }
  },
  created() {
  },
  mounted() { },
  methods: {
    download(row) {
      window.open(this.ftpUrl + row.fileName);
    }

  },
}
</script>
  
<style scope></style>
<template>
    <div></div>
    <!-- <el-footer class="FooterContainer normalBtn">
        <span @click="open">@Copyright 2021 深圳百立特物联科技有限公司</span>
    </el-footer> -->
</template>

<script>
    export default {
        name: "FooterContainer",
        methods: {
            open() {
                window.open('https://www.muguatp.com/')
            }
        }
    }
</script>

<style scoped>
    .FooterContainer {
        width: 100%;
        height: 50px;
        line-height: 50px;
        text-align: center;
        font-size: 10px;
        margin-top: 100px;
    }
</style>

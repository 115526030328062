<template>
    <div class="drawerContainer">
        <div class="drawerOpenIcon highLight" @click="open">
            <i v-if="drawer.show" class="el-icon-s-unfold"></i>
            <i v-else class="el-icon-s-fold"></i>
        </div>

        <el-drawer :visible.sync="drawer.show"
                   :withHeader="false"
                   :modal-append-to-body="false"
                   :size="210"
                   direction="ltr">
            <div class="menuContainer">
                <div class="menuLogoBox">
                    <img :src="drawer.logo" alt="企业LOGO" width="100%" height="60px">
                </div>
                <div>
                    <b-menu-container :argument="bMenuContainer"/>
                </div>
            </div>
        </el-drawer>
    </div>
</template>

<script>
    import AbsComponent from "@/components/genericComponents/AbsComponent";
    import logo from '@/images/logo.png'
    import BMenuContainer from "@/layout/components/BMenuContainer";
    import { mapGetters } from 'vuex'

    export default {
        name: "MenuDrawerContainer",
        extends: AbsComponent,
        components: { BMenuContainer },
        computed: {
            ...mapGetters([
                'curWorkPath' // 工厂ID
            ])
        },
        data() {
            return {
                activeIndex: null,
                drawer: {
                    logo: logo,
                    show: false,
                },
                bMenuContainer: {
                    mode: 'vertical',
                    backgroundColor: '#051424',
                    textColor: '#fff',
                    collapse: false,
                    activeTextColor: '#409EFF',
                }
            }
        },
        created() {

        },
        methods: {
            /*
             * 功能：打开弹窗
             * Author：潘溢林
             */
            open() {
                this.drawer.show = true
            },
            /*
             * 功能：选择菜单
             * Author：潘溢林
             */
            selectMenu(index, path) {
                console.warn(index, path)
            },
        }
    }
</script>

<style scoped>
    .drawerContainer {
        float: left;
    }

    .menuContainer {
        background-color: #051424;
        width: 100%;
        height: 100%;
    }

    .menuLogoBox {
        padding: 10px;
    }

    .drawerOpenIcon {
        width: 60px;
        height: 60px;
        line-height: 60px;
        text-align: center;
    }
</style>

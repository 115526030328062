import getters from "@/store/user/getters";
import mutations from "@/store/user/mutations";
import actions from "@/store/user/actions";

const state = {
    token: null,
    userInfo: null,
    accountInfo: null,
    humanInfo: null,
    humanIdentities: [],
    curIdentity: null,
    curWorkPath: null,
    curPath: null,
    curFactory: null,
    menuDrawer: {
        show: false,
    },
    notice: {
        show: false,
        message: '',
        type: 'success',
    },
    loadingStatus: {
        loading: false,
        message: '拼命加载中...',
        spinner: "el-icon-loading",
        background: "rgba(0, 0, 0, 0.8)"
    },
    isWeChatBrowser: false,
    scanQrCode: {
        show: false,
        callback: null,
        version: null,
    },
    audio: {
        refresh: 0,
        src: null,
    },
    downloader: {
        refresh: 0,
        show: false,
        list: []
    }
}

export default {
    state,
    getters,
    mutations,
    actions,
}

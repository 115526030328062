<template>
    <audio ref="audio" :src="audio.src" preload>对不起，您的浏览器不支持HTML5音频播放。</audio>
</template>

<script>
    import {mapGetters} from "vuex";

    export default {
        name: "GlobalAudio",
        computed: {
            ...mapGetters([
                'audio'
            ])
        },
        data() {
            return {
                audioComponent: null
            }
        },
        mounted() {
            this.audioComponent = this.$refs.audio
        },
        methods: {
            play() {
                setTimeout(() => {
                    this.audioComponent.play()
                }, 200)
            }
        },
        watch: {
            'audio.refresh': function () {
                this.play()
            }
        }
    }
</script>

<style scoped>

</style>

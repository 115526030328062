<template>
    <van-notify v-model="notice.show" :type="notice.type">
        <span v-html="notice.message"></span>
    </van-notify>
</template>

<script>
    import { mapGetters } from 'vuex'

    export default {
        name: "GlobalNotice",
        computed: {
            ...mapGetters([
                'notice'
            ])
        },
    }
</script>

<style scoped>

</style>
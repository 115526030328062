export default {

    OperatorType: { code: 'OperatorType' },
    WorkFlowBusiness: { code: 'WorkFlowBusiness' },

    WorkFlowBusiness_Order_Process: { code: '订单进度/工序/工步' },



}

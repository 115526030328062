import axiosCreate from '@/utils/AxiosUtil'

const service = axiosCreate({
	baseURL: process.env.VUE_APP_AUTH_CENTER,
})

/**
 * 授权中心 -> 捆绑看板到设备
 */
export function buildDeviceToBoardApi(data) {
	return service({
		url: '/buildDeviceToBoard',
		method: 'post',
		data
	});
}

/**
 * 授权中心 -> 获取工厂的设备编号
 */
export function authGetUserInfo(humanCode) {
	return service({
		url: '/getUserInfo/' + humanCode,
		method: 'get',
	});
}

/**
 * 授权中心 -> 捆绑看板到设备
 */
export function authGetFactoryInfo(factoryCode) {
	return service({
		url: '/getFactoryInfo/' + factoryCode,
		method: 'get',
	});
}

/**
 * 授权中心 -> 捆绑看板到设备
 */
export function authGetBasicDevice() {
	return service({
		url: '/getBasicDevice',
		method: 'get',
	});
}
/**
 * 授权中心 -> 获取设备工单工位
 */
export function getDeviceWorkOrderApi(deviceCode) {
	return service({
		url: '/getDeviceWorkOrder/' + deviceCode,
		method: 'get'
	});
}
/**
 * 授权中心 -> 创建虚拟工单
 */
export function createVirtuallyWorkOrderApi(data) {
	return service({
		url: '/createVirtuallyWorkOrder',
		method: 'post',
		data
	});
}

/**
 * 授权中心 -> 删除虚拟设备
 */
export function deleteVirtuallyWorkOrderApi(id) {
	return service({
		url: '/deleteVirtuallyWorkOrder/' + id,
		method: 'delete'
	});
}


/**
 * 授权中心 -> 设置多工位共享电脑
 */
export function setShareStationApi(data) {
	return service({
		url: '/setShareStation',
		method: 'post',
		data
	});
}

/**
 * 授权中心 -> 获取用户信息
 */
export function getUserInfoApi(phone) {
	return service({
		url: '/getUserInfo/' + phone,
		method: 'GET',
	});
}

/**
 * 授权中心 -> 设置工位加急通道
 */
export function setStationUrgentChannelApi(type, id) {
	return service({
		url: '/setStationUrgentChannel/' + type + "/" + id,
		method: 'GET',
	});
}
/**
 * 授权中心 -> 获取打印机配置
 */
export function getPrinterConfigApi() {
	return service({
		url: '/printer/getConfig',
		method: 'GET',
	});
}

/**
 * 授权中心 -> 增加工位打印机
 */
export function addDevicePrinterApi(data) {
	return service({
		url: '/printer/addDevicePrinter',
		method: 'POST',
		data
	});
}
/**
 * 授权中心 -> 删除工位打印机
 */
export function deleteDevicePrinterApi(id) {
	return service({
		url: '/printer/deleteDevicePrinter/'+id,
		method: 'POST',
	});
}
/**
 * 授权中心 -> 查询工位打印机
 */
export function getBoardTypePrinterApi(boardType,deviceCode) {
	return service({
		url: '/printer/getBoardTypePrinter/'+boardType+"/"+deviceCode,
		method: 'GET',
	});
}
<template>
    <div class="pushWorkOrder">
        <el-form label-position="right" label-width="100px" :inline="true" :model="workOrderForm" size="small">
            <el-form-item label="当前设备：">
                <el-select v-model="deviceCode" placeholder="请选择" @change="getData">
                    <el-option v-for="key in Object.keys(deviceCodeS)" :key="deviceCodeS[key]" :label="deviceCodeS[key]"
                        :value="deviceCodeS[key]">
                    </el-option>
                </el-select>
            </el-form-item>
            <el-form-item label="加急通道：">
                <el-switch v-model="workOrderForm.urgentChannel" active-text="启用" inactive-text="禁用" active-color="#13ce66"
                    inactive-color="#ff4949">
                </el-switch>
            </el-form-item>
        </el-form>
        <el-row :gutter="10" style="margin-top: 20px;">
            <el-col :span="12">
                <el-card class="station-card">
                    <div slot="header" style="text-align: center;font-weight: 600;">
                        <span>独立工位</span>
                        <el-button style="float: right; padding: 3px 0" type="text" icon="el-icon-setting"
                            @click="OnSetShareEvent">设置多工位共享</el-button>
                    </div>
                    <el-table :data="oneTableData" style="width: 100%" height="200" row-key="id" default-expand-all
                        :tree-props="{ children: 'children', hasChildren: 'hasChildren' }"
                        :cell-class-name="oneTableCellClass" @selection-change="handleSelectionChange">
                        <el-table-column type="selection" align="center" width="28">
                        </el-table-column>
                        <el-table-column prop="workOrderName" header-align="center" label="工位名称">
                        </el-table-column>
                        <el-table-column prop="workOrderType" align="center" label="工位属性" width="150">
                            <template v-slot="{ row }">
                                <span v-if="row.workOrderType === '1'" style="color:#409EFF">物理工位</span>
                                <span v-else style="color:#E6A23C">虚拟工位</span>
                            </template>
                        </el-table-column>
                        <el-table-column prop="address" align="center" label="操作" width="150">
                            <template v-slot="{ row }">
                                <el-button type="text" icon="el-icon-circle-plus" size="mini"
                                    v-if="row.workOrderType === '1'" @click="OnAddShowVirtually(row)">增加虚拟工位</el-button>
                                <el-button type="text" icon="el-icon-remove" size="mini" v-else style="color:#F56C6C"
                                    @click="OnDeleteShowVirtually(row)">删除虚拟工位</el-button>
                            </template>
                        </el-table-column>
                    </el-table>
                </el-card>
            </el-col>
            <el-col :span="12">
                <el-card class="station-card">
                    <div slot="header" style="text-align: center;font-weight: 600;">
                        <span>多工位共享电脑(批量领工单)</span>
                    </div>
                    <el-table :data="shareTableData" style="width: 100%" height="200">
                        <el-table-column prop="date" align="center" label="共享ID" width="80">
                        </el-table-column>
                        <el-table-column prop="date" align="center" label="工位">
                        </el-table-column>
                        <el-table-column prop="address" align="center" label="操作" width="100">
                        </el-table-column>
                    </el-table>
                </el-card>
            </el-col>
        </el-row>
    </div>
</template>

<script>
import AbsComponent from "@/components/genericComponents/AbsComponent";
import localStoreObj from "@/api/localStorage"
import { getDeviceWorkOrderApi, createVirtuallyWorkOrderApi, deleteVirtuallyWorkOrderApi, setShareStationApi } from "@/api/authCenter.js"
export default {
    name: 'pushWorkOrder',
    desc: '工单配置',
    extends: AbsComponent,
    components: {},
    props: {},
    data() {
        return {
            workOrderForm: {
                urgentChannel: false,
            },
            deviceCodeS: {},
            oneTableData: [],
            shareTableData: [],
            shareIdSelection: [],
            deviceCode: null,
        }
    },
    created() {

    },
    mounted() { },
    methods: {
        loadData() {
            if (localStoreObj.get("DEVICE_CODE")) {
                this.deviceCodeS = JSON.parse(localStoreObj.get("DEVICE_CODE"));
            }
        },
        getData() {
            if (this.deviceCode) {
                this.loadingStart({ message: '加载中，请稍后...' })
                getDeviceWorkOrderApi(this.deviceCode).then(rsp => {
                    this.oneTableData = rsp;
                });
            }
        },
        addWorkOrder() {
            this.workOrderForm.workOrders.push({
                value: '',
                key: Date.now()
            });
        },
        removeWorkOrder(item) {
            var index = this.workOrderForm.workOrders.indexOf(item)
            if (index !== -1) {
                this.workOrderForm.workOrders.splice(index, 1)
            }
        },
        handleSelectionChange(val) {
            this.shareIdSelection = val;
        },
        oneTableCellClass({ row }) {
            console.log(row)
            if (row.workOrderType !== '1') {
                return "checkNone";
            }
        },
        /**
         * 增加虚拟工位
         */
        OnAddShowVirtually(rowData) {
            this.$prompt('请输入虚拟设备名称', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                inputPattern: /^[\s\S]*.*[^\s][\s\S]*$/,
                inputErrorMessage: '名称不能为空'
            }).then(({ value }) => {
                this.loadingStart({ message: '加载中，请稍后...' })
                createVirtuallyWorkOrderApi({
                    operatorDeviceId: rowData.fkOperatorDeviceId,
                    boardCode: rowData.boardCode,
                    workOrderId: rowData.id,
                    workOrderName: value,

                }).then(() => {
                    this.loadData();
                });
            });
        },
        /**
         * 删除虚拟设备
         * @param {*} rowData 
         */
        OnDeleteShowVirtually(rowData) {
            this.$confirm('确定删除虚拟工位[' + rowData.workOrderName + ']?', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(() => {
                deleteVirtuallyWorkOrderApi(rowData.id).then(() => this.getData())
            });
        },
        /**
         * 设置多工位共享
         */
        OnSetShareEvent() {
            if (this.shareIdSelection.length == 0) {
                this.$notify.error({
                    title: '错误',
                    message: '请选择工位！'
                });
                return
            }
            var ids = this.shareIdSelection.map(item => item.id);
            setShareStationApi({ workOrderIds: ids }).then(rsp => { });
        },

    },
}
</script>

<style scope>
.pushWorkOrder .el-form-item--mini.el-form-item,
.el-form-item--small.el-form-item {
    margin-bottom: 15px !important;
}

.station-card .el-card__header {
    padding: 10px 20px;
}

.pushWorkOrder .el-table td,
.el-table th {
    padding: 5px 0;
}

.pushWorkOrder .checkNone .el-checkbox__input {
    display: none;
}
</style>
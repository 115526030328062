<template>
    <div class="BUploadFileV2" v-loading="loading">
        <div @click="open">
            <slot></slot>
        </div>
        <!-- 点击上传的文件输入框 -->
        <input ref="uploadFileInput" style="display: none;" type="file" :accept="acceptFileType">
    </div>
</template>

<script>
    import {uploadFile} from "@/api/OriginFileUploadApi";

    export default {
        name: 'BUploadFileV2',
        props: {
            businessName: {
                type: String,
                default: () => {
                    return 'temp'
                }
            },
            acceptFileType: {
                type: String,
                default: () => {
                    return null
                }
            },
            otherInfo: {
                type: Object,
                default: () => {
                    return {}
                }
            }
        },
        data() {
            return {
                loading: false,
                uploadFileInput: null,
            }
        },
        mounted() {
            this.uploadFileInput = this.$refs.uploadFileInput
            this.uploadFileInput.addEventListener('change', this.selectedFile, false);
        },
        methods: {
            /*
             * 功能：打开文件选择框
             * Author：潘溢林
             */
            open() {
                if (this.uploadFileInput) {
                    this.uploadFileInput.click()
                }
            },
            /*
             * 功能：选择了文件
             * Author：潘溢林
             */
            selectedFile(e) {
                this.fileUploadV2(e.target.files[0])
                e.stopPropagation()
                e.preventDefault()
            },
            /*
             * 功能：上传文件
             * Author：潘溢林
             */
            fileUploadV2(file) {
                if (!file) return

                this.loading = true
                return new Promise((resolve, reject) => {
                    uploadFile({file, businessName: this.businessName}).then(response => {
                        this.loading = false
                        this.uploadFileInput.value = ''
                        this.$emit('uploadSuccess', {
                            bFile: response,
                            extra: this.otherInfo
                        })
                        return (resolve)
                    }).catch(error => {
                        this.loading = false
                        reject(error)
                    })
                })
            },
        }
    }
</script>

<style scoped>
    .uploadBox {
        width: 100%;
    }
</style>

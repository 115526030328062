import axiosCreate from '@/utils/AxiosUtil'
import localStoreObj from "@/api/localStorage"
import { errorNotice } from "@/utils/NoticeUtil";
import { getBoardTypePrinterApi } from '@/api/authCenter';
let service = axiosCreate({
	baseURL: 'http://127.0.0.1:9180',
})

const utils = {
	base64ToBlob: function (code) {
		code = code.replace(/[\n\r]/g, '')
		// atob() 方法用于解码使用 base-64 编码的字符串。
		const raw = window.atob(code)
		const rawLength = raw.length
		const uInt8Array = new Uint8Array(rawLength)
		for (let i = 0; i < rawLength; ++i) {
			uInt8Array[i] = raw.charCodeAt(i)
		}
		return new Blob([uInt8Array], { type: 'application/pdf' })
	}
}

export function setIp(url) {
	if (url) {
		service = axiosCreate({
			baseURL: url,
		})
	}
}


/**
 * 获取端口列表
 */
export function getSerialList() {
	return service({
		url: '/serial/get',
		method: 'GET'
	});
}

/**
 * 打开端口
 * @param {Object} data 配置参数
 */
export function open(data) {
	return service({
		url: '/serial/open',
		method: 'POST',
		data
	});
}

/**
 * 关闭端口
 */
export function close() {
	return service({
		url: '/serial/close',
		method: 'POST',
	});
}
/**
 * 发送端口
 */
export function send(data) {
	return service({
		url: '/serial/send',
		method: 'POST',
		data,
	});
}
/**
 * 配置端口参数
 */
export function config(data) {
	return service({
		url: '/serial/config',
		method: 'POST',
		data
	});
}

/**
 * 获取窗口数据
 */
export function checkSerialData(data, cancelSource) {
	return service({
		url: '/serial/check/serial/data',
		method: 'POST',
		data,
		cancelToken: cancelSource.token
	});
}
/**
 * 语音播报
 * @param {Object} type 类型： PASS NG OTHER 
 * @param {Object} text 播报信息
 */
export function speech(type, text) {
	return service({
		url: '/speech/get/' + type + "/" + text,
		method: 'get'
	});
}
/**
 *  报表服务作为本地服务进行请求 -> 报表请求接口
 * @param {*} reportName 报表名称，不增加“jasper”扩展名，文件放到FTP路径/home/applications/ftproot/report/
 * @param {*} reportType 默认为PDF
 * @param {*} isPreview 是否预览 默认为预览格式，修改为'N'为直接进行打印，直接打印需要配置printConfig参数
 * @param {*} printConfig 打印参数
 * 				printerName:打印机名称
 * @param {*} cloudPrams 报表业务数据 请求参数，与业务处理接口属性保持一致
 * @param {*} cloudUrl 报表业务数据请求URL，全URL后台使用POST接口，业务接口请使用POST方式进行开发
 * @param {*} jsonData 报表JSON格式数据
 * @param {*} boardType 看板类型，联合看板需要看板+code，格式：CIP_0201
 * 
 * 
 * @returns 
 */

export function reportAndPrint({ reportName = null, boardType = null, reportType = 'PDF', isOpen = 'Y', isPreview = 'Y', printConfig = { printerName: null }, cloudPrams = null, cloudUrl = null, jsonData = null }) {
	// const printerInfo = localStoreObj.getPrinter();
	// console.log(printerInfo,"::",printConfig)
	// if (printerInfo) {
	// 	var localConfig = JSON.parse(printerInfo).filter(item => item.printerType === printConfig.printerName)[0];
	// 	console.log("使用打印机",localConfig)
	// 	if (localConfig) {
	// 		printConfig.printerName = localConfig.printerName;
	// 	}
	// 	if (localConfig) {
	// 		setIp("http://" + localConfig.ip + ":9180");
	// 	} else {
	// 		errorNotice("沒有找到【" + printConfig.printerName + "】打印配置,请联系管理员");
	// 		return;
	// 	}
	// } else {
	// 	errorNotice("本地未配置打印机,请联系管理员");
	// 	return;
	// }
	//2024.04.23 通过看板 + 打印类型 获取打印配置

	getBoardTypePrinterApi(boardType, localStoreObj.getDeviceCodeObj()[boardType]).then(rsp => {
		var localConfig = rsp.filter(item => item.printerType === printConfig.printerName)[0];
		if (!localConfig) {
			errorNotice("沒有找到设备【" + localStoreObj.getDeviceCodeObj()[boardType] + "】【" + printConfig.printerName + "】打印配置,请联系管理员");
			return;
		}
		printConfig.ip = localConfig.ip
		printConfig.printerName = localConfig.printerName;
		console.log("使用打印机", printConfig)
		setIp("http://" + printConfig.ip + ":9180");
		// return new Promise((resolve, reject) => {
		return service({
			url: '/report',
			method: 'POST',
			data: {
				reportName: reportName,
				reportType: reportType,
				isPreview: isPreview,
				printConfig: printConfig,
				cloudPrams: cloudPrams,
				cloudUrl: cloudUrl,
				jsonData: jsonData
			},
		}).then(rsp => {
			if (isPreview === 'Y' && isOpen == 'Y') {
				// console.log(rsp.reportData)
				const blob = utils.base64ToBlob(rsp.reportData)
				window.open(URL.createObjectURL(blob))
			} else {
				return new Promise((resolve, reject) => {
					resolve(rsp)
				})
			}

		});
	})
	// })

}
/**
 * 获取打印机配置
 */
export function getPrinter(ip) {
	setIp("http://" + ip + ":9180");
	return service({
		url: '/print/getLocalPrinter',
		method: 'GET',
	});
}
<template>
    <!-- 主视图布局 -->
    <el-container class="MainLayout backgroundColor" direction="vertical">
        <!-- 抬头栏 -->
        <header-container />
        <!-- 工作视图 -->
        <div class="workLayout">
            <!-- 主视图 -->
            <router-view></router-view>
            <!-- 底部视图 -->
            <footer-container />
        </div>
    </el-container>
</template>

<script>
    import AbsComponent from "@/components/genericComponents/AbsComponent";
    import HeaderContainer from "@/layout/headerContainer/HeaderContainer";
    import MenuContainer from "@/layout/menuContainer/MenuContainer";
    import FooterContainer from "@/layout/footerContainer/FooterContainer";


    export default {
        name: "index",
        components: {HeaderContainer, MenuContainer, FooterContainer},
        extends: AbsComponent
    }
</script>

<style scoped>
    .MainLayout {
        position: fixed;
        width: 100%;
        height: 100%;
    }

    .workLayout {
        display: block;
        flex: 1;
        flex-basis: auto;
        overflow-y: auto;
        overflow-x: hidden;
        box-sizing: border-box;
    }
</style>

import axios from "axios";
import store from "@/store";
import {getToken, getOperator, getCurFc} from "@/utils/CookieUtil";
import {errorNotification, successNotification} from "@/utils/NoticeUtil";

export default function ({ baseURL }) {
    let service = axios.create({
        baseURL: baseURL,
        timeout: process.env.VUE_APP_REQUEST_TIME_OUT
    })

    /* 配置默认请求拦截器 */
    service.interceptors.request.use(config => {
        /* 设置Token */
        let token = store.getters.token
        if (!token) token = getToken()
        config.headers['token'] = token
        // 获取本机工位
        let operator = getOperator()
        if (operator) config.headers['operatorMac'] = operator.operatorMac
        // 获取工厂
        let humanInfo = store.getters.humanInfo
        let factory = getCurFc()
        if (humanInfo && humanInfo.factoryCode) config.headers['fc'] = humanInfo.factoryCode
        else if (factory && factory.factoryCode) config.headers['fc'] = factory.factoryCode

        if (humanInfo && humanInfo.sabkCode) config.headers['bc'] = humanInfo.sabkCode
        else if (factory && factory.sabkCode) config.headers['bc'] = factory.sabkCode

        return config
    }, error => {
        return Promise.reject(error)
    })

    /* 配置默认响应拦截器 */
    service.interceptors.response.use(response => {
        store.dispatch('loading', { loading: false })
        /* 返回的结果 */
        let result = response.data
        /* 如果状态码不等于 0，则代表请求失败，直接转ERROR */
        if (result.code !== 0) return processErrorResult(result)
        /* 返回成功后的最后结果 */
        if (result.isNoticeSuccess !== false) successNotification({ message: result.message, duration: 100 })
        // 返回结果
        return response.data.total !== undefined ? response.data : response.data.data
    }, error  => {
        store.dispatch('loading', { loading: false })
        /* 提示错误 */
        errorNotification({ message: error.message })
        /* 返回错误结果 */
        return Promise.reject(error)
    })

    return service
}


/*
 * 功能：处理错误结果
 * Author：潘溢林
 */
function processErrorResult(result) {
    switch (result.code) {
        case 20007:
            errorNotification({ message: '登录有效期已过，请重新登录!!!' })
            /* 清楚Token */
            store.dispatch('humanRemove')
            break
        default :
            errorNotification({ message: result.message })
    }

    return Promise.reject(result)
}

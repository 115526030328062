<template>
    <span class="BButtonGroup">
        <el-button v-for="(btn, btnIndex) of buttons"
                   :key="btnIndex"
                   :size="btn.size || 'mini'"
                   :type="btn.type || 'primary'"
                   :plain="btn.plain !== false"
                   :round="btn.round"
                   :circle="btn.circle"
                   :loading="btn.loading"
                   :disabled="btn.loading"
                   :icon="btn.icon"
                   :autofocus="btn.autofocus"
                   :native-type="btn.nativeType"
                   :style="btn.style"
                   @click="btn.click">
            <span v-html="btn.txt"></span>
        </el-button>
    </span>
</template>

<script>
    export default {
        name: "BButtonGroup",
        props: {
            buttons: {
                type: Array,
                default: () => {
                    return []
                }
            }
        },
    }
</script>

<style scoped>
    .BButtonGroup {
        margin-bottom: 10px;
    }
</style>

import axios from 'axios'
import {
	getToken
} from "@/utils/CookieUtil";
import {
	errorNotice
} from "@/utils/NoticeUtil";


const service = axios.create({
	baseURL: process.env.VUE_APP_REQUEST_URL,
	timeout: process.env.VUE_APP_REQUEST_TIME_OUT
})

/* 配置默认请求拦截器 */
service.interceptors.request.use(config => {
	/* 设置Token */
	config.headers['token'] = getToken()
	return config
}, error => {
	console.warn('Request Error：', error)
	return error
})

/* 配置默认响应拦截器 */
service.interceptors.response.use(response => {
	/* 返回的结果 */
	let result = response.data
	/* 如果状态码不等于 0，则代表请求失败，直接转ERROR */
	if (result && !result.success) {
		errorNotice(result.message)
		return Promise.reject(result)
	}
	/* 返回成功后的最后结果 */
	// successNotice(result.message)
	return response.data
}, error => {
	/* 提示错误 */
	errorNotice(error.message)
	/* 返回错误结果 */
	return error
})

/* 创建实例 */
export default service

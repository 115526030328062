<template>
    <div id="app">
        <global-notice/>
        <!-- <global-scan/> -->
        <global-audio/>
        <global-downloader/>
        <div style="z-index: 99999; width: 100%; height: 100%; position: fixed;"
             v-if="loadingStatus.loading"
             v-loading="loadingStatus.loading"
             v-loading.fullscreen.lock="true"
             :element-loading-text="loadingStatus.message"
             :element-loading-spinner="loadingStatus.spinner"
             :element-loading-background="loadingStatus.background"></div>
        <router-view v-if="isRouterAlive"></router-view>
    </div>
</template>

<script>
    import GlobalNotice from "@/components/global/GlobalNotice";
    // import GlobalScan from "@/components/global/GlobalScan";
    import GlobalAudio from "@/components/global/GlobalAudio";
    import GlobalDownloader from "@/components/global/GlobalDownloader";
    import { mapGetters } from 'vuex'
    import {notification} from "@/utils/NoticeUtil";

    export default {
        name: 'App',
        components: { GlobalNotice, GlobalAudio, GlobalDownloader },
        computed: {
            ...mapGetters([
                'loadingStatus'
            ])
        },
        provide() {
            return {
                reload: this.reload
            }
        },
        data() {
            return {
                isRouterAlive: true,
            }
        },
        methods: {
            /*
             * 功能：重新加载当前页
             * Author：潘溢林
             */
            reload() {
                notification({ title: '刷新页面中...' })
                this.isRouterAlive = false
                this.$nextTick(() => {
                    notification({ title: '刷新页面完成', type: 'success' })
                    this.isRouterAlive = true
                })
            }
        }
    }
</script>

<style>
    @import "style/common.scss";

    * {
        -webkit-user-select: text !important;
        -moz-user-select: text !important;
    }

    #app {
        font-family: Avenir, Helvetica, Arial, sans-serif;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        color: #2c3e50;
    }

    @media screen and (max-width: 750px) {
        .el-message-box {
            width: 80% !important;
        }
    }

    #app {
        width: 100%;
        height: 100%;
    }
</style>

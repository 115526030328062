import {getCookie, setCookie} from "@/utils/CookieUtil";
import CookieEnum from "@/enums/CookieEnum";

const outLog = getCookie(CookieEnum.outLog.code)

if (outLog === null || outLog === undefined) {
    setCookie(CookieEnum.outLog.code, 'false')
}


export function log(...message) {
    if (outLog === 'true') console.warn(message)
}
import wx from 'weixin-js-sdk'
import store from '../store'
import {getWeChatConfig} from "@/api/common";
import {log} from "@/utils/LogUtil";

/*
 * 功能：是否是微信游览器
 * Author：潘溢林
 */
function isNotWeChatBrowser() {
    let isWeChatBrowser = store.getters.isWeChatBrowser
    log('isWeChatBrowser：', isWeChatBrowser)
    return !isWeChatBrowser
}

/*
 * 功能：初始化方法
 * Author：潘溢林
 */
function init() {
    wx.error(function(res) {
        alert('微信Config错误：' + res.errMsg)
    });
}

export function wxScanCode(callback, close) {
    if (isNotWeChatBrowser()) return
    log('打开微信扫码功能...')

    wx.scanQRCode({
        needResult: 1, // 默认为0，扫描结果由微信处理，1则直接返回扫描结果，
        scanType: ["qrCode","barCode"], // 可以指定扫二维码还是一维码，默认二者都有
        success: function (res) {
            /*alert(JSON.stringify(res))*/
            log('微信扫码结果：', res)

            /* 获取扫描结果 */
            let result = res.resultStr

            /* 如果包含 'scan-' , 则移除 */
            result = result.replace('scan-', '')
            result = result.replace(/CODE_\d+,/g, '')
            log('微信扫描结果处理后：', result)

            if (callback) callback(result)
        },
        cancel: function (res) {
            if (close) close()
        }
    });
}

export function registerConfig() {
    return new Promise((resolve, reject) => {
        if (isNotWeChatBrowser()) return reject
        return resolve(
            getWeChatConfig({
                url: location.href.split('#')[0]
            }).then(res => {
                return wx.config({
                    debug: false, // 开启调试模式,调用的所有api的返回值会在客户端alert出来，若要查看传入的参数，可以在pc端打开，参数信息会通过log打出，仅在pc端时才会打印。
                    appId: res.appid, // 必填，公众号的唯一标识
                    timestamp: res.timestamp, // 必填，生成签名的时间戳
                    nonceStr: res.nonceStr, // 必填，生成签名的随机串
                    signature: res.signature,// 必填，签名
                    jsApiList: ['updateAppMessageShareData', 'updateTimelineShareData', 'scanQRCode'] // 必填，需要使用的JS接口列表
                })
            }).catch(() => {})
        )
    })
}

export function sharePage({ title, desc, link }) {
    wx.ready(function () {   //需在用户可能点击分享按钮前就先调用
        // alert('updateAppMessageShareData invoke')
        wx.updateAppMessageShareData({
            title: title, // 分享标题
            desc: desc, // 分享描述
            link: link, // 分享链接，该链接域名或路径必须与当前页面对应的公众号JS安全域名一致
            imgUrl: 'https://file.baielink.com/system/images/mugualogo.png', // 分享图标
            type: '',
            dataUrl: '',
            success: function () {
                /*alert('更新链接成功，请点击右上角转发给朋友！')*/
            }
        });
    });
}

/* 初始化 */
init()

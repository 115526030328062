<template>
    <div class="BTableView">
        <!-- 查询框 -->
        <div v-if="tableView.searcher && !isEmptyList(tableView.searcher.items)">
            <b-searcher :searcher="tableView.searcher" :defaultData="tableView.table && tableView.table.remoteArgument && tableView.table.remoteArgument.voObject ? tableView.table.remoteArgument.voObject : null" @search="search"/>
        </div>
        <!-- 顶部按钮 -->
        <div v-if="!isEmptyList(tableView.topButtons)" style="margin-bottom: 10px;">
            <b-button-group :buttons="tableView.topButtons"/>
        </div>
        <!-- 表格 -->
        <div v-if="tableView.table" style="margin-bottom: 10px;">
            <b-table ref="bTable" :argument="tableView.table"/>
        </div>
    </div>
</template>

<script>
    import AbsComponent from "@/components/genericComponents/AbsComponent";
    import BButtonGroup from "@/components/genericComponents/element/BButtonGroup";
    import BSearcher from "@/components/genericComponents/element/BSearcher";
    import BTable from "@/components/genericComponents/element/BTable";

    export default {
        name: "BTableView",
        extends: AbsComponent,
        components: {BButtonGroup, BSearcher, BTable},
        props: {
            tableView: {
                type: Object,
                default: () => {
                    return {
                        topButtons: null,
                        searcher: null,
                        table: {},
                    }
                }
            }
        },
        data() {
            return {

            }
        },
        methods: {
            /*
             * 功能：搜索
             * Author：潘溢林
             */
            search(data) {
                let table = this.tableView.table || {}
                let remoteArgument = table.remoteArgument || {}
                let voObject = remoteArgument.voObject || { }

                for (let key in data) {
                    voObject[key] = data[key]
                }

                this.$refs.bTable.handleCurrentChange(1)
            },
        }
    }
</script>

<style scoped>

</style>

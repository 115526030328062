import request from "@/utils/RequestUtil";
import axiosCreate from '@/utils/AxiosUtil'

const service = axiosCreate({
    baseURL: process.env.VUE_APP_COMMON,
})

/*
 * 功能：上传文件
 * Author：潘溢林
 */
export function uploadFile({ businessName='temp', file }) {
    const param = new FormData() // 创建form对象
    param.append('file', file)
    param.append('businessType', businessName)

    return service({
        url:'File/Upload',
        method:'POST',
        data: param
    })
}

export function fileRelation(data) {
    return request({
        /*url: 'mobile/file/relation',*/
        url:'fileUpload/relation',
        method: 'POST',
        data
    })
}

export function fileUnRelation(data) {
    return request({
        // url: 'mobile/file/unRelation',
        url:'fileUpload/unRelationV2',
        method: 'POST',
        data
    })
}

export function relationFileGet(data) {
    return request({
        // url: 'mobile/file/relationFileGet',
        url:'fileUpload/relationFileGet',
        method: 'POST',
        data
    })
}

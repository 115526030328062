<template>
    <div v-if="!isEmptyList(images)">
        <div v-for="(image, imageIndex) of images"
             :key="imageIndex"
             class="imageContainer">

            <el-image class="imageBox"
                      :src="getImageUrl({ image })"
                      :preview-src-list="[getImageUrl({ image })]">
            </el-image>

            <span v-if="showDeleteBtn" class="deleteBtn redBtn el-icon-delete-solid" @click="deleteImage(image, imageIndex)"> 删除</span>

            <div v-if="labelShow" class="normalBtn">图{{imageIndex + 1}}</div>
        </div>
    </div>
</template>

<script>
    import AbsComponent from "@/components/genericComponents/AbsComponent";

    export default {
        name: "BImageGroup",
        extends: AbsComponent,
        props: {
            images: {
                type: Array,
                default: () => {
                    return null
                }
            },
            labelShow: {
                type: Boolean,
                default: () => {
                    return false
                }
            },
            showDeleteBtn: {
                type: Boolean,
                default: () => {
                    return false
                }
            },
            extra: {
                type: Object,
                default: () => {
                    return {}
                }
            }
        },
        watch: {
            images: function () {
                this.$forceUpdate()
            }
        },
        methods: {
            /*
             * 功能：删除图片
             * Author：潘溢林
             */
            deleteImage(image, imageIndex) {
                this.openDialogV2({ title: '提示', message: '确定删除图片？' }).then(res => {
                    this.images.splice(imageIndex, 1)
                    this.$emit('deleteImage', imageIndex, image, this.extra)
                })
            },
        }
    }
</script>

<style scoped>
    .imageContainer {
        display: inline-block;
        text-align: center;
        position: relative;
    }

    .imageBox {
        width: 50px;
        height: 50px;
        border: 1px solid #ccc;
        display: block;
    }

    .imageBox:hover {
        border: 1px solid #409EFF;
    }

    .deleteBtn {
        width: 100%;
        height: 20px;
        display: none;
        position: absolute;
        background-color: rgba(0, 0, 0, 0.5);
        line-height: 20px;
    }

    .imageContainer:hover .deleteBtn {
        display: block;
    }
</style>

import axios from 'axios'
import {getToken} from "@/utils/CookieUtil";
import {errorNotification, successNotification} from "@/utils/NoticeUtil";
import store from '../store'

const service = axios.create({
    baseURL: process.env.VUE_APP_REQUEST_URL,
    timeout: process.env.VUE_APP_REQUEST_TIME_OUT
})

/* 配置默认请求拦截器 */
service.interceptors.request.use(config => {
    /* 设置Token */
    let token = store.getters.token
    if (!token) token = getToken()
    config.headers['token'] = token
    let curFactory = store.getters.curFactory
    if (curFactory && curFactory.factoryCode) config.headers['fc'] = curFactory.factoryCode
    if (curFactory && curFactory.sabkCode) config.headers['bc'] = curFactory.sabkCode
    return config
}, error => {
    return Promise.reject(error)
})

/* 配置默认响应拦截器 */
service.interceptors.response.use(response => {
    store.dispatch('loading', { loading: false })
    /* 返回的结果 */
    let result = response.data
    /* 如果状态码不等于 0，则代表请求失败，直接转ERROR */
    if (result && !result.success) {
        return processErrorResult(result)
    }
    /* 返回成功后的最后结果 */
	// successNotification({ message: result.message, duration: 1000 })
    return response.data
}, error  => {
    store.dispatch('loading', { loading: false })
    /* 提示错误 */
    errorNotification({ message: error.message })
    /* 返回错误结果 */
    return Promise.reject(error)
})

/*
 * 功能：处理错误结果
 * Author：潘溢林
 */
function processErrorResult(result) {
    switch (result.code) {
        case 20007:
            errorNotification({ message: '登录有效期已过，请重新登录!!!' })
            /* 清楚Token */
            store.dispatch('humanRemove')
            break
        default :
            errorNotification({ message: result.message })
    }

    return Promise.reject(result)
}

/* 创建实例 */
export default service

<template>
    <div class="LookupFactoryMaterialComponent">
        <el-input v-model="searchContent" placeholder="请输入厂供料描述!!!" clearable></el-input>
        <div style="font-size: 10px; margin-top: 10px">
            <span>点击封装，自动录入：</span>
            <span @click="appendText('0201')" class="blueBtn">0201</span> |
            <span @click="appendText('0402')"  class="blueBtn">0402</span> |
            <span @click="appendText('0603')"  class="blueBtn">0603</span> |
            <span @click="appendText('0805')"  class="blueBtn">0805</span> |
            <span @click="appendText('1206')"  class="blueBtn">1206</span>
        </div>

        <div class="redBtn" style="font-size: 10px; margin-top: 5px;">必须带封装，否则不准！</div>

        <el-button type="primary" size="mini" icon="el-icon-search"
                   style="width: 100%; margin-top: 10px;" @click="parseMaterial">
            查询厂供料
        </el-button> <br>

        <el-button v-if="isWeChatBrowser()" type="primary" size="mini" icon="el-icon-full-screen"
                   style="width: 100%; margin-top: 10px;" @click="openWxScan">
            扫描厂供二维码
        </el-button>

        <div v-if="searchResult && isEmptyList(factoryMaterials)" class="redBtn">识别不到厂供料</div>

        <div class="factoryMaterialTable" v-for="(material, materialIndex) of factoryMaterials" :key="materialIndex">
            <div class="bltCardTitle">厂供料{{materialIndex + 1}}</div>
            <table>
                <tr>
                    <td>物料描述：</td>
                    <td>{{material.materialDesc}}</td>
                </tr>
                <tr>
                    <td>库存位置：</td>
                    <td>{{material.storeType}}</td>
                </tr>
                <tr>
                    <td>库存数量：</td>
                    <td>{{material.memory}}</td>
                </tr>
                <tr>
                    <td>固定机台：</td>
                    <td>{{material.fixedOperator}}</td>
                </tr>
                <tr v-if="material.useRecords != null && material.useRecords.length > 0">
                    <td>使用记录1：</td>
                    <td v-html="material.useRecords[0]"></td>
                </tr>
                <tr v-if="material.useRecords != null && material.useRecords.length > 1">
                    <td>使用记录2：</td>
                    <td v-html="material.useRecords[1]"></td>
                </tr>
                <tr v-if="material.useRecords != null && material.useRecords.length > 2">
                    <td>使用记录3：</td>
                    <td v-html="material.useRecords[2]"></td>
                </tr>
            </table>
        </div>
    </div>
</template>

<script>
    import AbsComponent from "@/components/genericComponents/AbsComponent";
    import BTableView from "@/components/genericComponents/element/BTableView";
    import {wxScanCode} from "@/utils/WeChatUtil";
    import {materialFastQueryEvent} from "@/views/factory/api";
    import {errorNotification} from "@/utils/NoticeUtil";
    import {factoryMaterialUseRecord, orderFactoryMaterialCodeParser} from "@/api/commonApi";

    export default {
        name: "LookupFactoryMaterialComponent",
        extends: AbsComponent,
        components: {BTableView},
        data() {
            return {
                searchContent: '0R',
                searchResult: false,
                factoryMaterials: [],
            }
        },
        methods: {
            /*
             * 功能：扫码识别厂供料
             * 作者：潘溢林
             * 日期：23/03/03 14:36
             */
            openWxScan() {
                wxScanCode((content) => {
                    this.searchContent = content
                    this.factoryMaterialCodeScan()
                })
            },
            /*
             * 功能：解析物料
             * Author：潘溢林
             */
            parseMaterial() {
                if (!this.searchContent) return errorNotification({ message: '请输入厂供料描述!' })
                this.loadingStart({ message: '查找厂供料描述中...' })
                return materialFastQueryEvent({
                    queryTxt: this.searchContent
                }).then(res => {
                    /* 获取匹配的厂供料 */
                    let materials = res.exactData.macthMaterialList
                    this.getFactoryMaterialUseRecord(materials, res)
                })
            },
            /*
             * 功能：厂供料扫码
             * 作者：潘溢林
             * 日期：23/03/03 14:10
             */
            factoryMaterialCodeScan() {
                this.loadingStart({ message: '识别二维码中...' })
                return orderFactoryMaterialCodeParser({
                    materialCode: this.searchContent
                }).then(res => {
                    this.searchContent = res.content
                    this.getFactoryMaterialUseRecord(res.result)
                })
            },
            getFactoryMaterialUseRecord(materials, res) {
                this.factoryMaterials = []
                this.searchResult = true
                if (this.isEmptyList(materials)) return

                this.loadingStart({ message: '获取厂供料使用记录...' })
                return factoryMaterialUseRecord({
                    fkMaterialIds: materials.map(item => item.pkMaterialId)
                }).then(materialUseRecord => {
                    /* 转DTO */
                    for (let i = 0; i < materials.length; i++) {
                        let material = materials[i]
                        material.storeType = ''
                        material.memory = (material.usableBudgetNumber || 0) + ' / PCS'
                        material.fixedOperator = ''
                        /* 获取存储位置 */
                        if (res && !this.isEmptyList(res.exactData.cachePositionList[i])) {
                            let storeType = res.exactData.cachePositionList[i][0]
                            material.storeType = storeType.cacheName + '-' + storeType.layer + '-' + storeType.startCardNo
                        }
                        /* 获取固定机台位置 */
                        if (res && !this.isEmptyList(res.exactData.fixedList[i])) {
                            let fixedOperator = res.exactData.fixedList[i][res.exactData.fixedList[i].length - 1]
                            material.fixedOperator = '【' + fixedOperator.placeNo + '站】' + fixedOperator.operatorName
                        }

                        let useRecords = materialUseRecord[material.pkMaterialId]

                        if (this.isEmptyList(useRecords)) material['useRecords'] = null
                        else material['useRecords'] = useRecords.map(item => {
                            return '<div class="ellipsis">' + this.formatterTime(item.createTime) + '【' + item.orderNo + '】【工位' + item.workStation + '】' + '</div>'
                        })

                        this.factoryMaterials.push(material)
                    }
                })
            },
            /*
             * 功能：快捷输入
             * Author：潘溢林
             */
            appendText(packageStr) {
                if (this.searchContent) {
                    this.searchContent += ' ' + packageStr
                } else {
                    this.searchContent = packageStr
                }
            },
        }
    }
</script>

<style scoped>
    .factoryMaterialTable {
        margin-top: 10px;
        font-size: 12px;
        padding: 10px;
        border-bottom: 1px solid #E4E7ED;
    }
    .factoryMaterialTable:hover {
        background-color: #fafafa;
    }

    .factoryMaterialTable table tr td:nth-child(1) {
        width: 80px;
        vertical-align: top;
    }
</style>

<template>
    <div class="BMenuItem">
        <el-menu-item v-if="isEmptyList(menu.children) && !menu.hidden" 
                      :index="menu.path"
                      :route="{ name: menu.name, path: menu.path, query: menu.query }">
            <div class="BMenuItemTitle el-icon-menu">
                {{menu.name}}
            </div>
        </el-menu-item>

        <el-submenu v-else-if="!isEmptyList(menu.children)" :index="menu.path">
            <template slot="title">
                <div class="BMenuItemTitle el-icon-menu"> {{menu.name}}</div>
            </template>
            <b-menu-item v-for="(childMenu, childMenuIndex) of menu.children"
                         :key="childMenuIndex"
                         :menu="childMenu"/>
        </el-submenu>
    </div>
</template>

<script>
    import AbsComponent from "@/components/genericComponents/AbsComponent";

    export default {
        name: "BMenuItem",
        extends: AbsComponent,
        props: {
            menu: {
                type: Object,
                default: () => {
                    return {}
                }
            },
            argument: {
                type: Object,
                default: () => {
                    return {}
                }
            },
        },
        methods: {
            /*
             * 功能：是否空list
             * Author：潘溢林
             */
            isEmptyList(list) {
                return !list || list.length <= 0
            },
        }
    }
</script>

<style scoped>
    .BMenuItem .is-active {
        border-bottom: 3px solid red;
    }
    .BMenuItem .el-menu-item{
        padding-left: 20px;
        padding-right: 20px;
        height: 57px;
    }
</style>

import {humanInfoGet, humanLogin, humanIdentityWorkPathGet} from "@/api/commonApi";
import {
    getCookie,
    getHumanInfo,
    saveCurIdentity,
    saveCurWorkPath,
    saveHumanInfo,
    saveIdentities,
    saveToken,
    saveFc
} from "@/utils/CookieUtil";
import {errorNotification, successToast} from "@/utils/NoticeUtil";
import {log} from "@/utils/LogUtil";
import CookieEnum from "@/enums/CookieEnum";

export default {
    login({ commit }, account, password, type) {
        commit('SET_LOADING_STATUS', { loading: true, message: '登录中...' })
        /* 登录工厂端 */
        return humanLogin({ account: account, password, type }).then(token => {
            /* 保存到Vuex */
            commit('SET_TOKEN', token)
            /* 保存到Cookie */
            saveToken(token)
            /* 登录成功 */
            commit('SET_LOADING_STATUS', { loading: false })
        }).catch(() => {
            /* 登录失败 */
            commit('SET_LOADING_STATUS', { loading: false })
        })
    },
    notice({ commit }, { message, duration=5000, type='success' }) {
        /* 打开提示 */
        commit('SET_NOTICE', {
            show: true,
            message,
            type,
        })

        /* 关闭提示 */
        setTimeout(() => {
            commit('SET_NOTICE', {
                show: false, message, type,
            })
        }, duration)
    },
    loading({ commit }, { loading=false, message='拼命加载中...', spinner='el-icon-loading', background='rgba(0, 0, 0, 0.8)' }) {
        commit('SET_LOADING_STATUS', { loading, message, spinner, background })
    },
    humanSave({ commit }, humanInfo) {
        let curHumanInfo = getHumanInfo()
        if (curHumanInfo && curHumanInfo.humanCode !== humanInfo.humanCode) {
            return this.$dialog.confirm({
                title: '请确认更换用户吗？',
                message: '<span class="blackBtn">（原）' + curHumanInfo.humanName + '</span> -> ' +
                         '<span class="redBtn">（新）' + humanInfo.humanName + '</span>',
                allowHtml: true
            }).then(() => {
                /* 保存到Cookie */
                saveHumanInfo(humanInfo)
                /* 保存到缓存 */
                commit('SET_HUMAN_INFO', humanInfo)
                /* 登录成功提示 */
                successToast("登录成功")
                /* 刷新页面 */
                location.reload();
            }).catch(() => {})
        } else {
            return this.$dialog.confirm({
                title: '请确认登录人是否正确',
                message: '工号：<span class="redBtn">' + humanInfo.humanCode + '</span><br>' +
                    '姓名：<span class="redBtn">' + humanInfo.humanName + '</span>',
                allowHtml: true
            }).then(() => {
                /* 保存到Cookie */
                saveHumanInfo(humanInfo)
                /* 保存到缓存 */
                commit('SET_HUMAN_INFO', humanInfo)
                /* 登录成功提示 */
                successToast("登录成功")
                /* 刷新页面 */
                location.reload();
            }).catch(() => {})
        }
    },
    updateIsWeChatBrowser({ commit }) {
        /* 获取游览器类型 */
        let userAgent = navigator.userAgent.toLowerCase()
        log('updateIsWeChatBrowser.userAgent', userAgent)
        /* 是否使用微信游览器打开 */
        let isOpenInWeChat = userAgent.match(/micromessenger/i) !== null
        log('updateIsWeChatBrowser.isOpenInWeChat', isOpenInWeChat)
        /* 如果是使用微信游览器打开，并且要确认是否外网 */
        let isOpenInInternet = location.origin.match(/.*mobile\.baielink\.com.*/)
        /* 保存信息 */
        commit('SET_IS_WE_CHAT_BROWSER', isOpenInWeChat && isOpenInInternet)
    },
    tokenSave({ commit }, token) {
        /* 保存信息 */
        commit('SET_TOKEN', token)
        /* 保存到Cookie */
        saveToken(token)
    },
    humanRemove({ commit }) {
        /* 保存信息 */
        commit('SET_TOKEN', null)
        saveToken(null)
        /* 保存用户信息 */
        commit('SET_HUMAN_INFO', null)
        saveHumanInfo(null)
        /* 保存角色信息 */
        commit('SET_HUMAN_IDENTITIES', null)
        saveIdentities(null)
        /* 保存当前角色到Cookie */
        commit('SET_CUR_IDENTITY', null)
        saveCurIdentity(null)
        /* 保存当前角色工作路径到Cookie */
        commit('SET_CUR_WORK_PATH', null)
        saveCurWorkPath(null)
        /* 刷新页面 */
        location.reload()
    },
    /*
     * 功能：更新用户信息
     * Author：潘溢林
     */
    updateHumanInfo({ commit }) {
        // 获取登录类型
        let loginType = getCookie(CookieEnum.LoginType.code)

        commit('SET_LOADING_STATUS', { message: '获取个人信息中...' })
        return humanInfoGet({ curLoginType: loginType }).then(res => {
            /* 保存用户信息 */
            commit('SET_HUMAN_INFO', res.human)
            saveHumanInfo(res.human)
            // 保存工厂
            commit('SET_CUR_FACTORY', res.fc)
            saveFc(res.fc)
            /* 保存角色信息 */
            commit('SET_HUMAN_IDENTITIES', res.identities)
            saveIdentities(res.identities)
            // 返回结果
            return res
        })
    },
    /*
     * 功能：切换用户身份
     * Author：潘溢林
     */
    toggleIdentity({ commit }, identity) {
        commit('SET_LOADING_STATUS', { message: '切换角色中...' })
        /* 获取角色工作路径 */
        return humanIdentityWorkPathGet({
            identityCode: identity.identityCode
        }).then(res => {
            /* 保存当前角色到Cookie */
            saveCurIdentity(identity)
            /* 保存工作路径到Cookie */
            saveCurWorkPath(res[identity.identityCode])
            /* 更改访问地址 */
            // console.warn(location.protocol + '//' + location.hostname + ':' + location.port + '/#/')
            window.location.replace(location.protocol + '//' + location.hostname + ':' + location.port + '/#/')
            location.reload()
        }).catch(error => {
            commit('SET_LOADING_STATUS', { loading: false })
            return errorNotification({ message: error.message })
        })
    },
    /*
     * 功能：打开扫码弹窗
     * Author：潘溢林
     */
    openScanCodeDialog({ commit }, { callBack, version }) {
        commit('SET_SCAN_QR_CODE', { show: true, callBack: callBack, version })
    },
    /*
     * 功能：打开扫码弹窗
     * Author：潘溢林
     */
    addItemInDownloader({ commit }, downloadItem) {
        commit('ADD_DOWNLOAD_ITEM', downloadItem)
        downloadItem.isAddInList = true
    },
}

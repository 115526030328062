import request from '@/utils/RequestUtil'
import {md5} from "@/utils/CryptoUtil";
import {successNotice, successToast} from "@/utils/NoticeUtil";
import {printJasperReportByPDF} from "@/api/printServer/reportPrintServer";

export function login({ username, password, type= 0 }) {
    return request({
        url: 'user/login',
        method: 'post',
        data: {
            username: username,
            pwd: password ? md5(password) : md5('123456789'),
            type,
        }
    })
}

export function humanLogin({ account, password, verifyCode }) {
    return request({
        url: 'humanController/humanLogin',
        method: 'post',
        data: {
            account,
            passwd: password ? md5(password) : null,
            verifyCode,
        }
    })
}

export function humanInfoGet() {
    return request({
        url: 'humanControllerV2/humanInfoGet',
        method: 'post',
        data: {}
    })
}

export function getOriginBom(data) {
    return request({
        url: 'OrderCr/getOriginBom',
        method: 'POST',
        data
    })
}

export function getOrderDetail(data) {
    return request({
        url: 'moe/getOrderInfo',
        method: 'POST',
        data
    })
}

export function humanGet(data) {
    return request({
        url: 'humanController/humanGet',
        method: 'POST',
        data
    })
}

export function workFlowAdd(data) {
    return request({
        url: 'workFlowController/workFlowAdd',
        method: 'POST',
        data
    })
}

export function workFlowGet(data) {
    return request({
        url: 'workFlowController/workFlowGet',
        method: 'POST',
        data
    })
}

export function craftGetV3(data) {
    return request({
        url: 'craftController/craftGetV3',
        method: 'POST',
        data
    })
}

export function orderWorkFlowStatusGet(data) {
    return request({
        url: 'orderWorkFlowController/orderWorkFlowStatusGet',
        method: 'POST',
        data
    })
}

export function workFlowUpdate(data) {
    return request({
        url: 'workFlowController/workFlowUpdate',
        method: 'POST',
        data
    })
}

export function workFlowDel(data) {
    return request({
        url: 'workFlowController/workFlowDel',
        method: 'POST',
        data
    })
}

export function workFlowGetDetail(data) {
    return request({
        url: 'workFlowController/workFlowGetDetail',
        method: 'POST',
        data
    })
}

export function workFlowDetailAdd(data) {
    return request({
        url: 'workFlowController/workFlowDetailAdd',
        method: 'POST',
        data
    })
}

export function workFlowDetailDel(data) {
    return request({
        url: 'workFlowController/workFlowDetailDel',
        method: 'POST',
        data
    })
}

export function getTypeCode(data) {
    return request({
        url: 'specialMarkController/getTypeCode',
        method: 'POST',
        data
    })
}

export function getQrCodeUrl(data) {
    return request({
        url: 'moe/getQrCodeUrl',
        method: 'post',
        data
    })
}

export function getAudioToken(data) {
    return request({
        url: 'wx/getAudioToken',
        method: 'post',
        data
    })
}

export function getWeChatConfig(data) {
    return request({
        url: 'wx/getWeChatConfig',
        method: 'post',
        data
    })
}

export function identityWorkPathGet(data) {
    return request({
        url: 'humanControllerV2/identityWorkPathGet',
        method: 'post',
        data
    })
}

export function generateOrderLabel(data) {
    return request({
        url: 'OrderV2/generateOrderLabel',
        method: 'POST',
        data,
    })
}

/*
 * 功能：打印订单标识卡
 * Author：潘溢林
 */
export function printOrderLabelV2({ businessId, orderId, mapParm, remarks, equType, equNo, facId }) {
    let printLabelData = {
        orderId,
        businessType: 'ActionLable',
        exportType: 'PDF',
        businessId: businessId,
        remarks: remarks,
        mapParm
    }

    let printConfigParm = {
        fkFactoryId: facId,
        equipmentType: equType,
        equipmentNo: equNo,
    };

    successToast('开始打印标签中...')

    return printJasperReportByPDF(printLabelData, printConfigParm)
}

export function updateCache() {
    successNotice('更新缓存中...')
    return request({
        url: 'WorkShop/updateWorkShopCache',
        method: 'POST',
        data: {}
    }).then(response => {
        successNotice('更新缓存成功...')
    }).catch(error => {})
}


export function craftCommitV3(data) {
    return request({
        url: 'craftController/craftCommitV3',
        method: 'POST',
        data
    })
}


export function orderWorkFlowGetV2(data) {
    return request({
        url: 'orderWorkFlowController/orderWorkFlowGetV2',
        method: 'POST',
        data
    })
}


export function initOrderStandardMaterialCode(data) {
    return request({
        url: 'OrderV2/initOrderStandardMaterialCode',
        method: 'POST',
        data
    })
}

export function operatorLookup(data) {
    return request({
        url: 'Operator/lookup',
        method: 'POST',
        data
    })
}
/**
 * 更新物料状态
 * @param {} data 
 * @returns 
 */
export function updateMaterialStatus(data) {
    return request({
        url: 'Material/updateMaterialStatus',
        method: 'POST',
        data
    })
}
/**
 * 物料合并
 * @param {*} data 
 * @returns 
 */
export function materialListClientMaterialMergeApi(data) {
    return request({
        url: 'NewMaterialList/materialListClientMaterialMerge',
        method: 'POST',
        data
    })
}
/**
 * 更新厂供料来台标签
 * @param {*} data 
 * @returns 
 */
export function updateFactoryCommingStatus(data) {
    return request({
        url: 'OrderV2/updateOrderStatus',
        method: 'POST',
        data
    })
}

export function coordinateTextRasterizeEvent(data) {
    return request({
        url: 'merchandiser/coordinateTextRasterizeEvent',
        method: 'POST',
        data
    })
}
<template>
    <div class="BMenuContainer">
        <el-menu :default-active="curPath"
                 :router="true"
                 :mode="argument.mode || 'horizontal'"
                 @select="selectMenu"
                 :collapse="argument.collapse"
                 :background-color="argument.backgroundColor"
                 :text-color="argument.textColor"
                 :active-text-color="argument.activeTextColor"
                 :unique-opened="argument.uniqueOpened"
                 :menu-trigger="argument.menuTrigger"
                 :collapse-transition="argument.collapseTransition">
            <div v-for="(menu, menuIndex) of curWorkPath"
                 :key="menuIndex">
                <b-menu-item v-for="(childMenu, childMenuIndex) of menu.children"
                             :key="childMenuIndex"
                             :menu="childMenu"
                             :argument="argument"
                             :class="{ isHorizontal: argument.mode !== 'vertical' }"/>
            </div>
        </el-menu>
    </div>
</template>

<script>
    import AbsComponent from "@/components/genericComponents/AbsComponent";
    import BMenuItem from "@/layout/components/BMenuItem";
    import { mapGetters } from 'vuex'

    export default {
        name: "BMenuContainer",
        inject: ['reload'],
        extends: AbsComponent,
        components: { BMenuItem },
        computed: {
            ...mapGetters([
                'curWorkPath', 'curPath' // 工厂ID
            ])
        },
        props: {
            argument: {
                type: Object,
                default: () => {
                    return {}
                }
            },
        },
        created() {
            this.$store.commit('SET_CUR_PATH', this.$route.path)
        },
        methods: {
            /*
             * 功能：选择菜单
             * Author：潘溢林
             */
            selectMenu(index, path) {
                if (index !== this.curPath) this.$store.commit('SET_CUR_PATH', index)
                else this.reload()
            },
        }
    }
</script>

<style scoped>
    .BMenuContainer {
    }

    .isHorizontal {
        display: inline-block;
    }
</style>

import request from "@/utils/RequestUtil";
import requestTable from "@/utils/RequestTableUtil";

export function getOrderCenterData(data) {
    return request({
        url: 'OrderV2/getOrderCenterData',
        method: 'POST',
        data
    })
}

export function orderWorkFlowGet(data) {
    return request({
        url: 'orderWorkFlowController/orderWorkFlowGet',
        method: 'POST',
        data
    })
}

export function getOrderDetailV2(data) {
    return request({
        url: 'moe/getOrderDetailV2',
        method: 'POST',
        data
    })
}

export function businessHumanGet(data) {
    return request({
        url: 'craftControllerV2/businessHumanGet',
        method: 'POST',
        data
    })
}

export function craftAbsWorkOrderGet(data) {
    return request({
        url: 'craftControllerV2/craftAbsWorkOrderGet',
        method: 'POST',
        data
    })
}

export function craftAddWorkOrder(data) {
    return request({
        url: 'craftControllerV2/craftAddWorkOrder',
        method: 'POST',
        data
    })
}

export function craftWorkOrderGet(data) {
    return request({
        url: 'craftController/craftWorkOrderGet',
        method: 'POST',
        data
    })
}

export function craftWorkOrderPublish(data) {
    return request({
        url: 'craftControllerV2/craftWorkOrderPublish',
        method: 'POST',
        data
    })
}

export function craftWorkOrderCancel(data) {
    return request({
        url: 'craftControllerV2/craftWorkOrderCancel',
        method: 'POST',
        data
    })
}

export function clientMaterialLessGet(data) {
    return request({
        url: 'craftController/clientMaterialLessGet',
        method: 'POST',
        data
    })
}

export function absActionGet(data) {
    return request({
        url: 'commonControllerV2/absActionGet',
        method: 'POST',
        data
    })
}

export function craftWorkOrderProcess(data) {
    return request({
        url: 'craftController/craftWorkOrderProcess',
        method: 'POST',
        data
    })
}

export function craftWorkOrderProcessDelete(data) {
    return request({
        url: 'craftController/craftWorkOrderProcessDelete',
        method: 'POST',
        data
    })
}

export function craftWorkOrderProcessGet(data) {
    return request({
        url: 'craftController/craftWorkOrderProcessGet',
        method: 'POST',
        data
    })
}

export function craftWorkOrderEdit(data) {
    return request({
        url: 'craftController/craftWorkOrderEdit',
        method: 'POST',
        data
    })
}

export function craftActionGroupGet(data) {
    return request({
        url: 'craftController/craftWorkOrderGroupGet',
        method: 'POST',
        data
    })
}

export function supplierAdd(data) {
    return request({
        url: 'supplierControllerV2/supplierAdd',
        method: 'POST',
        data
    })
}

export function supplierGet(data) {
    return request({
        url: 'supplierControllerV2/supplierGet',
        method: 'POST',
        data
    })
}

export function supplierDelete(data) {
    return request({
        url: 'supplierControllerV2/supplierDelete',
        method: 'POST',
        data
    })
}

export function supplierUpdate(data) {
    return request({
        url: 'supplierControllerV2/supplierUpdate',
        method: 'POST',
        data
    })
}

export function productDelete(data) {
    return request({
        url: 'supplierControllerV2/productDelete',
        method: 'POST',
        data
    })
}

export function productUpdate(data) {
    return request({
        url: 'supplierControllerV2/productUpdate',
        method: 'POST',
        data
    })
}

export function productGet(data) {
    return request({
        url: 'supplierControllerV2/productGet',
        method: 'POST',
        data
    })
}

export function productAdd(data) {
    return request({
        url: 'supplierControllerV2/productAdd',
        method: 'POST',
        data
    })
}

export function productGetDetail(data) {
    return request({
        url: 'supplierControllerV2/productGetDetail',
        method: 'POST',
        data
    })
}

export function supplierProductDetailDelete(data) {
    return request({
        url: 'supplierControllerV2/supplierProductDetailDelete',
        method: 'POST',
        data
    })
}

export function supplierProductDetailGet(data) {
    return request({
        url: 'supplierControllerV2/supplierProductDetailGet',
        method: 'POST',
        data
    })
}

export function supplierProductDetailAdd(data) {
    return request({
        url: 'supplierControllerV2/supplierProductDetailAdd',
        method: 'POST',
        data
    })
}

export function supplierGetDetail(data) {
    return request({
        url: 'supplierControllerV2/supplierGetDetail',
        method: 'POST',
        data
    })
}

export function supplierProductConfigAdd(data) {
    return request({
        url: 'supplierControllerV2/supplierProductConfigAdd',
        method: 'POST',
        data
    })
}

export function supplierProductConfigDel(data) {
    return request({
        url: 'supplierControllerV2/supplierProductConfigDel',
        method: 'POST',
        data
    })
}

export function productGetSupplier(data) {
    return request({
        url: 'supplierControllerV2/productGetSupplier',
        method: 'POST',
        data
    })
}

export function sendVerifyCode(data) {
    return request({
        url: 'commonControllerV2/sendVerifyCode',
        method: 'POST',
        data
    })
}

export function productTypeUpdate(data) {
    return request({
        url: 'supplierControllerV2/productTypeUpdate',
        method: 'POST',
        data
    })
}

export function productTypeGet(data) {
    return request({
        url: 'supplierControllerV2/productTypeGet',
        method: 'POST',
        data
    })
}

export function productTypeDelete(data) {
    return request({
        url: 'supplierControllerV2/productTypeDelete',
        method: 'POST',
        data
    })
}

export function productTypeAdd(data) {
    return request({
        url: 'supplierControllerV2/productTypeAdd',
        method: 'POST',
        data
    })
}

export function productTypeGenerateQrCode(data) {
    return request({
        url: 'supplierControllerV2/productTypeGenerateQrCode',
        method: 'POST',
        data
    })
}

export function qrCodeInit(data) {
    return request({
        url: 'qrCodeController/qrCodeInit',
        method: 'POST',
        data
    })
}

export function qrCodeGet(data) {
    return request({
        url: 'qrCodeController/qrCodeGet',
        method: 'POST',
        data
    })
}

export function qrCodeEvent(data) {
    return request({
        url: 'qrCodeController/qrCodeEvent',
        method: 'POST',
        data
    })
}

export function qrCodeSearch(data) {
    return request({
        url: 'qrCodeController/qrCodeSearch',
        method: 'POST',
        data
    })
}

export function qrCodeSearchTable(data) {
    return requestTable({
        url: 'qrCodeController/qrCodeSearch',
        method: 'POST',
        data
    })
}

export function getOrderByTagName(data) {
    return request({
        url: 'commonControllerV2/getOrderByTagName',
        method: 'POST',
        data
    })
}

export function getOrderInfoByOrderNo(data) {
    return request({
        url: 'commonControllerV2/getOrderInfoByOrderNo',
        method: 'POST',
        data
    })
}

export function GetMountPluginBomOperatorV2(data) {
    return request({
        url: 'Production/GetMountPluginBomOperatorV2',
        method: 'POST',
        data
    })
}

export function getMountOperatorMaterialV2(data) {
    return request({
        url: 'Production/getMountOperatorMaterialV2',
        method: 'POST',
        data
    })
}

export function downloadMountProgramV2(data) {
    return request({
        url: 'Production/downloadMountProgramV2',
        method: 'POST',
        data
    })
}

export function expressAdd(data) {
    return request({
        url: 'expressControllerV2/expressAdd',
        method: 'POST',
        data
    })
}

export function expressDel(data) {
    return request({
        url: 'expressControllerV2/expressDel',
        method: 'POST',
        data
    })
}

export function expressGet(data) {
    return requestTable({
        url: 'expressControllerV2/expressGet',
        method: 'POST',
        data
    })
}

export function expressUpdate(data) {
    return request({
        url: 'expressControllerV2/expressUpdate',
        method: 'POST',
        data
    })
}

export function expressGetTrace(data) {
    return request({
        url: 'expressControllerV2/expressGetTrace',
        method: 'POST',
        data
    })
}

export function expressSign(data) {
    return request({
        url: 'expressControllerV2/expressSign',
        method: 'POST',
        data
    })
}

export function expressGenerateLabel(data) {
    return request({
        url: 'expressControllerV2/expressGenerateLabel',
        method: 'POST',
        data
    })
}

export function getClientInfo(data) {
    return request({
        url: 'commonControllerV2/getClientInfo',
        method: 'POST',
        data
    })
}

export function expressWaitSignView(data) {
    return request({
        url: 'expressControllerV2/expressWaitSignView',
        method: 'POST',
        data
    })
}

export function clientGet(data) {
    return requestTable({
        url: 'ClientControllerV2/clientGet',
        method: 'POST',
        data
    })
}

export function clientDetailUpdate(data) {
    return request({
        url: 'ClientControllerV2/clientDetailUpdate',
        method: 'POST',
        data
    })
}

export function clientUpdate(data) {
    return request({
        url: 'ClientControllerV2/clientUpdate',
        method: 'POST',
        data
    })
}

export function uploadPlaceNoTable(data) {
    return request({
        url: 'craftControllerV2/uploadPlaceNoTable',
        method: 'POST',
        data
    })
}

export function getPrepareClientMaterial(data) {
    return request({
        url: 'craftControllerV2/getPrepareClientMaterial',
        method: 'POST',
        data
    })
}

export function materialPrepareCheck(data) {
    return request({
        url: 'craftControllerV2/materialPrepareCheck',
        method: 'POST',
        data
    })
}

export function factoryMaterialPrintLabel(data) {
    return request({
        url: 'craftControllerV2/factoryMaterialPrintLabel',
        method: 'POST',
        data
    })
}

export function materialCacheStoreGet(data) {
    return requestTable({
        url: 'MaterialCacheController/materialCacheStoreGet',
        method: 'POST',
        data
    })
}

export function materialCacheGet(data) {
    return request({
        url: 'MaterialCacheController/materialCacheGet',
        method: 'POST',
        data
    })
}

export function materialCacheBindCode(data) {
    return request({
        url: 'MaterialCacheController/materialCacheBindCode',
        method: 'POST',
        data
    })
}

export function materialCacheCodeRemove(data) {
    return request({
        url: 'MaterialCacheController/materialCacheCodeRemove',
        method: 'POST',
        data
    })
}

export function parseMaterial(data) {
    return request({
        url: 'OrderCr/parseMaterial',
        method: 'POST',
        data
    })
}

export function materialFastQueryEvent(data) {
    return request({
        url: 'warehouseKeeper/materManage/materialFastQueryEvent',
        method: 'POST',
        data
    })
}

export function materialCodeParse(data) {
    return request({
        url: 'Material/materialCodeParse',
        method: 'POST',
        data
    })
}

export function materialCacheCarAdd(data) {
    return request({
        url: 'MaterialCacheController/materialCacheCarAdd',
        method: 'POST',
        data
    })
}

export function materialCacheCarUpdate(data) {
    return request({
        url: 'MaterialCacheController/materialCacheCarUpdate',
        method: 'POST',
        data
    })
}

export function materialCacheCarGet(data) {
    return requestTable({
        url: 'MaterialCacheController/materialCacheCarGet',
        method: 'POST',
        data
    })
}

export function materialCacheCarDelete(data) {
    return request({
        url: 'MaterialCacheController/materialCacheCarDelete',
        method: 'POST',
        data
    })
}

export function materialCacheLayerGet(data) {
    return request({
        url: 'MaterialCacheController/materialCacheLayerGet',
        method: 'POST',
        data
    })
}

export function materialCacheLayerDelete(data) {
    return request({
        url: 'MaterialCacheController/materialCacheLayerDelete',
        method: 'POST',
        data
    })
}

export function materialCacheLayerAdd(data) {
    return request({
        url: 'MaterialCacheController/materialCacheLayerAdd',
        method: 'POST',
        data
    })
}

export function materialCacheLayerUpdate(data) {
    return request({
        url: 'MaterialCacheController/materialCacheLayerUpdate',
        method: 'POST',
        data
    })
}

export function materialCacheCarLayerDetailGet(data) {
    return request({
        url: 'MaterialCacheController/materialCacheCarLayerDetailGet',
        method: 'POST',
        data
    })
}

export function materialCacheLayerImport(data) {
    return uploadFile({
        url: 'MaterialCacheController/materialCacheLayerImport',
        method: 'POST',
        data
    })
}

export function printLongLabel(data) {
    return request({
        url: 'labelController/printLongLabel',
        method: 'POST',
        data
    })
}

export function materialCommandStatusUpdate(data) {
    return request({
        url: 'craftControllerV2/materialCommandStatusUpdate',
        method: 'POST',
        data
    })
}

export function materialCacheCarMachineDel(data) {
    return request({
        url: 'MaterialCacheController/materialCacheCarMachineDel',
        method: 'POST',
        data
    })
}

export function materialCacheCarMachineGet(data) {
    return request({
        url: 'MaterialCacheController/materialCacheCarMachineGet',
        method: 'POST',
        data
    })
}

export function materialCacheCarMachineAdd(data) {
    return request({
        url: 'MaterialCacheController/materialCacheCarMachineAdd',
        method: 'POST',
        data
    })
}

export function absMachineGet(data) {
    return request({
        url: 'absMachine/lookup',
        method: 'POST',
        data
    })
}

export function materialCacheEnabled(data) {
    return request({
        url: 'MaterialCacheController/materialCacheEnabled',
        method: 'POST',
        data
    })
}

export function orderAddContract(data) {
    return request({
        url: 'contract/addContract',
        method: 'POST',
        data
    })
}

export function bomDraftQuoteSelectEvent(data) {
    return request({
        url: 'mobile/bomDraftQuoteSelectEvent',
        method: 'POST',
        data
    })
}

export function updateOrderStatus(data) {
    return request({
        url: 'OrderV2/updateOrderStatus',
        method: 'POST',
        data
    })
}

export function bomDraftMergeContract(data) {
    return request({
        url: 'microController/bomDraftMergeContract',
        method: 'POST',
        data
    })
}

export function testMaterialEnterTestItem(data) {
    return request({
        url: 'testMaterialController/testMaterialEnterTestItem',
        method: 'POST',
        data
    })
}

export function productTypeGenerateQrCodeByOrder(data) {
    return request({
        url: 'supplierControllerV2/productTypeGenerateQrCodeByOrder',
        method: 'POST',
        data
    })
}

//报表请求
export function jasperReq(data) {
    return request({
        url: 'reportForm/reportFormDataByInputStream',
        method: 'POST',
        data
    })
}

export function getBomQuestion(data) {
    return request({
        url: 'bomQuestion/getBomQuestion',
        method: 'post',
        data
    })
}

export function bomQuestionProcess(data) {
    return request({
        url: 'bomQuestion/bomQuestionProcess',
        method: 'post',
        data
    })
}

export function bomQuestionPublish(data) {
    return request({
        url: 'bomQuestion/bomQuestionPublish',
        method: 'post',
        data
    })
}

export function bomDraftOfferEventV2(data) {
    return request({
        url: 'mobile/bomDraftOfferEventV2',
        method: 'post',
        data
    })
}

export function bomDraftByBomDraftGroupIdView({bomDraftGroupId}) {
    return request({
        url: 'mobile/bomDraftByBomDraftGroupIdView',
        method: 'post',
        data: {bomDraftGroupId}
    })
}

export function bomDraftArgumentConfirmEvent(data) {
    return request({
        url: 'mobile/bomDraftArgumentConfirmEvent',
        method: 'post',
        data
    })
}

export function bomEffectView({fkBomDraftId, process}) {
    return request({
        url: 'mobile/bomEffectView',
        method: 'post',
        data: {fkBomDraftId, process}
    })
}

export function bomEffectOperateRecordBuildBatchEvent({voList}) {
    return request({
        url: 'mobile/bomEffectOperateRecordBuildBatchEvent',
        method: 'post',
        data: {voList}
    })
}


export function clientOfferView(data) {
    return request({
        url: 'mobile/clientOfferView',
        method: 'post',
        data
    })
}
export function clientInfoSelectEvent(data) {
    return request({
        url: 'mobile/clientInfoSelectEvent',
        method: 'post',
        data
    })
}


export function clientOfferBuildEvent(data) {
    return request({
        url: 'mobile/clientOfferBuildEvent',
        method: 'post',
        data
    })
}

export function contractCancelEvent(data) {
    return request({
        url: 'mobile/contractCancelEvent',
        method: 'post',
        data
    })
}

export function clientOfferBuildBatchEvent(data) {
    return request({
        url: 'mobile/clientOfferBuildBatchEvent',
        method: 'post',
        data
    })
}


export function contractDealEvent(data) {
    return request({
        url: 'mobile/contractDealEvent',
        method: 'post',
        data
    })
}

export function bomDraftGroupNoView({pkBomDraftId,offerCode,clientId}) {
    return request({
        url: 'mobile/bomDraftGroupNoView',
        method: 'post',
        data:{pkBomDraftId,offerCode,clientId}
    })
}


export function accountPeriodRejoinEventV2(data) {
    return request({
        url: 'mobile/accountPeriodRejoinEventV2',
        method: 'post',
        data
    })
}

export function bomDraftDealAfterRequoteEvent(data) {
    return request({
        url: 'mobile/bomDraftDealAfterRequoteEvent',
        method: 'post',
        data
    })
}

export function getMountBomCrChange(data) {
    return request({
        url: 'Production/getMountBomCrChange',
        method: 'post',
        data
    })
}

export function checkMountBomCrChange(data) {
    return request({
        url: 'Production/checkMountBomCrChange',
        method: 'post',
        data
    })
}

export function updateOrderDownProgramState(data) {
    return request({
        url: 'OrderV2/updateOrderDownProgramState',
        method: 'post',
        data
    })
}

// clientAddress: "华创创业园2栋3楼"company: "华创科技有限公司"fkClientId: "10109"receiver: "张闯100000"receiverPhone: "15660828521"
export function addClientReceivingAddress({clientAddress,company,fkClientId,receiver,receiverPhone }) {
    return request({
        url: 'clientReceivingAddress/addClientReceivingAddress',
        method: 'post', data:{clientAddress,company,fkClientId,receiver,receiverPhone }
    })
}

export function uploadFile(api) {
    let param = new FormData()
    if (api.data) {
        for (let property in api.data) {
            console.log(api.data[property])
            param.append(property, api.data[property])
        }
    }
    console.log('param = ', param)
    return request({
        url: api.url,
        method: api.method,
        headers: { 'Content-Type': 'multipart/form-data' },
        data: param
    })
}

export function bomDraftEditEvent({
                                      pkBomDraftId, orderNumber, packageType, urgentAmount, causeNote, dealStatus, openSteelNet, fixture, chargeMaterialAmount,
                                      bomFilePath, coordinateFile, gerberFile, pakeFile, fkOrder, bgaNumber, dipNumber, screenPrinting, screenPrintingB,
                                      smtFixtureAmount, dipFixtureAmount, steelNetAmount, freightAmount, orderBomFile, taxRate, turnoverAmount, packAmount, workOfferMode, realDealPrice, pcsNumber, floatDoubleFace, physicalPicture1, physicalPicture2, fkBomDraftQuote, dipUnitPrice,
                                      pcbSizeHeight, pcbSizeWidth, pcbType,clientDipFixtureNumber,replenishDipFixture,steelnetMoldType,steelnetSizeType,offerDiscount,customizeDipNumber,maxDipPinNumber
                                  }) {
    return request({
        url: 'mobile/bomDraftEditEvent',
        method: 'POST',
        data: {
            pkBomDraftId,
            orderNumber,
            packageType,
            urgentAmount,
            causeNote,
            dealStatus,
            openSteelNet,
            fixture,
            chargeMaterialAmount,
            bomFilePath,
            coordinateFile,
            gerberFile,
            pakeFile,
            fkOrder,
            bgaNumber,
            dipNumber,
            screenPrinting,
            screenPrintingB,
            smtFixtureAmount,
            dipFixtureAmount,
            steelNetAmount,
            freightAmount,
            orderBomFile,
            taxRate,
            turnoverAmount,
            packAmount,
            workOfferMode,
            realDealPrice,
            pcsNumber,
            floatDoubleFace,
            physicalPicture1,
            physicalPicture2,
            fkBomDraftQuote,
            dipUnitPrice,
            pcbSizeHeight,
            pcbSizeWidth,
            pcbType,clientDipFixtureNumber,replenishDipFixture,steelnetMoldType,steelnetSizeType,offerDiscount,customizeDipNumber,maxDipPinNumber
        }
    })
}

export function setBomDraftTaxRate(data) {
    return request({
        url: 'mobile/setBomDraftTaxRate',
        method:'POST',
        data
    })
}

export function bomDraftSetProductName(data) {
    return request({
        url: 'mobile/bomDraftSetProductName',
        method:'POST',
        data
    })
}

export function downloadAoiProgramV2(data) {
    return request({
        url:'Production/downloadAoiProgramV2',
        method:'POST',
        data
    })
}

export function craftGetOrderProductive(data) {
    return request({
        url:'craftController/craftGetOrderProductive',
        method:'POST',
        data
    })
}

export function downloadFirstTestExcelV2(data) {
    return request({
        url:'Production/downloadFirstTestExcelV2',
        method:'POST',
        data
    })
}

export function getFirstTestPositionData(data) {
    return request({
        url:'craftControllerV2/getFirstTestPositionData',
        method:'POST',
        data
    })
}

export function accountStatementBuildEventV2({pkSendPackItemIdList, pkBomDraftContractItemIds,pkBomDraftIdList, accountStatementAmountList, accountStatementNumberList,paymentDayNumber }) {
    return request({
        url: 'mobile/accountStatementBuildEventV2',
        method: 'post', data: {pkSendPackItemIdList, pkBomDraftContractItemIds,pkBomDraftIdList, accountStatementAmountList, accountStatementNumberList,paymentDayNumber }
    })
}

export function orderAccountStatementBuildEventV2({pkBomDraftIdList, accountStatementAmountList, accountStatementNumberList, pkBomDraftContractItemIds,paymentDayNumber}) {
    return request({
        url: 'mobile/orderAccountStatementBuildEventV2', method: 'post', data: {pkBomDraftIdList, accountStatementAmountList, accountStatementNumberList, pkBomDraftContractItemIds,paymentDayNumber}
    })
}

export function accountStatementToXlsEvent({pkAccountStatementId }) {
    return request({
        url: 'mobile/accountStatementToXlsEvent',
        method: 'post', data:{pkAccountStatementId }
    })
}

export function accountStatementViewV2({all,fkHumanId,fkClientId,clientName,fkBusinessId,accountSettlementStatus ,pageindex,pagesize,year,months,orderBy,accountSettlementStatusList}) {
    return requestTable({
        url: 'mobile/accountStatementViewV2',
        method: 'post', data:{all,fkHumanId,fkClientId,clientName,fkBusinessId,accountSettlementStatus ,pageindex,pagesize,year,months,orderBy,accountSettlementStatusList }
    })
}

export function clientAccountStatementViewV2({fkClientId}) {
    return request({
        url: 'mobile/clientAccountStatementViewV2',
        method: 'post', data: {fkClientId}
    })
}

export function bomDraftContractItemView({accountStatementStatus, bomDraftIds, clientsId, pageindex, pagesize}) {
    return requestTable({
        url: 'mobile/bomDraftContractItemView',
        method: 'post', data: {accountStatementStatus, bomDraftIds, clientsId, pageindex, pagesize}
    })
}

export function bomDraftContractItemView2({bomDraftIds,clientsId}) {
    return requestTable({
        url: 'mobile/bomDraftContractItemView2',
        method: 'post', data: {bomDraftIds,clientsId}
    })
}



export function bomDraftContractItemToAccountStatementEvent({pkAccountStatementId, pkBomDraftContractItemIds}) {
    return requestTable({
        url: 'mobile/bomDraftContractItemToAccountStatementEvent',
        method: 'post', data: {pkAccountStatementId, pkBomDraftContractItemIds}
    })
}

export function sendPackRecordView({fkHumanId,pkSendPackSetId,pkSendPackRecordIds,pkSendPackItemId,fkClientId,logisticsType,sendPackNo,clientName,orderNo,bomTagName,businessLogisticType,outStoreStatus,sendPackTimeStart,sendPackTimeEnd,accountStatementStatus,sender, senderPhone, senderAddress,pageindex,pagesize,invoiceStatus}) {
    return requestTable({
        url: 'mobile/sendPackRecordView',
        method: 'post', data: {fkHumanId,pkSendPackSetId,pkSendPackRecordIds,pkSendPackItemId,fkClientId,logisticsType,sendPackNo,clientName,orderNo,bomTagName,businessLogisticType,outStoreStatus,sendPackTimeStart,sendPackTimeEnd,accountStatementStatus,sender, senderPhone, senderAddress,pageindex,pagesize,invoiceStatus}
    })
}

export function accountStatementSummaryViewV2({all,fkHumanId,fkClientId,clientName,fkBusinessId,accountSettlementStatus ,pageindex,pagesize,year,months,orderBy,accountSettlementStatusList }) {
    return requestTable({
        url: 'mobile/accountStatementSummaryViewV2',
        method: 'post', data:{all,fkHumanId,fkClientId,clientName,fkBusinessId,accountSettlementStatus ,pageindex,pagesize,year,months,orderBy,accountSettlementStatusList }
    })
}

export function accountReceivableView({year,month,businessId,clientName,fkHumanId}) {
    return requestTable({
        url: 'mobile/accountReceivableView',
        method: 'post', data: {year, month,businessId,clientName,fkHumanId}
    })
}

export function accountReceivableView2({year,month,businessId,clientName,fkHumanId,clientId,getDataDetail}) {
    return requestTable({
        url: 'mobile/accountReceivableView2',
        method: 'post', data: {year, month,businessId,clientName,fkHumanId,clientId,getDataDetail}
    })
}

export function accountReceivableView3({year,month,businessId,clientName,fkHumanId,clientId,getDataDetail}) {
    return request({
        url: 'mobile/accountReceivableView3',
        method: 'post', data: {year, month,businessId,clientName,fkHumanId,clientId,getDataDetail}
    })
}

export function accountReceivableClientView({year,month,businessId,clientName,fkHumanId,clientId,getDataDetail}) {
    return request({
        url: 'mobile/accountReceivableClientView',
        method: 'post', data: {year, month,businessId,clientName,fkHumanId,clientId,getDataDetail}
    })
}

export function selectShipmentPayment({year,month,businessId,clientName,fkHumanId,clientId,getDataDetail}) {
    return requestTable({
        url: 'mobile/selectShipmentPayment',
        method: 'post', data: {year, month,businessId,clientName,fkHumanId,clientId,getDataDetail}
    })
}


export function monthUrgeAccountSaveEvent({monthUrgeAccountItemDoList,title}) {
    return requestTable({
        url: 'mobile/monthUrgeAccountSaveEvent',
        method: 'post', data: {monthUrgeAccountItemDoList,title}
    })
}

export function monthUrgeAccountView({ pkMonthUrgeAccountTitleId }) {
    return requestTable({
        url: 'mobile/monthUrgeAccountView',
        method: 'post', data: { pkMonthUrgeAccountTitleId }
    })
}


export function accountStatementItemPaymentView({year,month,fkClientId}) {
    return requestTable({
        url: 'mobile/accountStatementItemPaymentView',
        method: 'post', data: {year, month,fkClientId}
    })
}

export function accountStatementDeleteEvent({pkAccountStatementId}) {
    return requestTable({
        url: 'mobile/accountStatementDeleteEvent',
        method: 'post', data: {pkAccountStatementId}
    })
}

export function accountStatementResetEvent({pkAccountStatementId}) {
    return requestTable({url: 'mobile/accountStatementResetEvent', method: 'post', data: {pkAccountStatementId}})
}
export function contractPostBackEvent({pkOrderContractId,files}) {
    return requestTable({url: 'mobile/contractPostBackEvent', method: 'post', data: {pkOrderContractId,files}})
}


export function invoiceBillPreviewEventV2({
                                              clientId,
                                              orderIdList, invoiceNumberList,
                                              sendPackItemIdList,
                                              pkAccountStatementId,pkAccountStatementItemIds,
                                              pkInvoiceBillId,sendPackItemId,bomDraftContractItemIdList
                                          }) {
    return requestTable({
        url: 'mobile/invoiceBillPreviewEventV2',
        method: 'post', data: {
            clientId,
            orderIdList, invoiceNumberList,
            sendPackItemIdList,
            pkAccountStatementId,pkAccountStatementItemIds,
            pkInvoiceBillId,sendPackItemId,bomDraftContractItemIdList
        }
    })
}

export function accountStatementDetailViewV2({pkAccountStatementId,fkSendPackItemId}) {
    return request({url: 'mobile/accountStatementDetailViewV2', method: 'post', data: {pkAccountStatementId,fkSendPackItemId}})
}

export function accountStatementSingleViewV2({pkBomDraftId, pkAccountStatementId}) {
    return request({
        url: 'mobile/accountStatementSingleViewV2',
        method: 'post',
        data: {pkBomDraftId, pkAccountStatementId}
    })
}

export function accountStatementSingleBuildEvent({pkBomDraftId}) {
    return request({
        url: 'mobile/accountStatementSingleBuildEvent',
        method: 'post',
        data: {pkBomDraftId}
    })
}

export function accountStatementSubmitAuditEventV2({files, pkAccountStatementId, paymentAmount,paymentMethod,cachePaymentFlag}) {
    return request({url: 'mobile/accountStatementSubmitAuditEventV2', method: 'POST',
        data: {files, pkAccountStatementId, paymentAmount,paymentMethod,cachePaymentFlag}
    })
}

export function accountStatementCachePaymentSubmitEvent({pkAccountStatementPaymentId, cachePaymentFlag, files}) {
    return request({
        url: 'mobile/accountStatementCachePaymentSubmitEvent', method: 'POST',
        data: {pkAccountStatementPaymentId, cachePaymentFlag, files}
    })
}

// /** 审核 对账付款流水的金额*/
// BigDecimal paymentAmount;
// /** 对账付款流水id*/
// Long pkAccountStatementPaymentId;
export function accountStatementResolveAuditEventV2({pkAccountStatementPaymentId, paymentAmount,paymentTime}) {
    return request({
        url: 'mobile/accountStatementResolveAuditEventV2',
        method: 'POST',
        data: {pkAccountStatementPaymentId, paymentAmount,paymentTime}
    })
}


export function accountStatementResolveAuditEventV3({pkAccountStatementPaymentId, paymentAmount, paymentTime}) {
    return request({
        url: 'mobile/accountStatementResolveAuditEventV3',
        method: 'POST',
        data: {pkAccountStatementPaymentId, paymentAmount, paymentTime}
    })
}

export function accountStatementSettleEvent({pkAccountStatementId}) {
    return request({
        url: 'mobile/accountStatementSettleEvent',
        method: 'POST',
        data: {pkAccountStatementId}
    })
}

// /** 业务员ID*/Long businessId;/** 年份*/String year;/** 月份  多个 可选择的 */List<String> months;String clientName;

export function commissionRecordViewV2({fkHumanId,businessId, businessIds,year, months, clientName,pageSize,pageindex,pageNum}) {
    return requestTable({
        url: 'mobile/commissionRecordViewV2',
        method: 'POST',
        data: {fkHumanId,businessId,businessIds, year, months, clientName,pageSize,pageindex,pageNum}
    })
}

export function commissionRecordViewV2OutXls({fkHumanId,businessId, businessIds,year, months, clientName,pageSize,pageindex,pageNum}) {
    return request({
        url: 'mobile/commissionRecordViewV2OutXls',
        method: 'POST',
        data: {fkHumanId,businessId,businessIds, year, months, clientName,pageSize,pageindex,pageNum}
    })
}


export function commissionAmountComputerEvent({pkAccountStatementItemId, paymentTime}) {
    return requestTable({
        url: 'mobile/commissionAmountComputerEvent',
        method: 'POST',
        data: {pkAccountStatementItemId, paymentTime}
    })
}


export function allCommissionAmountComputerEvent({pkAccountStatementItemId, paymentTime}) {
    return requestTable({
        url: 'mobile/allCommissionAmountComputerEvent',
        method: 'POST',
        data: {pkAccountStatementItemId, paymentTime}
    })
}

export function accountStatementItemAddRebateEvent({pkAccountStatementItemId, rebatePrice, businessConfigType}) {
    return request({
        url: 'mobile/accountStatementItemAddRebateEvent',
        method: 'POST',
        data: {pkAccountStatementItemId, rebatePrice, businessConfigType}
    })
}



export function accountStatementItemAddImposeAmountEvent({pkAccountStatementItemId, imposeAmount, imposeAmountNote,imposeAmountVerify}) {
    return request({
        url: 'mobile/accountStatementItemAddImposeAmountEvent',
        method: 'POST',
        data: {pkAccountStatementItemId, imposeAmount, imposeAmountNote,imposeAmountVerify}
    })
}

export function ClientInfoListByAfterHenchman({afterHenchmanId, humanId, roleEnName, phone, clientName}) {
    return request({
        url: 'clientsController/selectClientNamesByAfterHenchman',
        method: 'POST',
        data: {afterHenchmanId, humanId, roleEnName, phone, clientName}
    })
}

/**
 * 获取员工列表（平台）
 * @param pageindex 从哪页开始
 * @param pagesize 一页多少
 * @param fkDepartmentInfo 部门外键
 * @param fkOrgInfo 工厂外键
 * @param sex 性别
 * @param chName 姓名
 * @param activityStatus 状态
 * @param workType 员工类型
 */
export function empList({ pageindex, pagesize, fkDepartmentInfo, fkOrgInfo, sex, chName, activityStatus, workType }) {
    return request({
        url: 'workerinfo/selectTiaojian',
        method: 'post',
        data: {
            pageindex,
            pagesize,
            fkDepartmentInfo,
            fkOrgInfo,
            sex,
            chName,
            activityStatus,
            workType
        }
    })
}

export function invoiceTitleEditEvent({pkClientContractMessageId,fkClientId, identificationNo, company,
                                          account, address, openDeposit,
                                          landlinePhone, isDefault, imagePath,overwrite}) {
    return request({
        url: 'mobile/invoiceTitleEditEvent', method: 'POST', data: {
            pkClientContractMessageId,fkClientId, identificationNo, company,
            account, address, openDeposit,
            landlinePhone, isDefault, imagePath,overwrite}
    });
}
export function invoiceTitleView({clientId}) {
    return request({
        url: 'mobile/invoiceTitleView', method: 'POST', data: {clientId}
    });
}

export function clientAccountConfigEvent({pkClientAccountId,invoiceNoteType}) {
    return request({
        url: 'mobile/clientAccountConfigEvent',
        method: 'POST',
        data: {pkClientAccountId,invoiceNoteType}
    });
}

export function invoiceTitleDefaultSetEvent({invoiceTitleId,clientId}) {
    return request({
        url: 'mobile/invoiceTitleDefaultSetEvent', method: 'POST', data: {invoiceTitleId,clientId}
    });
}

export function invoiceBillBuildEventV2(invoiceBillPreviewDto) {
    return request({
        url: 'mobile/invoiceBillBuildEventV2',
        method: 'POST',
        data: invoiceBillPreviewDto
    });
}

// {"fkClientId":"11972","identificationNo":"纳税人识别号","company":"公司名称",
//     "account":"账户账户","address":"公司地址","openDeposit":"开户行开户行",
//     "landlinePhone":"座机座机","isDefault":"Y","imageList":[]}
export function invoiceTitleBuildEvent({ fkClientId, identificationNo, company,
                                           account, address, openDeposit,
                                           landlinePhone, isDefault, imageList,}) {
    return request({
        url: 'mobile/invoiceTitleBuildEvent',
        method: 'POST',
        data: {fkClientId, identificationNo, company,
            account, address, openDeposit,
            landlinePhone, isDefault, imageList,}
    });
}

export function invoiceBillView({pageindex, pagesize, invoiceStatus, fkClientId,clientName,createTimeStart,createTimeEnd,fkBusinessId,fkHumanId}) {
    return requestTable({url: 'mobile/invoiceBillView', method: 'POST',
        data: {pageindex, pagesize, invoiceStatus, fkClientId,clientName,createTimeStart,createTimeEnd,fkBusinessId,fkHumanId}
    });
}


export function onInvoiceBillViewOutXls({pageindex, pagesize, invoiceStatus, fkClientId,clientName,createTimeStart,createTimeEnd,fkBusinessId,fkHumanId}) {
    return request({url: 'mobile/onInvoiceBillViewOutXls', method: 'POST',
        data: {pageindex, pagesize, invoiceStatus, fkClientId,clientName,createTimeStart,createTimeEnd,fkBusinessId,fkHumanId}
    });
}

export function newClientDealSelectView({date, year, month}) {
    return request({
        url: 'mobile/newClientDealSelectView', method: 'POST',
        data: {date, year, month}
    });
}

export function invoiceBillViewSum({pageindex, pagesize, invoiceStatus, fkClientId,clientName,createTimeStart,createTimeEnd,fkBusinessId,fkHumanId}) {
    return requestTable({url: 'mobile/invoiceBillViewSum', method: 'POST',
        data: {pageindex, pagesize, invoiceStatus, fkClientId,clientName,createTimeStart,createTimeEnd,fkBusinessId,fkHumanId}
    });
}


export function invoiceBillDoneEvent({pkInvoiceBillId, files, invoiceCode, invoiceTime, invoiceUrl}) {
    return request({
        url: 'mobile/invoiceBillDoneEvent',
        method: 'POST',
        data: {pkInvoiceBillId, files, invoiceCode, invoiceTime, invoiceUrl}
    });
}

export function invoiceBillDisabledEvent({pkInvoiceBillId}) {
    return request({
        url: 'mobile/invoiceBillDisabledEvent',
        method: 'POST',
        data: {pkInvoiceBillId}
    });
}

export function invoiceBillBusinessTaskEvent({pkInvoiceBillId,process}) {
    return request({
        url: 'mobile/invoiceBillBusinessTaskEvent',
        method: 'POST',
        data: {pkInvoiceBillId,process}
    });
}

export function invoiceBillChangeTitleEvent({pkInvoiceBillId,invoiceTitleId}) {
    return request({
        url: 'mobile/invoiceBillChangeTitleEvent', method: 'POST', data: {pkInvoiceBillId,invoiceTitleId}
    });
}

export function commonInvoiceTitleView({invoiceTitle,fkClientId}) {
    return request({
        url: 'mobile/commonInvoiceTitleView', method: 'POST', data: {invoiceTitle,fkClientId}
    });
}

export function commonInvoiceTitleBuildEvent({invoiceTitle,dutyNumber,registerAddress,baseAccountBankName,fkClientId}) {
    return request({
        url: 'mobile/commonInvoiceTitleBuildEvent', method: 'POST', data: {invoiceTitle,dutyNumber,registerAddress,baseAccountBankName,fkClientId}
    });
}

export function accountStatementForceCompleteEvent({fkBomDraftId}) {
    return request({
        url: 'mobile/accountStatementForceCompleteEvent', method: 'POST', data: {fkBomDraftId}
    });
}

export function accountStatementRefreshEvent({pkAccountStatmentIdList}) {
    return request({
        url: 'mobile/accountStatementRefreshEvent', method: 'POST', data: {pkAccountStatmentIdList}
    });
}

export function accountPeriodShipmentJoinEvent({pkBomDraftId}) {
    return request({
        url: 'mobile/accountPeriodShipmentJoinEvent', method: 'POST', data: {pkBomDraftId}
    });
}

export function accountPeriodResettingEvent({clientId}) {
    return request({
        url: 'mobile/accountPeriodResettingEvent', method: 'POST', data: {clientId}
    });
}

/**
 * remindStatus 提醒状态：0、未到账期对账 1、对账提醒(含即将逾期对账) 2、即将逾期对账 3、逾期对账
 * createrId 业务员ID
 * clientId 客户ID
 */
export function accountPeriodControlPanelView({remindStatus,createrId,clientId,fkHumanId}) {
    return request({
        url: 'mobile/accountPeriodControlPanelView', method: 'POST', data: {remindStatus,createrId,clientId,fkHumanId}
    });
}


export function addClientSettlementWayApply({fkClientId,creditAmount,pkReconciliationTimeEnumId,nextReconciliationTime,paymentDays,nextMonth}) {
    return request({
        url:'clientSettlementWayApply/addClientSettlementWayApply',
        method:'POST',
        data:{fkClientId,creditAmount,pkReconciliationTimeEnumId,nextReconciliationTime,paymentDays,nextMonth}
    });
}

export function clientSettlementWayConfigEvent({fkClientId, fkReconciliationTimeEnumId, paymentDays, monthPeriodType, dayStep}) {
    return request({
        url: 'mobile/clientSettlementWayConfigEvent',
        method: 'POST',
        data: {fkClientId, fkReconciliationTimeEnumId, paymentDays, monthPeriodType, dayStep}
    });
}

export function selectReconciliationTimeEnum() {
    return request({
        url:'clientSettlementWay/selectReconciliationTimeEnum',
        method:'POST',
    });
}
export function updateClientCodeByClientId({bussinessClientCode,pkClientIdDo,isAuto}) {
    return request({
        url:'client/updateClientCodeByClientId',
        method:'POST',
        data:{bussinessClientCode,pkClientIdDo,isAuto}
    });
}
export function clientRelocateEvent({ extBusinessId, afterHenchmanId, clientId }) {
    return request({
        url: 'merchandiser/clientRelocateEvent', method: 'POST',
        data: { extBusinessId, afterHenchmanId, clientId }
    })
}

export function bomDraftBusinessView({pageindex, pagesize,dealStatus,createrId,clientId,timeSlot,clientName,startTime,endTime,offerCode,orderStatus}) {
    return request({
        url: 'mobile/bomDraftBusinessView',
        method: 'POST',
        data: {
            pageindex, pagesize,dealStatus,createrId,clientId,timeSlot,clientName,startTime,endTime,offerCode,orderStatus
        }
    })
}

export function accountPeriodShipmentJoinEventToOrderIds(data) {
    return request({
        url: 'mobile/accountPeriodShipmentJoinEventToOrderIds',
        method: 'POST',
        data
    })
}

/** 业务报价视图 */
export function salesmanBomDraftView({offerType,orderNumber,note,orderNo, saleName,sendPackStatus,fkOrderGroupIndexId, sendPackEndTime,sendPackStartTime,clientId,notExistIncomingMaterial,
                                         orderStatus,pageindex, pagesize, clientName, offerCode, productName, taxRate, remindStatus,
                                         startTime, endTime, discount, fkHumanId,dealStatus,timeType,pkBomDraftIds,dullStatus,isDel,sendPackNotAccount,offerStatus, incomingMaterialStartTime,incomingMaterialEndTime}) {
    return request({
        url: 'mobile/salesmanBomDraftView',
        method: 'POST',
        data:{offerType,orderNumber,note, orderNo,saleName,sendPackStatus,fkOrderGroupIndexId,sendPackEndTime,sendPackStartTime,clientId,notExistIncomingMaterial,
            orderStatus,pageindex, pagesize, clientName, offerCode, productName, taxRate, remindStatus, startTime, endTime, discount, fkHumanId,dealStatus,
            timeType,pkBomDraftIds,dullStatus,isDel,sendPackNotAccount,offerStatus, incomingMaterialStartTime,incomingMaterialEndTime }
    })
}

/** 业务报价视图 */
export function sumSalesmanBomDraftView({offerType,orderNumber,note,sendPackEndTime,sendPackStartTime,pageindex, pagesize, clientName, offerCode, productName, taxRate, remindStatus, startTime, endTime, discount, fkHumanId,dealStatus,timeType,pkBomDraftIds,dullStatus,isDel,sendPackNotAccount,offerStatus }) {
    return request({ url: 'mobile/sumSalesmanBomDraftView', method: 'POST',
        data:{ offerType,orderNumber,note,sendPackEndTime,sendPackStartTime,pageindex, pagesize, clientName, offerCode, productName, taxRate, remindStatus, startTime, endTime, discount, fkHumanId,dealStatus,timeType,pkBomDraftIds,dullStatus,isDel,sendPackNotAccount,offerStatus }
    })
}

/** 业务员-列表 */
export function allHumanListView({}) {
    return request({
        url: 'mobile/allHumanListView',
        method: 'POST',
        data: {}
    })
}

export function bomDraftSetNoteOrHighlightTypeEvent({pkBomDraftId, highlightType, note}) {
    return request({
        url: 'mobile/bomDraftSetNoteOrHighlightTypeEvent',
        method: 'POST',
        data: {pkBomDraftId, highlightType, note}
    })
}


export function clientHeatFameView({businessId, heatType,clientId}) {
    return request({
        url: 'mobile/clientHeatFameView',
        method: 'POST',
        data: {businessId, heatType,clientId}
    })
}

export function clientMarkLabelSettingEvent({clientId, typeName, mark, pkClientMarkLabelId, isDel}) {
    return request({
        url: 'mobile/clientMarkLabelSettingEvent',
        method: 'POST',
        data: {clientId, typeName, mark, pkClientMarkLabelId, isDel}
    })
}

export function clientMarkLabelTemplateView({}) {
    return request({
        url: 'mobile/clientMarkLabelTemplateView',
        method: 'POST',
        data: {}
    })
}

export function clientMarkLabelTemplateBuildEvent({typeName,mark}) {
    return request({
        url: 'mobile/clientMarkLabelTemplateBuildEvent',
        method: 'POST',
        data: {typeName,mark}
    })
}

export function clientCharacterImageView({clientId, pkBomDraftId}) {
    return request({
        url: 'mobile/clientCharacterImageView',
        method: 'POST',
        data: {clientId, pkBomDraftId}
    })
}

export function clientCharacterImageSettingEvent({pkClientId,clientAddress, decisioner, affecter, informer, orderType, businessExpensesNeed, engineerNumber, floorNumber,productType,clientSource,isContractPostBack}) {
    return request({
        url: 'mobile/clientCharacterImageSettingEvent',
        method: 'POST',
        data: {pkClientId,clientAddress, decisioner, affecter, informer, orderType, businessExpensesNeed, engineerNumber, floorNumber,productType,clientSource,isContractPostBack}
    })
}

/** trackType 跟进类型：1微信沟通2晚Call3登门 造访4对账 */
/** executeCycle 执行周期：1每天 2按周 3按月 4单次 */
/** executeCycle 执行节点： 多个 逗点隔开 1,3,4,5 */
/** planType 计划类型：1自定义的，2系统定义的 */
export function businessSchedulePlanBuildEvent({fkClient,trackType,executeCycle,executeNodes,planType,scheduleDescription }) {
    return request({
        url: 'mobile/businessSchedulePlanBuildEvent',
        method: 'POST',
        data: {fkClient,trackType,executeCycle,executeNodes,planType,scheduleDescription }
    })
}

// Long fkHumanId;
// Long clientId;
/** 执行状态：N未执行Y已执行*/
// String executeStatus;
export function businessScheduleTaskBuildEvent(data) {
    return request({
        url: 'mobile/businessScheduleTaskBuildEvent',
        method: 'POST',
        data: data
    })
}

export function businessDailyBuildEvent(data) {
    return request({
        url: 'mobile/businessDailyBuildEvent',
        method: 'POST',
        data: data
    })
}

export function businessDailyRemindView({humanId}) {
    return request({
        url: 'mobile/businessDailyRemindView',
        method: 'POST',
        data: {humanId}
    })
}

export function businessDailyView(data) {
    return request({
        url: 'mobile/businessDailyView',
        method: 'POST',
        data: data
    })
}
export function businessDailyToPdfEvent({pkBusinessDailyId}) {
    return request({
        url: 'mobile/businessDailyToPdfEvent',
        method: 'POST',
        data: {pkBusinessDailyId}
    })
}
export function businessWeekPlanDoToPdfEvent({pkBusinessWeekPlanId}) {
    return request({
        url: 'mobile/businessWeekPlanDoToPdfEvent',
        method: 'POST',
        data: {pkBusinessWeekPlanId}
    })
}

export function businessWeekPlanBuildEvent(data) {
    return request({
        url: 'mobile/businessWeekPlanBuildEvent',
        method: 'POST',
        data: data
    })
}
export function businessWeekPlanEidtEvent(data) {
    return request({
        url: 'mobile/businessWeekPlanEidtEvent',
        method: 'POST',
        data: data
    })
}

export function businessWeekPlanView(data) {
    return request({
        url: 'mobile/businessWeekPlanView',
        method: 'POST',
        data: data
    })
}

export function businessScheduleTaskEditEvent({pkBusinessScheduleTaskId, fkClient, trackType, scheduleDescription, verifyStatus, executeBusinessId, mastermindId, executeTime, submitEventVo}) {
    return request({
        url: 'mobile/businessScheduleTaskEditEvent',
        method: 'POST',
        data: {pkBusinessScheduleTaskId, fkClient, trackType, scheduleDescription, verifyStatus, executeBusinessId, mastermindId, executeTime, submitEventVo}
    })
}
// Long fkHumanId;
// Long clientId;
/** 执行状态：N未执行Y已执行*/
// String executeStatus;
export function businessScheduleTaskView({fkHumanId, clientId, executeStatus, pageindex, pagesize}) {
    return request({
        url: 'mobile/businessScheduleTaskView',
        method: 'POST',
        data: {fkHumanId, clientId, executeStatus, pageindex, pagesize}
    })
}

export function businessScheduleTaskVisitorEvent({fkClient}) {
    return request({
        url: 'mobile/businessScheduleTaskVisitorEvent',
        method: 'POST',
        data: {fkClient}
    })
}

export function businessScheduleTaskViewV2({fkHumanId, clientId, month, week, trackType, today}) {
    return request({
        url: 'mobile/businessScheduleTaskViewV2',
        method: 'POST',
        data: {fkHumanId, clientId, month, week, trackType, today}
    })
}
export function businessScheduleTaskSubmitEvent({pkBusinessScheduleTaskId, remarkTime, recordRemark, files}) {
    return request({
        url: 'mobile/businessScheduleTaskSubmitEvent',
        method: 'POST',
        data: {pkBusinessScheduleTaskId, remarkTime, recordRemark, files}
    })
}

export function businessScheduleTaskCancelEvent({pkBusinessScheduleTaskId}) {
    return request({
        url: 'mobile/businessScheduleTaskCancelEvent',
        method: 'POST',
        data: {pkBusinessScheduleTaskId}
    })
}

export function businessSchedulePlanEditEvent({pkBusinessSchedulePlanId, fkClient, trackType, executeCycle, executeNodes, planType, scheduleDescription}) {
    return request({
        url: 'mobile/businessSchedulePlanEditEvent',
        method: 'POST',
        data: {pkBusinessSchedulePlanId, fkClient, trackType, executeCycle, executeNodes, planType, scheduleDescription}
    })
}

export function businessSchedulePlanView({fkHumanId,clientId,clientName}) {
    return request({
        url: 'mobile/businessSchedulePlanView',
        method: 'POST',
        data: {fkHumanId,clientId,clientName}
    })
}


export function businessScheduleTaskInstallEvent({fkClient ,fkHumanId}) {
    return request({
        url: 'mobile/businessScheduleTaskInstallEvent',
        method: 'POST',
        data: {fkClient ,fkHumanId}
    })
}

export function onSelectBomDraftContractItemTemplate({}) {
    return request({
        url: 'mobile/onSelectBomDraftContractItemTemplate',
        method: 'POST',
        data: {}
    })
}

export function bomDraftContractItemAppendEvent(data) {
    return request({
        url: 'mobile/bomDraftContractItemAppendEvent',
        method: 'POST',
        data: data
    })
}
export function bomDraftContractItemEditEvent(data) {
    return request({
        url: 'mobile/bomDraftContractItemEditEvent',
        method: 'POST',
        data: data
    })
}

export function orderGroupIndexInitEvent(data) {
    return request({
        url: 'mobile/orderGroupIndexInitEvent',
        method: 'POST',
        data: data
    })
}
export function bomDraftComplexLevelInitEvent(data) {
    return request({
        url: 'mobile/bomDraftComplexLevelInitEvent',
        method: 'POST',
        data: data
    })
}

export function printOutPriceAnalysis(data) {
    return request({
        url: 'mobile/bomDraftComplexLevelInitEvent_printOutPriceAnalysis',
        method: 'POST',
        data: data
    })
}


export function bomDraftBuildOrderGroupIndexEvent({bomDraftId}) {
    return request({
        url: 'mobile/bomDraftBuildOrderGroupIndexEvent',
        method: 'POST',
        data: {bomDraftId}
    })
}

export function orderGroupIndexNameEditEvent({pkOrderGroupIndexId,orderGroupIndexName}) {
    return request({
        url: 'mobile/orderGroupIndexNameEditEvent',
        method: 'POST',
        data: {pkOrderGroupIndexId,orderGroupIndexName}
    })
}


export function bomDataCompareEvent(data) {
    return request({
        url: 'mobile/bomDataCompareEvent',
        method: 'POST',
        data: data
    })
}

export function bomDataCompareFctypeEvent({pkBomDraftId}) {
    return request({
        url: 'mobile/bomDataCompareFctypeEvent',
        method: 'POST',
        data: {pkBomDraftId}
    })
}


export function bomDataCompareEvent_onDifferenceOpera({srcBomDataCompareList, toBomDataCompareList}) {
    return request({
        url: 'mobile/bomDataCompareEvent_onDifferenceOpera',
        method: 'POST',
        data: {srcBomDataCompareList, toBomDataCompareList}
    })
}

export function orderGroupIndexView({clientId, fkHumanId, businessId, orderGroupIndexName, clientName}) {
    return request({
        url: 'mobile/orderGroupIndexView',
        method: 'POST',
        data: {clientId, fkHumanId, businessId, orderGroupIndexName, clientName}
    })
}



export function businessBonusGameBuildEvent({gameTimeNo, gameAreaName, startTime, endTime, bonusPoolAmount, targetAmouont, joinGroupList}) {
    return request({
        url: 'mobile/businessBonusGameBuildEvent',
        method: 'POST',
        data: {gameTimeNo, gameAreaName, startTime, endTime, bonusPoolAmount, targetAmouont, joinGroupList}
    })
}

export function businessBonusGameView({}) {
    return request({
        url: 'mobile/businessBonusGameView',
        method: 'POST',
        data: {}
    })
}

export function businessBonusGameStateView({pkBusinessBonusGameId}) {
    return request({
        url: 'mobile/businessBonusGameStateView',
        method: 'POST',
        data: {pkBusinessBonusGameId}
    })
}
export function businessBonusGameSaveEvent({businessBonusGameStateViewDto}) {
    return request({
        url: 'mobile/businessBonusGameSaveEvent',
        method: 'POST',
        data: {businessBonusGameStateViewDto}
    })
}


export function updateClientNameAndClientPhone({pkClientId, clientName, phone, accountStatementNote}) {
    return request({
        url: 'mobile/updateClientNameAndClientPhone',
        method: 'POST',
        data: {pkClientId, clientName, phone, accountStatementNote}
    })
}

export function pushBomDraftGroup({pkBomDraftId, bomDraftGroupNo}) {
    return request({
        url: 'mobile/pushBomDraftGroup',
        method: 'POST',
        data: {pkBomDraftId, bomDraftGroupNo}
    })
}

export function removeBomDraftGroup({pkBomDraftId,offerCode,clientId,bomDraftGroupNo}) {
    return request({
        url: 'mobile/removeBomDraftGroup',
        method: 'POST',
        data: {pkBomDraftId,offerCode,clientId,bomDraftGroupNo}
    })
}

export function bomDraftContractItemAppendDelEvent({pkBomDraftContractItemId}) {
    return request({
        url: 'mobile/bomDraftContractItemAppendDelEvent',
        method: 'POST',
        data: {pkBomDraftContractItemId}
    })
}


export function clientFileLibraryBuildEvent({clientId,fileId,offerType,makeType,pkBomDraftIds}) {
    return request({
        url: 'mobile/clientFileLibraryBuildEvent', method: 'POST', data: {clientId,fileId,offerType,makeType,pkBomDraftIds}
    })
}


export function clientFileLibraryExportProductionEvent({productionFileList}) {
    return request({
        url: 'mobile/clientFileLibraryExportProductionEvent', method: 'POST', data: {productionFileList}
    })
}

export function placeOrderPanelView({offerCode,clientName,businessName,productName}) {
    return request({
        url: 'mobile/placeOrderPanelView', method: 'POST', data: {offerCode,clientName,businessName,productName}
    })
}

export function placeOrderPanelToClientEvent({clientId}) {
    return request({
        url: 'mobile/placeOrderPanelToClientEvent', method: 'POST', data: {clientId}
    })
}

export function placeOrderPanelAlertClockEvent({pkBomDraftId, filePlanTime, fileHolder, fileHolderPhone,fileIds}) {
    return request({
        url: 'mobile/placeOrderPanelAlertClockEvent',
        method: 'POST',
        data: {pkBomDraftId, filePlanTime, fileHolder, fileHolderPhone,fileIds}
    })
}

export function placeOrderPanelExceptionalEvent({pkBomDraftId, fileExceptionalCode}) {
    return request({
        url: 'mobile/placeOrderPanelExceptionalEvent',
        method: 'POST',
        data: {pkBomDraftId, fileExceptionalCode}
    })
}

export function expressReceiptMaterialView({signInNo}) {
    return request({
        url: 'mobile/expressReceiptMaterialView',
        method: 'POST',
        data: {signInNo}
    })
}

export function expressReceiptClaimBuildEvent({signInNo,expressInfo,fkClient,fileIds}) {
    return request({
        url: 'mobile/expressReceiptClaimBuildEvent',
        method: 'POST',
        data: {signInNo,expressInfo,fkClient,fileIds}
    })
}
export function expressReceiptClaimDelectEvent({pkExpressReceiptClaimId}) {
    return request({
        url: 'mobile/expressReceiptClaimDelectEvent',
        method: 'POST',
        data: {pkExpressReceiptClaimId}
    })
}



export function expressReceiptClaimView({clientId, humanId}) {
    return request({
        url: 'mobile/expressReceiptClaimView',
        method: 'POST',
        data: {clientId, humanId}
    })
}

export function expressReceiptClaimSubmitEvent({signInNo,pkBomDraftIds}) {
    return request({
        url: 'mobile/expressReceiptClaimSubmitEvent',
        method: 'POST',
        data: {signInNo,pkBomDraftIds}
    })
}

export function expressReceiptClaimSortingEvent({signInNo,claimType,expressInfo}) {
    return request({
        url: 'mobile/expressReceiptClaimSortingEvent',
        method: 'POST',
        data: {signInNo,claimType,expressInfo}
    })
}

export function businessPlaceOrderPanelAddEvent({pkBomDraftIds}) {
    return request({
        url: 'mobile/businessPlaceOrderPanelAddEvent',
        method: 'POST',
        data: {pkBomDraftIds}
    })
}

export function placeOrderPanelCountView({}) {
    return request({
        url: 'mobile/placeOrderPanelCountView',
        method: 'POST',
        data: {}
    })
}

export function placeOrderPlanTaskView({}) {
    return request({
        url: 'mobile/placeOrderPlanTaskView',
        method: 'POST',
        data: {}
    })
}

export function placeOrderPlanTaskBuildEvent({}) {
    return request({
        url: 'mobile/placeOrderPlanTaskBuildEvent',
        method: 'POST',
        data: {}
    })
}

export function placeOrderPlanTaskCancelEvent({pkPlaceOrderPlanTaskId,cancelCause}) {
    return request({
        url: 'mobile/placeOrderPlanTaskCancelEvent',
        method: 'POST',
        data: {pkPlaceOrderPlanTaskId,cancelCause}
    })
}

export function materialToBomDraftSelectEvent({materialCode,materialDesc,clientId}) {
    return request({
        url: 'mobile/materialToBomDraftSelectEvent',
        method: 'POST',
        data: {materialCode,materialDesc,clientId}
    })
}


export function bomDraftOfferRangeRoundView({offerRangeType}) {
    return request({
        url: 'mobile/bomDraftOfferRangeRoundView',
        method: 'POST',
        data: {offerRangeType}
    })
}
export function bomDraftOfferRangeConfigEvent(data) {
    return request({
        url: 'mobile/bomDraftOfferRangeConfigEvent',
        method: 'POST',
        data: data
    })
}
export function bomDraftOfferRangeRoundConfigEvent({pkBomDraftOfferRangeId,headPrice,footPrice}) {
    return request({
        url: 'mobile/bomDraftOfferRangeRoundConfigEvent',
        method: 'POST',
        data: {pkBomDraftOfferRangeId,headPrice,footPrice}
    })
}
export function bomDraftOfferActiveConfigEvent({pkBomDraftOfferRangeId, offerRangeType}) {
    return request({
        url: 'mobile/bomDraftOfferActiveConfigEvent',
        method: 'POST',
        data: {pkBomDraftOfferRangeId, offerRangeType}
    })
}

export function clientBaseInfoView({clientId, pkBomDraftId}) {
    return request({
        url: 'mobile/clientBaseInfoView',
        method: 'POST',
        data: {clientId, pkBomDraftId}
    })
}

export function bomDraftDealForgoEvent({pkBomDraftId}) {
    return request({
        url: 'mobile/bomDraftDealForgoEvent',
        method: 'POST',
        data: {pkBomDraftId}
    })
}

export function businessPriceTypeConfigEvent({pkClientId, businessPriceType}) {
    return request({
        url: 'mobile/businessPriceTypeConfigEvent',
        method: 'POST',
        data: {pkClientId, businessPriceType}
    })
}
export function clientPriceProtocolConfigEvent(clientPriceProtocolDo) {
    return request({
        url: 'mobile/clientPriceProtocolConfigEvent',
        method: 'POST',
        data: clientPriceProtocolDo
    })
}

export function bomDraftRecordBuildEvent({fkBomDraftId, offerMethod, offerStrength, workHourPrice, offerPrice,note}) {
    return request({
        url: 'mobile/bomDraftRecordBuildEvent',
        method: 'POST',
        data: {fkBomDraftId, offerMethod, offerStrength, workHourPrice, offerPrice,note}
    })
}

export function bomDraftRecordDeleteEvent({pkBomDraftRecordId}) {
    return request({
        url: 'mobile/bomDraftRecordDeleteEvent',
        method: 'POST',
        data: {pkBomDraftRecordId}
    })
}
export function bomDraftRecordMarkEvent({pkBomDraftRecordId,markStatus}) {
    return request({
        url: 'mobile/bomDraftRecordMarkEvent',
        method: 'POST',
        data: {pkBomDraftRecordId,markStatus}
    })
}
export function bomDraftDealSubmitEvent({pkBomDraftRecordId}) {
    return request({
        url: 'mobile/bomDraftDealSubmitEvent',
        method: 'POST',
        data: {pkBomDraftRecordId}
    })
}

export function clientPriceProtocolDeleteEvent({fkOrderGroupIndexId,clientId}) {
    return request({
        url: 'mobile/clientPriceProtocolDeleteEvent',
        method: 'POST',
        data: {fkOrderGroupIndexId,clientId}
    })
}

export function clientCollectionPackProtocolConfigEvent({list,productName,pkClientId,containTax}) {
    return request({
        url: 'mobile/clientCollectionPackProtocolConfigEvent',
        method: 'POST',
        data: {list,productName,pkClientId,containTax}
    })
}

export function bomDraftOfferBillBuildEvent({priceLayer}) {
    return request({
        url: 'mobile/bomDraftOfferBillBuildEvent',
        method: 'POST',
        data: {priceLayer}
    })
}
export function bomDraftOfferTemplateItemBuildEvent({bomDraftOfferItemDoList,clientId,fkOrderGroupIndexId}) {
    return request({
        url: 'mobile/bomDraftOfferTemplateItemBuildEvent',
        method: 'POST',
        data: {bomDraftOfferItemDoList,clientId,fkOrderGroupIndexId}
    })
}
export function attachBomDraftOfferBuildEvent({pkBomDraftOfferBillId,orderNumber,offerStrength}) {
    return request({
        url: 'mobile/attachBomDraftOfferBuildEvent',
        method: 'POST',
        data: {pkBomDraftOfferBillId,orderNumber,offerStrength}
    })
}

export function attachBomDraftOfferDeleteEvent({pkBomDraftOfferAttachId}) {
    return request({
        url: 'mobile/attachBomDraftOfferDeleteEvent',
        method: 'POST',
        data: {pkBomDraftOfferAttachId}
    })
}

export function bomDraftOfferBillResetEvent({priceLayer}) {
    return request({
        url: 'mobile/bomDraftOfferBillResetEvent',
        method: 'POST',
        data: {priceLayer}
    })
}

export function bomDraftOfferBillView({pkBomDraftRecordId}) {
    return request({
        url: 'mobile/bomDraftOfferBillView',
        method: 'POST',
        data: {pkBomDraftRecordId}
    })
}

export function attachBomDraftOfferBillView({pkBomDraftOfferAttachId}) {
    return request({
        url: 'mobile/attachBomDraftOfferBillView',
        method: 'POST',
        data: {pkBomDraftOfferAttachId}
    })
}

export function bomDraftOfferBillAdjustItemAmountEvent({bomDraftOfferItemDoList,pkBomDraftOfferBillId,newBomDraftOfferItemDo,delBomDraftOfferItemDo}) {
    return request({
        url: 'mobile/bomDraftOfferBillAdjustItemAmountEvent',
        method: 'POST',
        data: {bomDraftOfferItemDoList,pkBomDraftOfferBillId,newBomDraftOfferItemDo,delBomDraftOfferItemDo}
    })
}

export function bomDraftOfferBillAdjustItemAmountLockSumEvent({bomDraftOfferItemDoList,pkBomDraftOfferBillId,newBomDraftOfferItemDo,delBomDraftOfferItemDo}) {
    return request({
        url: 'mobile/bomDraftOfferBillAdjustItemAmountLockSumEvent',
        method: 'POST',
        data: {bomDraftOfferItemDoList,pkBomDraftOfferBillId,newBomDraftOfferItemDo,delBomDraftOfferItemDo}
    })
}

export function bomDraftOfferBillAdjustSumAmountEvent({bomDraftOfferItemDoList,pkBomDraftOfferBillId,sumAmount}) {
    return request({
        url: 'mobile/bomDraftOfferBillAdjustSumAmountEvent',
        method: 'POST',
        data: {bomDraftOfferItemDoList,pkBomDraftOfferBillId,sumAmount}
    })
}
export function bomDraftOfferItemEditNoteEvent({pkBomDraftOfferItemId, note}) {
    return request({
        url: 'mobile/bomDraftOfferItemEditNoteEvent',
        method: 'POST',
        data: {pkBomDraftOfferItemId, note}
    })
}
export function bomDraftOfferBillToPdfEvent({pkBomDraftRecordId,saveType}) {
    return request({
        url: 'mobile/bomDraftOfferBillToPdfEvent',
        method: 'POST',
        data: {pkBomDraftRecordId,saveType}
    })
}

export function bomDraftIncomingMaterialView({startTime,endTime,outXls}) {
    return request({
        url: 'mobile/bomDraftIncomingMaterialView',
        method: 'POST',
        data: {startTime,endTime,outXls}
    })
}

export function accountStatementMethodSettingEvent({pkClientId, accountStatementMethod}) {
    return request({
        url: 'mobile/accountStatementMethodSettingEvent',
        method: 'POST',
        data: {pkClientId, accountStatementMethod}
    })
}
// List<CustomizeItem> customizeItemList;
// private List<Long> pkBomDraftContractItemIds;
export function customizedAccountStatementBuildEventV2({customizeItemList, pkBomDraftContractItemIds, paymentDayNumber}) {
    return request({
        url: 'mobile/customizedAccountStatementBuildEventV2', method: 'POST',
        data: {customizeItemList, pkBomDraftContractItemIds, paymentDayNumber}
    })
}

export function accountStatementViewByClientV2({clientId, accountSettlementStatusList}) {
    return request({
        url: 'mobile/accountStatementViewByClientV2',
        method: 'post', data: {clientId, accountSettlementStatusList}
    })
}

export function invoiceBillByClientView({clientId, invoiceStatus}) {
    return request({
        url: 'mobile/invoiceBillByClientView',
        method: 'post', data: {clientId, invoiceStatus}
    })
}


export function sabkHumanListView(data) {
    return request({
        url: 'mobile/sabkHumanListView',
        method: 'post', data: data
    })
}

export function sabkHumanEditEvent({pkHumanId,humanName}) {
    return request({
        url: 'mobile/sabkHumanEditEvent',
        method: 'post', data: {pkHumanId,humanName}
    })
}
export function sabkHumanDisableEvent({pkHumanId}) {
    return request({
        url: 'mobile/sabkHumanDisableEvent',
        method: 'post', data: {pkHumanId}
    })
}

export function businessCollectStatisticsView(data) {
    return request({
        url: 'mobile/businessCollectStatisticsView',
        method: 'post', data: data
    })
}

export function businessCollectStatisticsDetailView(data) {
    return request({
        url: 'mobile/businessCollectStatisticsDetailView',
        method: 'post', data: data
    })
}


export function businessCollectStatisticsTimeView(data) {
    return request({
        url: 'mobile/businessCollectStatisticsTimeView',
        method: 'post', data: data
    })
}

export function bomDraftBuildEvent({ clientId, bomFilePath, note, coordinateFile, gerberFile, pakeFile, orderNumber, screenPrinting, screenPrintingB,orderBomFile }) {
    return requestTable({
        url: 'merchandiser/bomDraftBuildEvent',
        method: 'POST',
        data: { clientId, bomFilePath, note, coordinateFile, gerberFile, pakeFile, orderNumber, screenPrinting, screenPrintingB,orderBomFile }
    })
}
/**
 * 查看客户列表(跟单业务员）-戴素芳
 * @param pageindex 分页起点
 * @param pagesize 每一页多少
 * @param orderCounter 累计成交订单数量
 * @param sumOrder 累计下单总金额
 * @param sumOwe 累计欠款金额
 * @param sumPay 累计已付金额
 * @param max 最大值
 * @param min 最小值
 * @param clientName 客户姓名
 */
export function getClientInfoLists({ pageindex, pagesize, screen, orderCounter, sumOrder, sumOwe, sumPay, max, min,
                                       clientName, phone, afterHenchmanId, roleEnName,humanId }) {
    return request({
        url: 'clientsController/ClientInfoListByAfterHenchman',
        method: 'POST',
        data: {
            pageindex,
            pagesize,
            screen,
            orderCounter,
            sumOrder,
            sumOwe,
            sumPay,
            max,
            min,
            clientName, phone, afterHenchmanId, roleEnName,humanId
        }
    })
}


export function createClients({ telephone, password, clientName, afterHenchmanId, extBusinessId,linkman}) {
    return request({
        url: 'businessManager/createClients',
        method: 'POST',
        data: {
            telephone, password, clientName, afterHenchmanId, extBusinessId, linkman
        }
    })
}

export function xlsxSplitSheetToXlsxEvent({fileName, bFile}) {
    return request({
        url: 'mobile/xlsxSplitSheetToXlsxEvent',
        method: 'POST',
        data: {fileName, bFile}
    })
}



////////////// BOM识别 以下

export function bomParseAdjustXlsEvent({ fileName, sheetIndex, pkBomDraftId }) {
    return requestTable({
        url: 'quoter/ident/bomParseAdjustXlsEvent',
        method: 'POST',
        data: { fileName, sheetIndex, pkBomDraftId }
    })
}
/** BOM问题标记 视图*/
export function bomQuestionMarkView({ fkBomDraft, markType }) {
    return requestTable({
        url: 'quoter/ident/bomQuestionMarkView',
        method: 'POST',
        data: { fkBomDraft, markType }
    })
}

/** BOM问题标记设置事件*/
export function bomQuestionMarkSetEvent({ bomQuestionMarkList, isDel }) {
    return requestTable({
        url: 'quoter/ident/bomQuestionMarkSetEvent',
        method: 'POST',
        requestTable: { bomQuestionMarkList, isDel }
    })
}


/** BOM操作记录 视图*/
export function bomDraftOperationRecordView({ fkBomDraft, bomFile }) {
    return requestTable({
        url: 'quoter/ident/bomDraftOperationRecordView',
        method: 'POST',
        data: { fkBomDraft, bomFile }
    })
}

/** BOM操作记录事件*/
export function bomDraftOperationRecordEvent({ bomDraftOperationRecordList }) {
    return requestTable({
        url: 'quoter/ident/bomDraftOperationRecordEvent',
        method: 'POST',
        data: { bomDraftOperationRecordList }
    })
}

export function bomSubstituteArrangeEvent({positionNoCellIndex,useNumberCellIndex, substituteType, bomFile, xlsData, positionList, useNumberList, clientMaterialCodeList, rowNoList, pkBomDraftId, fkOrder, cellTypeList, ignoreDisable, process }) {
    return requestTable({
        url: 'quoter/ident/bomSubstituteArrangeEvent', method: 'POST',
        data: {positionNoCellIndex,useNumberCellIndex, substituteType, bomFile, xlsData, positionList, useNumberList, clientMaterialCodeList, rowNoList, pkBomDraftId, fkOrder, cellTypeList, ignoreDisable, process }
    })
}

/** BOM操作记录 清除事件*/
export function bomDraftOperationRecordCleanEvent({ fkBomDraft, bomFile }) {
    return requestTable({
        url: 'quoter/ident/bomDraftOperationRecordCleanEvent',
        method: 'POST',
        data: { fkBomDraft, bomFile }
    })
}


/** BOM操作记录 确认*/
export function bomDraftOperationRecordConfirmEvent({ pkBomDraftOperationRecordIdList}) {
    return requestTable({
        url: 'quoter/ident/bomDraftOperationRecordConfirmEvent',
        method: 'POST',
        data: { pkBomDraftOperationRecordIdList }
    })
}
export function bomDataCheckEvent({ xlsData, positionList, rowNoList, useNumberList, pkBomDraftId, coordinateTextDto }) {
    return requestTable({
        url: 'merchandiser/bomDataCheckEvent',
        method: 'POST',
        data: { xlsData, positionList, rowNoList, useNumberList, pkBomDraftId, coordinateTextDto }
    })
}

export function bomDraftView({ pageindex, pagesize, status, dealStatus, orderNo, clientName, fkOrder, roleEnName, pkBomDraftId, orderStatus, offerCode,createrId,contractStatus,companyName,bomTagName,clientId ,offerStatus,pkBomDraftIds}) {
    return request({
        url: 'merchandiser/bomDraftView',
        method: 'POST',
        data: { pageindex, pagesize, status, dealStatus, orderNo, clientName, fkOrder, roleEnName,
            pkBomDraftId, orderStatus, offerCode, createrId, contractStatus, companyName, bomTagName, clientId,offerStatus,pkBomDraftIds }
    })
}



/** 存储 【客户料号代码】 */
export function customMaterialCodeSaveEvent({ customMaterialCodeList, clientId }) {
    return requestTable({
        url: 'mobile/customMaterialCodeSaveEvent', method: 'POST',
        data: { customMaterialCodeList, clientId }
    })
}
export function bomIndexFindHistoryEvent({ pkBomDraftId, bomEffectIdentDtoList }) {
    return requestTable({
        url: 'quoter/ident/bomIndexFindHistoryEvent',
        method: 'POST',
        data: { pkBomDraftId, bomEffectIdentDtoList }
    })
}
export function bomOfferOrderDifferenceEvent2({ pkBomDraftId }) {
    return requestTable({
        url: 'quoter/ident/bomOfferOrderDifferenceEvent2', method: 'POST',
        data: { pkBomDraftId }
    })
}


export function bomOfferOrderDifferenceEvent({ pkBomDraftId }) {
    return requestTable({
        url: 'quoter/ident/bomOfferOrderDifferenceEvent', method: 'POST',
        data: { pkBomDraftId }
    })
}

export function bomParseIdentPropEvent({ xlsData, positionList, useNumberList, clientMaterialCodeList, rowNoList, pkBomDraftId, fkOrder, cellTypeList,ignoreDisable,process }) {
    return requestTable({
        url: 'quoter/ident/bomParseIdentPropEvent',
        method: 'POST',
        data: {
            xlsData, positionList, useNumberList, clientMaterialCodeList, rowNoList, pkBomDraftId, fkOrder, cellTypeList,ignoreDisable,process
        }
    })
}

export function bomEffectOperateRecordBuildEvent({ fkBomDraft, rowPositionNo, operateType, operateContent, workStage, rowNo, process, solderedDotNumber, list }) {
    return requestTable({
        url: 'merchandiser/bomEffectOperateRecordBuildEvent',
        method: 'POST',
        data: { fkBomDraft, rowPositionNo, operateType, operateContent, workStage, rowNo, process, solderedDotNumber, list }
    })
}

export function bomEffectOperateRecordCleanEvent({ pkBomDraftId, }) {
    return requestTable({
        url: 'merchandiser/bomEffectOperateRecordCleanEvent',
        method: 'POST',
        data: { pkBomDraftId, }
    })
}

export function bomEffectOperateRecordView({ pkBomDraftId, process, resultType }) {
    return requestTable({
        url: 'merchandiser/bomEffectOperateRecordView',
        method: 'POST',
        data: { pkBomDraftId, process, resultType }
    })
}

////////////// BOM识别 以上


export function getClientReceiverAddress({ clientId }) {
    return request({
        url: 'mobile/getClientReceiverAddress',
        method: 'POST',
        data: { clientId}

    })
}

export function bomDraftReceiverEditEvent({pkBomDraftId, address, receiverName, receiverPhone, sendPackLogisticsType}) {
    return request({
        url: 'mobile/bomDraftReceiverEditEvent',
        method: 'POST',
        data: {pkBomDraftId, address, receiverName, receiverPhone, sendPackLogisticsType}

    })
}

export function bomDraftRepeatOrderEvent(data) {
    return request({
        url: 'mobile/bomDraftRepeatOrderEvent',
        method: 'POST',
        data: data
    })
}

export function returnOrderView({pkBomDraftId}) {
    return request({
        url: 'mobile/returnOrderView',
        method: 'POST',
        data: {pkBomDraftId}
    })
}

export function bomDraftDealTimeEditEvent({pkBomDraftId, dealTime}) {
    return request({
        url: 'mobile/bomDraftDealTimeEditEvent',
        method: 'POST',
        data: {pkBomDraftId, dealTime}
    })
}

export function bomDraftFactoryMaterialRateEditEvent({pkBomDraftId, factoryMaterialRate}) {
    return request({
        url: 'mobile/bomDraftFactoryMaterialRateEditEvent',
        method: 'POST',
        data: {pkBomDraftId, factoryMaterialRate}
    })
}
export function bomDraftCustomizeDipNumberEditEvent({pkBomDraftId, customizeDipNumber}) {
    return request({
        url: 'mobile/bomDraftCustomizeDipNumberEditEvent',
        method: 'POST',
        data: {pkBomDraftId, customizeDipNumber}
    })
}

export function businessAccountsTaskView({humanId}) {
    return request({
        url: 'mobile/businessAccountsTaskView',
        method: 'POST',
        data: {humanId}
    })
}

export function cashierAccountsTaskView({}) {
    return request({
        url: 'mobile/cashierAccountsTaskView',
        method: 'POST',
        data: {}
    })
}

export function bomDraftOfferTextAddEvent(data) {
    return request({
        url: 'mobile/businessAccountsTaskView',
        method: 'POST',
        data: data
    })
}

export function businessTaskProcessRecordView({humanId, pkWorkerInfoIdList, pageindex, pagesize, taskBillType, clientName}) {
    return request({
        url: 'mobile/businessTaskProcessRecordView',
        method: 'POST',
        data: {humanId, pkWorkerInfoIdList, pageindex, pagesize, taskBillType, clientName}
    })
}

export function bomDraftShipmentLockSettingEvent({pkBomDraftId, shipmentLockFlag, shipmentLockCause}) {
    return request({
        url: 'mobile/bomDraftShipmentLockSettingEvent',
        method: 'POST',
        data: {pkBomDraftId, shipmentLockFlag, shipmentLockCause}
    })
}

export function messageReceiverView({}) {
    return request({
        url: 'mobile/messageReceiverView',
        method: 'POST',
        data: {}
    })
}

export function accountStatementExtensionTimeEvent({pkAccountStatementId, pkAccountStatementIdList, dueTime, extensionCause}) {
    return request({
        url: 'mobile/accountStatementExtensionTimeEvent',
        method: 'POST',
        data: {pkAccountStatementId, pkAccountStatementIdList, dueTime, extensionCause}
    })
}

export function clientInfoListView({humanId}) {
    return request({url: 'mobile/clientInfoListView', method: 'POST', data: {humanId}})
}

export function specialBomDraftBuildEvent({clientId, productName, offerPrice, orderNumber, taxRate}) {
    return request({
        url: 'mobile/specialBomDraftBuildEvent', method: 'POST',
        data: {clientId, productName, offerPrice, orderNumber, taxRate}
    })
}

export function specialBomDraftEditEvent({pkBomDraftId,offerPrice,orderNumber,taxRate}) {
    return request({
        url: 'mobile/specialBomDraftEditEvent', method: 'POST',
        data: {pkBomDraftId,offerPrice,orderNumber,taxRate}
    })
}

export function clientMaterialOutputEvent({fileName, bomEffectIdentDtoList, orderNumber, pkBomDraftId, factoryClientType, process}) {
    return request({
        url: 'mobile/clientMaterialOutputEvent',
        method: 'POST',
        data: {fileName, bomEffectIdentDtoList, orderNumber, pkBomDraftId, factoryClientType, process}
    })
}


export function accountStatementAddItemEventV2({pkAccountStatementId, pkBomDraftContractItemIds, pkSendPackItemIdList, pkBomDraftIdList, accountStatementAmountList, accountStatementNumberList}) {
    return request({
        url: 'mobile/accountStatementAddItemEventV2',
        method: 'POST',
        data: {pkAccountStatementId, pkBomDraftContractItemIds, pkSendPackItemIdList, pkBomDraftIdList, accountStatementAmountList, accountStatementNumberList}
    })
}
export function accountStatementItemDeleteEventV2({pkAccountStatementItemId }) {
    return request({
        url: 'mobile/accountStatementItemDeleteEventV2',
        method: 'POST',
        data: {pkAccountStatementItemId }
    })
}


// /** N 个月未下单 */
// /** monthNum： -2  是3个月前 未下单， -5是 半年前未下单*/Integer monthNum = null;
// /** 公海客户：N 非公海 Y公海 */String highSeasType;
// /** 客户名*/String clientName;
// /** 业务ID*/Long pkHumanId;
// /*** 每页大小*/
// protected int pagesize = 10;
// /*** 分页起始位置*/
// protected int pageindex;
export function highSeasView({existDeal, monthNum, highSeasType, clientName, pkHumanId, pageindex, pagesize, heatType, dealMonth, newAddMonth, pkBusinessGroupId, pkBusinessTeamId,ashType,denyType,uncreditType}) {
    return request({
        url: 'mobile/highSeasView',
        method: 'POST',
        data: {existDeal, monthNum, highSeasType, clientName, pkHumanId, pageindex, pagesize, heatType, dealMonth, newAddMonth, pkBusinessGroupId, pkBusinessTeamId,ashType,denyType,uncreditType}
    })
}

export function highSeasRefreshDateEvent() {
    return request({
        url: 'mobile/highSeasRefreshDateEvent',
        method: 'POST',
        data: {}
    })
}

export function clientDeleteEvent({pkClientId}) {
    return request({
        url: 'mobile/clientDeleteEvent',
        method: 'POST',
        data: {pkClientId}
    })
}

export function businessTargetManageBuildEvent({
                                                   fkHumanId,startTargetTime,teamId,groupId,sumTargetAmount,
                                                   steadyTargetAmount,steadyTaskNum,
                                                   potentialTargetAmount,potentialTaskNum,
                                                   scatteredTargetAmount,scatteredTaskNum,
                                                   newClientTargetAmount,newClientTaskNum,
                                                   extendWechatNum
                                               }) {
    return request({
        url: 'mobile/businessTargetManageBuildEvent',
        method: 'POST',
        data: {fkHumanId,startTargetTime,teamId,groupId,sumTargetAmount,
            steadyTargetAmount,steadyTaskNum,
            potentialTargetAmount,potentialTaskNum,
            scatteredTargetAmount,scatteredTaskNum,
            newClientTargetAmount,newClientTaskNum,
            extendWechatNum}
    })
}

export function businessTargetManageEditEvent({ pkBusinessTargetManageId,
                                                  fkHumanId,startTargetTime,teamId,groupId,sumTargetAmount,
                                                   steadyTargetAmount,steadyTaskNum,
                                                   potentialTargetAmount,potentialTaskNum,
                                                   scatteredTargetAmount,scatteredTaskNum,
                                                   newClientTargetAmount,newClientTaskNum,
                                                   extendWechatNum}) {
    return request({url: 'mobile/businessTargetManageEditEvent', method: 'POST',
        data: { pkBusinessTargetManageId,
            fkHumanId,startTargetTime,teamId,groupId,sumTargetAmount,
            steadyTargetAmount,steadyTaskNum,
            potentialTargetAmount,potentialTaskNum,
            scatteredTargetAmount,scatteredTaskNum,
            newClientTargetAmount,newClientTaskNum,
            extendWechatNum}
    })
}

export function businessTargetManageView({ fkHumanId,startTargetTime}) {
    return request({url: 'mobile/businessTargetManageView', method: 'POST',
        data: { fkHumanId,startTargetTime}
    })
}


export function parseMaterialBatch({ parseMaterialVoList }) {
    return request({url: 'OrderCr/parseMaterialBatch', method: 'POST',
        data: { parseMaterialVoList}
    })
}

export function businessCollectStatisticsSumView({fkHumanIds, timeRound,pkBusinessTeamId,pkBusinessGroupId,year,month}) {
    return request({
        url: 'mobile/businessCollectStatisticsSumView', method: 'POST',
        data: {fkHumanIds, timeRound,pkBusinessTeamId,pkBusinessGroupId,year,month}
    })
}

export function businessTeamGroupView() {
    return request({
        url: 'mobile/businessTeamGroupView', method: 'POST',
        data: {}
    })
}

export function businessPaymentPlanView({fkHumanId, planTime}) {
    return request({
        url: 'mobile/businessPaymentPlanView', method: 'POST',
        data: {fkHumanId, planTime}
    })
}

export function businessPaymentPlanBuildEvent({fkHumanId, planTime}) {
    return request({
        url: 'mobile/businessPaymentPlanBuildEvent', method: 'POST',
        data: {fkHumanId, planTime}
    })
}

export function businessPaymentJoinSendPackEvent({fkSendPackItemId,planTime}) {
    return request({
        url: 'mobile/businessPaymentJoinSendPackEvent', method: 'POST',
        data: {fkSendPackItemId,planTime}
    })
}

export function businessPaymentJoinMultiSendPackEvent({fkSendPackItemIdList, planTime}) {
    return request({
        url: 'mobile/businessPaymentJoinMultiSendPackEvent', method: 'POST',
        data: {fkSendPackItemIdList, planTime}
    })
}

export function businessPaymentJoinContractItemEvent({fkBomDraftContractItemId, planTime}) {
    return request({
        url: 'mobile/businessPaymentJoinContractItemEvent',
        method: 'POST',
        data: {fkBomDraftContractItemId, planTime}
    })
}

export function businessPaymentJoinBomDraftEvent({fkBomDraftId, planTime, paymentNumber}) {
    return request({
        url: 'mobile/businessPaymentJoinBomDraftEvent',
        method: 'POST',
        data: {fkBomDraftId, planTime, paymentNumber}
    })
}
export function businessPaymentJoinAccountStatementItemEvent({pkAccountStatementItemIdList, planTime}) {
    return request({
        url: 'mobile/businessPaymentJoinAccountStatementItemEvent',
        method: 'POST',
        data: {pkAccountStatementItemIdList, planTime}
    })
}

export function businessPaymentPlanRemoveEvent({pkBusinessPaymentDetailId}) {
    return request({
        url: 'mobile/businessPaymentPlanRemoveEvent',
        method: 'POST',
        data: {pkBusinessPaymentDetailId}
    })
}

export function cacheStoreView() {
    return request({
        url: 'warehouseKeeper/materManage/cacheStoreView',
        method: 'POST',
        data: {pageindex: 0, pagesize: 100000}
    })
}

export function cacheMaterialViewV2({pkCacheStoreId, layer, sabkCode}) {
    return request({
        url: 'warehouseKeeper/materManage/cacheMaterialViewV2',
        method: 'POST',
        data: {pkCacheStoreId, layer, sabkCode}
    })
}

export function bomDraftGiftOrderNumberEditEvent({pkBomDraftId, giftOrderNumber, showGfitToAccount}) {
    return request({
        url: 'mobile/bomDraftGiftOrderNumberEditEvent',
        method: 'POST',
        data: {pkBomDraftId, giftOrderNumber, showGfitToAccount}
    })
}

export function inventorySubmitEvent(data) {
    return request({
        url: 'warehouseKeeper/materManage/inventorySubmitEvent',
        method: 'POST',
        data: data
    })
}

export function clientLabelTypeSettingEvent({pkClientId, labelType,pass}) {
    return request({
        url: 'mobile/clientLabelTypeSettingEvent',
        method: 'POST',
        data: {pkClientId, labelType,pass}
    })
}

export function clientLabelTypeView({labelType, humanId}) {
    return request({
        url: 'mobile/clientLabelTypeView',
        method: 'POST',
        data: {labelType, humanId}
    })
}

export function highSeasPullEvent({clientId, humanId}) {
    return request({
        url: 'mobile/highSeasPullEvent',
        method: 'POST',
        data: {clientId, humanId}
    })
}

export function highSeasPushEvent({clientId, humanId}) {
    return request({
        url: 'mobile/highSeasPushEvent',
        method: 'POST',
        data: {clientId, humanId}
    })
}

export function highSeasRemindEvent({humanId}) {
    return request({
        url: 'mobile/highSeasRemindEvent',
        method: 'POST',
        data: {humanId}
    })
}
export function clientLabelSettingRemindEvent({humanId}) {
    return request({
        url: 'mobile/clientLabelSettingRemindEvent',
        method: 'POST',
        data: {humanId}
    })
}

export function highSeasRemindExecuteEvent({humanId, pkHighSeasRemindId, exList, wtList}) {
    return request({
        url: 'mobile/highSeasRemindExecuteEvent',
        method: 'POST',
        data: {humanId, pkHighSeasRemindId, exList, wtList}
    })
}
export function clientSpaceExpandEvent({pkHumanId,ht10Number,lt10Number,wtNumber}) {
    return request({
        url: 'mobile/clientSpaceExpandEvent',
        method: 'POST',
        data: {pkHumanId,ht10Number,lt10Number,wtNumber}
    })
}

export function ashSeasPushEvent({pkClientId ,ashCause}) {
    return request({
        url: 'mobile/ashSeasPushEvent',
        method: 'POST',
        data: {pkClientId ,ashCause}
    })
}
export function denySeasPushEvent({pkClientId ,denyCause}) {
    return request({
        url: 'mobile/denySeasPushEvent',
        method: 'POST',
        data:{pkClientId ,denyCause}
    })
}

export function clientSettingUncreditEvent({pkClientId, uncreditType}) {
    return request({
        url: 'mobile/clientSettingUncreditEvent',
        method: 'POST',
        data: {pkClientId, uncreditType}
    })
}

export function overdueReportFormsToPdfEvent({humanId}) {
    return request({
        url: 'mobile/overdueReportFormsToPdfEvent',
        method: 'POST',
        data: {humanId}
    })
}

export function workAttendanceView({humanId, month, unWorks}) {
    return request({
        url: 'mobile/workAttendanceView',
        method: 'POST',
        data: {humanId, month, unWorks}
    })
}
export function monthAttendanceView({humanId, month, unWorks,toPdf}) {
    return request({
        url: 'mobile/monthAttendanceView',
        method: 'POST',
        data: {humanId, month, unWorks,toPdf}
    })
}

export function orderUrgentTicketView({pkHumanId }) {
    return request({
        url: 'mobile/orderUrgentTicketView',
        method: 'POST',
        data: {pkHumanId }
    })
}

export function orderUrgentTicketUseEvent({pkOrderUrgentTicketId, bomDraftId}) {
    return request({
        url: 'mobile/orderUrgentTicketUseEvent',
        method: 'POST',
        data: {pkOrderUrgentTicketId, bomDraftId}
    })
}

export function orderUrgentTicketGrantEvent({pkHumanId }) {
    return request({
        url: 'mobile/orderUrgentTicketGrantEvent',
        method: 'POST',
        data: {pkHumanId}
    })
}

export function bomDraftContractView({fkClientId, humanId, initVerify, finalVerify, waitPostBack}) {
    return request({
        url: 'mobile/bomDraftContractView',
        method: 'POST',
        data: {fkClientId, humanId, initVerify, finalVerify, waitPostBack}
    })
}

export function contractPostBackVerifyEvent({pkContractPostBackId, initVerify,  finalVerify, rejectCause}) {
    return request({
        url: 'mobile/contractPostBackVerifyEvent',
        method: 'POST',
        data: {pkContractPostBackId, initVerify,  finalVerify, rejectCause}
    })
}

export function contractPostBackDelayTimeEvent({pkOrderContractId}) {
    return request({url: 'mobile/contractPostBackDelayTimeEvent', method: 'POST', data: {pkOrderContractId}})
}


export function recentUrgentOrderView({}) {
    return request({url: 'mobile/recentUrgentOrderView', method: 'POST', data: {}})
}


import axiosCreate from '@/utils/AxiosUtil'

const service = axiosCreate({
    baseURL: process.env.VUE_APP_COMMON,
})

export function humanLogin(data) {
    return service({
        url: 'human/login',
        method: 'post',
        data
    })
}

export function humanIsAdministrator(data) {
    return service({
        url: 'human/IsAdministrator',
        method: 'post',
        data
    })
}

export function humanIsHaveIdentity(data) {
    return service({
        url: 'human/IsHaveIdentity',
        method: 'post',
        data
    })
}

export function humanGetIsNeedPassword(data) {
    return service({
        url: 'human/GetIsNeedPassword',
        method: 'post',
        data
    })
}

export function humanIdentityWorkPathGet(data) {
    return service({
        url: 'human/IdentityWorkPathGet',
        method: 'post',
        data
    })
}

export function humanInfoGet(data) {
    return service({
        url: 'human/humanInfoGet',
        method: 'post',
        data: data
    })
}

export function humanGet(data) {
    return service({
        url: 'Human/Get',
        method: 'post',
        data
    })
}

export function humanSaleCreate(data) {
    return service({
        url: 'Human/SaleCreate',
        method: 'post',
        data
    })
}

export function humanGetByCode(data) {
    return service({
        url: 'Human/GetByCode',
        method: 'post',
        data
    })
}

export function orderGet(data) {
    return service({
        url: 'order/orderGet',
        method: 'POST',
        data
    })
}

export function businessAddressGet(data) {
    return service({
        url: 'businessAddress/businessAddressGet',
        method: 'POST',
        data
    })
}

export function businessAddressUpdate(data) {
    return service({
        url: 'businessAddress/businessAddressUpdate',
        method: 'POST',
        data
    })
}

export function businessAddressDelete(data) {
    return service({
        url: 'businessAddress/businessAddressDelete',
        method: 'POST',
        data
    })
}

export function ProductionSupplementLookup(data) {
    return service({
        url: 'ProductionSupplement/Lookup',
        method: 'POST',
        data
    })
}

export function ProductionSupplementDel(data) {
    return service({
        url: 'ProductionSupplement/del',
        method: 'POST',
        data
    })
}

export function ProductionSupplementGet(data) {
    return service({
        url: 'ProductionSupplement/get',
        method: 'POST',
        data
    })
}

export function ProductionSupplementGetUnPublishTotal(data) {
    return service({
        url: 'ProductionSupplement/getUnPublishTotal',
        method: 'POST',
        data
    })
}

export function ProductionSupplementEnter(data) {
    return service({
        url: 'ProductionSupplement/enter',
        method: 'POST',
        data
    })
}

export function ProductionSupplementLessMaterialEnter(data) {
    return service({
        url: 'ProductionSupplement/lessMaterialEnter',
        method: 'POST',
        data
    })
}

export function ProductionSupplementGetMaterial(data) {
    return service({
        url: 'ProductionSupplement/getMaterial',
        method: 'POST',
        data
    })
}

export function businessStatusBatchUpdateStatus(data) {
    return service({
        url: 'businessStatus/batchUpdateStatus',
        method: 'POST',
        data
    })
}

export function businessStatusUpdateStatus(data) {
    return service({
        url: 'businessStatus/updateStatus',
        method: 'POST',
        data
    })
}

export function orderReOrder(data) {
    return service({
        url: 'order/reOrder',
        method: 'POST',
        data
    })
}

export function orderCancel(data) {
    return service({
        url: 'order/cancel',
        method: 'POST',
        data
    })
}

export function orderRollback(data) {
    return service({
        url: 'order/rollback',
        method: 'POST',
        data
    })
}

export function orderCraftGet(data) {
    return service({
        url: 'order/orderCraftGet',
        method: 'POST',
        data
    })
}

export function exportUnOutStore(data) {
    return service({
        url: 'order/exportUnOutStore',
        method: 'POST',
        data
    })
}

export function orderDetailGet(data) {
    return service({
        url: 'order/orderDetailGet',
        method: 'POST',
        data
    })
}

export function orderUrgentAuthGet(data) {
    return service({
        url: 'order/UrgentAuthGet',
        method: 'POST',
        data
    })
}

export function orderLockUpdate(data) {
    return service({
        url: 'order/lockUpdate',
        method: 'POST',
        data
    })
}

export function InvoiceChangeAdd(data) {
    return service({
        url: 'InvoiceChange/add',
        method: 'POST',
        data
    })
}


export function MessageGet(data) {
    return service({
        url: 'Message/MessageGet',
        method: 'POST',
        data
    })
}

export function MessageSend(data) {
    return service({
        url: 'Message/MessageSend',
        method: 'POST',
        data
    })
}

export function MessageTemplateGet(data) {
    return service({
        url: 'Message/MessageTemplateGet',
        method: 'POST',
        data
    })
}

export function MessageTemplateValid(data) {
    return service({
        url: 'Message/Valid',
        method: 'POST',
        data
    })
}

export function orderPaymentRecordGet(data) {
    return service({
        url: 'orderPaymentRecord/get',
        method: 'POST',
        data
    })
}

export function orderPaymentRecordGetDetail(data) {
    return service({
        url: 'orderPaymentRecord/getDetail',
        method: 'POST',
        data
    })
}

export function orderPaymentRecordEnter(data) {
    return service({
        url: 'orderPaymentRecord/enter',
        method: 'POST',
        data
    })
}

export function bltComputerGetBusinessType(data) {
    return service({
        url: 'bltComputer/getBusinessType',
        method: 'POST',
        data
    })
}

export function bltComputerGetPrintFile(data) {
    return service({
        url: 'bltComputer/getAllPrintFile',
        method: 'POST',
        data
    })
}

export function bltComputerBindBusinessType(data) {
    return service({
        url: 'bltComputer/bindBusinessType',
        method: 'POST',
        data
    })
}

export function orderTraceGet(data) {
    return service({
        url: 'order/traceGet',
        method: 'POST',
        data
    })
}

export function orderMaterialCheckGet(data) {
    return service({
        url: 'order/materialCheckGet',
        method: 'POST',
        data
    })
}

export function orderStatusUpdate(data) {
    return service({
        url: 'order/statusUpdate',
        method: 'POST',
        data
    })
}

export function orderProcessTraceGet(data) {
    return service({
        url: 'order/processTraceGet',
        method: 'POST',
        data
    })
}

export function orderProcessGet(data) {
    return service({
        url: 'order/ProcessGet',
        method: 'POST',
        data
    })
}

export function orderProcessTraceAdd(data) {
    return service({
        url: 'order/processTraceAdd',
        method: 'POST',
        data
    })
}

export function orderProcessTraceDel(data) {
    return service({
        url: 'order/processTraceDel',
        method: 'POST',
        data
    })
}

export function orderFileLibraryGet(data) {
    return service({
        url: 'order/fileLibraryGet',
        method: 'POST',
        data
    })
}

export function bomDraftGet(data) {
    return service({
        url: 'order/bomDraftGet',
        method: 'POST',
        data
    })
}

export function orderBomDraftCommitOrderCount(data) {
    return service({
        url: 'order/BomDraftCommitOrderCount',
        method: 'POST',
        data
    })
}

export function orderBomDraftCommitOrderCancel(data) {
    return service({
        url: 'order/BomDraftCommitOrderCancel',
        method: 'POST',
        data
    })
}

export function bomDraftDetailUpdate(data) {
    return service({
        url: 'order/bomDraftDetailUpdate',
        method: 'POST',
        data
    })
}

export function BomDraftCount(data) {
    return service({
        url: 'order/BomDraftCount',
        method: 'POST',
        data
    })
}

export function BomDraftClientStatistics(data) {
    return service({
        url: 'order/BomDraftClientStatistics',
        method: 'POST',
        data
    })
}

export function BomDraftQuoteGet(data) {
    return service({
        url: 'order/BomDraftQuoteGet',
        method: 'POST',
        data
    })
}

export function BomDraftCountDetail(data) {
    return service({
        url: 'order/BomDraftCountDetail',
        method: 'POST',
        data
    })
}

export function SteelNetOrderGet(data) {
    return service({
        url: 'SteelNet/OrderGet',
        method: 'POST',
        data
    })
}

export function humanBindWorkerGet(data) {
    return service({
        url: 'human/BindWorkerGet',
        method: 'POST',
        data
    })
}

export function BomDraftImport(data) {
    return uploadFile({
        url: 'order/BomDraftImport',
        method: 'POST',
        data
    })
}

export function SteelNetRequiredEdit(data) {
    return service({
        url: 'SteelNet/RequiredEdit',
        method: 'POST',
        data
    })
}

export function SteelNetRequiredAdd(data) {
    return service({
        url: 'SteelNet/RequiredAdd',
        method: 'POST',
        data
    })
}

export function SteelNetRequiredGet(data) {
    return service({
        url: 'SteelNet/RequiredGet',
        method: 'POST',
        data
    })
}

export function SteelNetRequiredDel(data) {
    return service({
        url: 'SteelNet/RequiredDel',
        method: 'POST',
        data
    })
}

export function SteelNetAnalysis(data) {
    return service({
        url: 'SteelNet/SteelNetAnalysis',
        method: 'POST',
        data
    })
}

export function SteelNetSubmitOrder(data) {
    return service({
        url: 'SteelNet/SubmitOrder',
        method: 'POST',
        data
    })
}

export function SteelNetMakeRecordGet(data) {
    return service({
        url: 'SteelNet/MakeRecordGet',
        method: 'POST',
        data
    })
}

export function SteelNetMakeRecordDel(data) {
    return service({
        url: 'SteelNet/MakeRecordDel',
        method: 'POST',
        data
    })
}

export function SteelNetMakeRecordGetDetail(data) {
    return service({
        url: 'SteelNet/MakeRecordGetDetail',
        method: 'POST',
        data
    })
}

export function SteelNetMakeRecordUploadSpiFile(data) {
    return service({
        url: 'SteelNet/MakeRecordUploadSpiFile',
        method: 'POST',
        data
    })
}

export function SteelNetMakeRecordUpdate(data) {
    return service({
        url: 'SteelNet/MakeRecordUpdate',
        method: 'POST',
        data
    })
}

export function OrderSteelNetAndPcbCheck(data) {
    return service({
        url: 'order/SteelNetAndPcbCheck',
        method: 'POST',
        data
    })
}

export function OrderMaterialWorkStageChange(data) {
    return service({
        url: 'order/MaterialWorkStageChange',
        method: 'POST',
        data
    })
}

export function OrderMaterialGetByDesignator(data) {
    return service({
        url: 'order/MaterialGetByDesignator',
        method: 'POST',
        data
    })
}

export function OrderMaterialCheck(data) {
    return service({
        url: 'order/MaterialCheck',
        method: 'POST',
        data
    })
}

export function OrderDipProduceReview(data) {
    return service({
        url: 'order/DipProduceReview',
        method: 'POST',
        data
    })
}

export function OrderPluginCheckGet(data) {
    return service({
        url: 'order/PluginCheckGet',
        method: 'POST',
        data
    })
}

export function OrderPluginReviewCommit(data) {
    return service({
        url: 'order/PluginReviewCommit',
        method: 'POST',
        data
    })
}

export function OrderProcessExceptionAdd(data) {
    return service({
        url: 'order/ProcessExceptionAdd',
        method: 'POST',
        data
    })
}

export function OrderProcessExceptionGet(data) {
    return service({
        url: 'order/ProcessExceptionGet',
        method: 'POST',
        data
    })
}

export function OrderProductionCenterGet(data) {
    return service({
        url: 'order/ProductionCenterGet',
        method: 'POST',
        data
    })
}

export function OrderXRayDetailGet(data) {
    return service({
        url: 'order/XRayDetailGet',
        method: 'POST',
        data
    })
}

export function OrderWorkFlowGet(data) {
    return service({
        url: 'order/WorkFlowGet',
        method: 'POST',
        data
    })
}

export function OrderReflowGet(data) {
    return service({
        url: 'order/ReflowGet',
        method: 'POST',
        data
    })
}

export function OrderStatisticsGet(data) {
    return service({
        url: 'order/StatisticsGet',
        method: 'POST',
        data
    })
}

export function OrderProcessStatusUpdate(data) {
    return service({
        url: 'Order/ProcessStatusUpdate',
        method: 'POST',
        data
    })
}

export function factoryMaterialUseRecord(data) {
    return service({
        url: 'factory/MaterialUseRecord',
        method: 'POST',
        data
    })
}

export function orderProcessExceptionDetail(data) {
    return service({
        url: 'order/ProcessExceptionDetail',
        method: 'POST',
        data
    })
}

export function orderProcessExceptionProcess(data) {
    return service({
        url: 'order/ProcessExceptionProcess',
        method: 'POST',
        data
    })
}

export function orderProcessExceptionDel(data) {
    return service({
        url: 'order/ProcessExceptionDel',
        method: 'POST',
        data
    })
}

export function orderXRayReview(data) {
    return service({
        url: 'order/XRayReview',
        method: 'POST',
        data
    })
}

export function orderXRayRemoveImage(data) {
    return service({
        url: 'order/XRayRemoveImage',
        method: 'POST',
        data
    })
}

export function orderMaterialUpdateType(data) {
    return service({
        url: 'order/MaterialUpdateType',
        method: 'POST',
        data
    })
}

export function getFeederMaterial(data) {
    return service({
        url: 'order/getFeederMaterial',
        method: 'POST',
        data
    })
}

export function orderSmtPasteFeeder(data) {
    return service({
        url: 'order/SmtPasteFeeder',
        method: 'POST',
        data
    })
}

export function orderMachineProgramGet(data) {
    return service({
        url: 'order/MachineProgramGet',
        method: 'POST',
        data
    })
}

export function OrderSmtMaterialConvertArtifact(data) {
    return service({
        url: 'order/SmtMaterialConvertArtifact',
        method: 'POST',
        data
    })
}

export function OrderSmtArtifactEnterUploadMaterial(data) {
    return service({
        url: 'order/SmtArtifactEnterUploadMaterial',
        method: 'POST',
        data
    })
}

export function OrderUploadMachinePlaceNo(data) {
    return service({
        url: 'order/UploadMachinePlaceNo',
        method: 'POST',
        data
    })
}

export function OrderAoiQcDetailGet(data) {
    return service({
        url: 'order/AoiQcDetailGet',
        method: 'POST',
        data
    })
}

export function HumanPrivilegeGet(data) {
    return service({
        url: 'human/PrivilegeGet',
        method: 'POST',
        data
    })
}

export function humanSalesGet(data) {
    return service({
        url: 'human/SalesGet',
        method: 'POST',
        data
    })
}

export function orderDispatchCheckMaterialGet(data) {
    return service({
        url: 'order/DispatchCheckMaterialGet',
        method: 'POST',
        data
    })
}

export function orderTaskTimeCompute(data) {
    return service({
        url: 'order/TaskTimeCompute',
        method: 'POST',
        data
    })
}

export function orderCraftRequiredGet(data) {
    return service({
        url: 'order/CraftRequiredGet',
        method: 'POST',
        data
    })
}

export function orderCraftRequiredEdit(data) {
    return service({
        url: 'order/CraftRequiredEdit',
        method: 'POST',
        data
    })
}

export function orderCraftRequiredDel(data) {
    return service({
        url: 'order/CraftRequiredDel',
        method: 'POST',
        data
    })
}

export function orderCraftRequiredAdd(data) {
    return service({
        url: 'order/CraftRequiredAdd',
        method: 'POST',
        data
    })
}

export function orderCraftRequiredMaterialAdd(data) {
    return service({
        url: 'order/CraftRequiredMaterialAdd',
        method: 'POST',
        data
    })
}


export function orderCraftRequiredMaterialDel(data) {
    return service({
        url: 'order/CraftRequiredMaterialDel',
        method: 'POST',
        data
    })
}

export function orderCraftRequireCancel(data) {
    return service({
        url: 'order/CraftRequireCancel',
        method: 'POST',
        data
    })
}

export function orderCraftRequireEnter(data) {
    return service({
        url: 'order/CraftRequireEnter',
        method: 'POST',
        data
    })
}

export function FileUnRelation(data) {
    return service({
        url: 'File/UnRelation',
        method: 'POST',
        data
    })
}

export function orderGerberMergeCoordinateGet(data) {
    return service({
        url: 'order/GerberMergeCoordinateGet',
        method: 'POST',
        data
    })
}

export function orderCoordinateGet(data) {
    return service({
        url: 'order/CoordinateGet',
        method: 'POST',
        data
    })
}

export function orderGerberAndCoordinateMerge(data) {
    return service({
        url: 'order/GerberAndCoordinateMerge',
        method: 'POST',
        data
    })
}

export function orderMaterialGet(data) {
    return service({
        url: 'order/MaterialGet',
        method: 'POST',
        data
    })
}

export function orderBomDraftGetDetail(data) {
    return service({
        url: 'order/bomDraftGetDetail',
        method: 'POST',
        data
    })
}

export function orderGetByClient(data) {
    return service({
        url: 'order/getByClient',
        method: 'POST',
        data
    })
}

export function orderBomDraftCommitOrder(data) {
    return service({
        url: 'order/BomDraftCommitOrder',
        method: 'POST',
        data
    })
}

export function orderCraftRequiredPublish(data) {
    return service({
        url: 'order/CraftRequiredPublish',
        method: 'POST',
        data
    })
}

export function SteelNetPreviewGet(data) {
    return service({
        url: 'SteelNet/PreviewGet',
        method: 'POST',
        data
    })
}

export function SteelNetPreviewEnter(data) {
    return service({
        url: 'SteelNet/PreviewEnter',
        method: 'POST',
        data
    })
}

export function SteelNetAddInfoGet(data) {
    return service({
        url: 'SteelNet/AddInfoGet',
        method: 'POST',
        data
    })
}

export function SteelNetGet(data) {
    return service({
        url: 'SteelNet/Get',
        method: 'POST',
        data
    })
}

export function SteelNetAdd(data) {
    return service({
        url: 'SteelNet/Add',
        method: 'POST',
        data
    })
}

export function SteelNetEdit(data) {
    return service({
        url: 'SteelNet/Edit',
        method: 'POST',
        data
    })
}

export function SteelNetSizeGet(data) {
    return service({
        url: 'SteelNet/SizeGet',
        method: 'POST',
        data
    })
}

export function orderPluginSubcontractingOrderGet(data) {
    return service({
        url: 'order/PluginSubcontractingOrderGet',
        method: 'POST',
        data
    })
}

export function orderPluginSubcontractingOrderDownload(data) {
    return service({
        url: 'order/PluginSubcontractingOrderDownload',
        method: 'POST',
        data
    })
}

export function SteelNetPrint(data) {
    return service({
        url: 'SteelNet/Print',
        method: 'POST',
        data
    })
}

export function SteelNetDel(data) {
    return service({
        url: 'SteelNet/Del',
        method: 'POST',
        data
    })
}

export function orderPreProduceCheckGet(data) {
    return service({
        url: 'order/PreProduceCheckGet',
        method: 'POST',
        data
    })
}

export function orderSolderPrintGet(data) {
    return service({
        url: 'order/SolderPrintGet',
        method: 'POST',
        data
    })
}

export function orderPcbaCleanGet(data) {
    return service({
        url: 'order/PcbaCleanGet',
        method: 'POST',
        data
    })
}

export function orderPluginFirstLabelPrint(data) {
    return service({
        url: 'order/PluginFirstLabelPrint',
        method: 'POST',
        data
    })
}

export function orderCheckMaterialGet(data) {
    return service({
        url: 'order/CheckMaterialGet',
        method: 'POST',
        data
    })
}

export function orderCheckMaterialCount(data) {
    return service({
        url: 'order/CheckMaterialCount',
        method: 'POST',
        data
    })
}

export function orderCheckMaterialEnter(data) {
    return service({
        url: 'order/CheckMaterialEnter',
        method: 'POST',
        data
    })
}

export function orderGerberCoordinateRotationAdjustGet(data) {
    return service({
        url: 'order/GerberCoordinateRotationAdjustGet',
        method: 'POST',
        data
    })
}

export function orderGerberParsePad(data) {
    return service({
        url: 'order/GerberParsePad',
        method: 'POST',
        data
    })
}

export function orderGerberRotatePad(data) {
    return service({
        url: 'order/GerberRotatePad',
        method: 'POST',
        data
    })
}

export function orderGerberSamePadMatch(data) {
    return service({
        url: 'order/GerberSamePadMatch',
        method: 'POST',
        data
    })
}

export function orderGerberCoordinateUpdate(data) {
    return service({
        url: 'order/GerberCoordinateUpdate',
        method: 'POST',
        data
    })
}

export function BomDraftAutoCommitOrder(data) {
    return service({
        url: 'order/BomDraftAutoCommitOrder',
        method: 'POST',
        data
    })
}

export function orderMachineProgramUpload(data) {
    return service({
        url: 'order/MachineProgramUpload',
        method: 'POST',
        data
    })
}

export function orderMachineProgramDel(data) {
    return service({
        url: 'order/MachineProgramDel',
        method: 'POST',
        data
    })
}

export function HumanInfoUpdate(data) {
    return service({
        url: 'Human/InfoUpdate',
        method: 'POST',
        data
    })
}

export function orderExceptionListPrint(data) {
    return service({
        url: 'order/ExceptionListPrint',
        method: 'POST',
        data
    })
}

export function TypeCodeGet(data) {
    return service({
        url: 'TypeCode/Get',
        method: 'POST',
        data
    })
}

export function orderDashboardStatisticsGet(data) {
    return service({
        url: 'order/DashboardStatisticsGet',
        method: 'POST',
        data
    })
}

export function orderDashboardGet(data) {
    return service({
        url: 'order/DashboardGet',
        method: 'POST',
        data
    })
}

export function orderSharePageCheck(data) {
    return service({
        url: 'Order/SharePageCheck',
        method: 'POST',
        data
    })
}

export function search(data) {
    return service({
        url: 'common/search',
        method: 'POST',
        data
    })
}

export function orderFactoryMaterialCodeParser(data) {
    return service({
        url: 'order/FactoryMaterialCodeParser',
        method: 'POST',
        data
    })
}

export function ClientLabelGet(data) {
    return service({
        url: 'Client/LabelGet',
        method: 'POST',
        data
    })
}

export function ClientLabelAdd(data) {
    return service({
        url: 'Client/LabelAdd',
        method: 'POST',
        data
    })
}

export function ClientLabelDel(data) {
    return service({
        url: 'Client/LabelDel',
        method: 'POST',
        data
    })
}

export function orderCraftCheckGet(data) {
    return service({
        url: 'order/CraftCheckGet',
        method: 'POST',
        data
    })
}

export function orderCraftPcbSave(data) {
    return service({
        url: 'order/CraftPcbSave',
        method: 'POST',
        data
    })
}

export function orderCraftPcbRollback(data) {
    return service({
        url: 'order/CraftPcbRollback',
        method: 'POST',
        data
    })
}

export function orderCraftSteelNetSave(data) {
    return service({
        url: 'order/CraftSteelNetSave',
        method: 'POST',
        data
    })
}

export function orderCraftDipFixtureSave(data) {
    return service({
        url: 'Order/CraftDipFixtureSave',
        method: 'POST',
        data
    })
}

export function orderCraftDipRollback(data) {
    return service({
        url: 'Order/CraftDipRollback',
        method: 'POST',
        data
    })
}

export function orderCraftDipFixtureGet(data) {
    return service({
        url: 'order/CraftDipFixtureGet',
        method: 'POST',
        data
    })
}

export function orderCraftSmtGet(data) {
    return service({
        url: 'order/CraftSmtGet',
        method: 'POST',
        data
    })
}

export function orderCraftSteelNetGet(data) {
    return service({
        url: 'order/CraftSteelNetGet',
        method: 'POST',
        data
    })
}

export function orderCraftSmtSave(data) {
    return service({
        url: 'Order/CraftSmtSave',
        method: 'POST',
        data
    })
}

export function orderCraftSmtRollback(data) {
    return service({
        url: 'Order/CraftSmtRollback',
        method: 'POST',
        data
    })
}

export function orderCraftAoiFixtureSave(data) {
    return service({
        url: 'Order/CraftAoiFixtureSave',
        method: 'POST',
        data
    })
}

export function SupplierSteelNetEnter(data) {
    return service({
        url: 'Supplier/SteelNetEnter',
        method: 'POST',
        data
    })
}

export function SupplierSteelNetDownloadGerber(data) {
    return service({
        url: 'Supplier/SteelNetDownloadGerber',
        method: 'POST',
        data
    })
}

export function SupplierSteelNetUploadSpi(data) {
    return service({
        url: 'Supplier/SteelNetUploadSpi',
        method: 'POST',
        data
    })
}

export function SupplierOrderViewGet(data) {
    return service({
        url: 'Supplier/OrderViewGet',
        method: 'POST',
        data
    })
}

export function SupplierOrderEnter(data) {
    return service({
        url: 'Supplier/OrderEnter',
        method: 'POST',
        data
    })
}

export function SupplierOrderReject(data) {
    return service({
        url: 'SupplierOrder/reject',
        method: 'POST',
        data
    })
}

export function SupplierOrderDownloadRequirePdf(data) {
    return service({
        url: 'SupplierOrder/DownloadRequirePdf',
        method: 'POST',
        data
    })
}

export function SupplierOrderGet(data) {
    return service({
        url: 'Supplier/OrderGet',
        method: 'POST',
        data
    })
}

export function SupplierOrderGetV2(data) {
    return service({
        url: 'Supplier/OrderGetV2',
        method: 'POST',
        data
    })
}

export function SupplierOrderSendTableGet(data) {
    return service({
        url: 'SupplierOrder/SendTableGet',
        method: 'POST',
        data
    })
}

export function SupplierOrderEngineerGet(data) {
    return service({
        url: 'Supplier/OrderEngineerGet',
        method: 'POST',
        data
    })
}

export function SupplierOrderSendItemAdd(data) {
    return service({
        url: 'SupplierOrder/SendItemAdd',
        method: 'POST',
        data
    })
}

export function SupplierOrderSendItemRemove(data) {
    return service({
        url: 'SupplierOrder/SendItemRemove',
        method: 'POST',
        data
    })
}

export function SupplierOrderEngineerSendBillGet(data) {
    return service({
        url: 'SupplierOrder/EngineerSendBillGet',
        method: 'POST',
        data
    })
}

export function SupplierOrderSendAddV2(data) {
    return service({
        url: 'SupplierOrder/SendAddV2',
        method: 'POST',
        data
    })
}

export function SupplierOrderSendBillCancel(data) {
    return service({
        url: 'SupplierOrder/SendBillCancel',
        method: 'POST',
        data
    })
}

export function SupplierOrderAccountBillAdd(data) {
    return service({
        url: 'SupplierOrder/AccountBillAdd',
        method: 'POST',
        data
    })
}

export function SupplierOrderAccountBillGet(data) {
    return service({
        url: 'SupplierOrder/AccountBillGet',
        method: 'POST',
        data
    })
}

export function SupplierOrderAccountBillGetDetail(data) {
    return service({
        url: 'SupplierOrder/AccountBillGetDetail',
        method: 'POST',
        data
    })
}

export function SupplierOrderAccountBillUploadPayment(data) {
    return service({
        url: 'SupplierOrder/AccountBillUploadPayment',
        method: 'POST',
        data
    })
}

export function SupplierOrderAccountBillCancel(data) {
    return service({
        url: 'SupplierOrder/AccountBillCancel',
        method: 'POST',
        data
    })
}

export function SupplierOrderAccountBillPreview(data) {
    return service({
        url: 'SupplierOrder/AccountBillPreview',
        method: 'POST',
        data
    })
}

export function SupplierOrderEngineerAccountBillGet(data) {
    return service({
        url: 'SupplierOrder/EngineerAccountBillGet',
        method: 'POST',
        data
    })
}

export function SupplierOrderSendBillDetailGet(data) {
    return service({
        url: 'SupplierOrder/SendBillDetailGet',
        method: 'POST',
        data
    })
}

export function SupplierOrderSendBillReceive(data) {
    return service({
        url: 'SupplierOrder/SendBillReceive',
        method: 'POST',
        data
    })
}

export function SupplierOrderSendBillPreview(data) {
    return service({
        url: 'SupplierOrder/SendBillPreview',
        method: 'POST',
        data
    })
}

export function SupplierOrderCount(data) {
    return service({
        url: 'Supplier/OrderCount',
        method: 'POST',
        data
    })
}

export function OrderProcessStatusGet(data) {
    return service({
        url: 'order/ProcessStatusGet',
        method: 'POST',
        data
    })
}

export function orderProcessStatusStatistics(data) {
    return service({
        url: 'order/ProcessStatusStatistics',
        method: 'POST',
        data
    })
}

export function orderCraftSteelNetFixtureRequiredAdd(data) {
    return service({
        url: 'order/CraftSteelNetFixtureRequiredAdd',
        method: 'POST',
        data
    })
}

export function ShelfGet(data) {
    return service({
        url: 'Shelf/Get',
        method: 'POST',
        data
    })
}

export function ShelfAdd(data) {
    return service({
        url: 'Shelf/Add',
        method: 'POST',
        data
    })
}

export function ShelfDel(data) {
    return service({
        url: 'Shelf/Del',
        method: 'POST',
        data
    })
}

export function ShelfLayerAreaAdd(data) {
    return service({
        url: 'Shelf/LayerAreaAdd',
        method: 'POST',
        data
    })
}

export function orderCraftSteelNetRollback(data) {
    return service({
        url: 'order/CraftSteelNetRollback',
        method: 'POST',
        data
    })
}

export function orderCraftSmtFixtureGet(data) {
    return service({
        url: 'order/CraftSmtFixtureGet',
        method: 'POST',
        data
    })
}

export function orderCraftSmtFixtureSave(data) {
    return service({
        url: 'order/CraftSmtFixtureSave',
        method: 'POST',
        data
    })
}

export function orderCraftSmtFixtureRollback(data) {
    return service({
        url: 'order/CraftSmtFixtureRollback',
        method: 'POST',
        data
    })
}

export function orderCraftCraftSmtSupplierGet(data) {
    return service({
        url: 'order/CraftSmtSupplierGet',
        method: 'POST',
        data
    })
}

export function orderCraftSmtSupplierSave(data) {
    return service({
        url: 'order/CraftSmtSupplierSave',
        method: 'POST',
        data
    })
}

export function orderCraftSmtSupplierRollback(data) {
    return service({
        url: 'order/CraftSmtSupplierRollback',
        method: 'POST',
        data
    })
}

export function orderPrepareMaterialGet(data) {
    return service({
        url: 'Order/PrepareMaterialGet',
        method: 'POST',
        data
    })
}

export function orderPrepareMaterialFactoryLabelPrint(data) {
    return service({
        url: 'order/PrepareMaterialFactoryLabelPrint',
        method: 'POST',
        data
    })
}

export function orderPrepareInstanceOperator(data) {
    return service({
        url: 'Order/PrepareInstanceOperator',
        method: 'POST',
        data
    })
}

export function orderPrepareMaterialSplitLabelPrint(data) {
    return service({
        url: 'Order/PrepareMaterialSplitLabelPrint',
        method: 'POST',
        data
    })
}
export function ShelfGetDetail(data) {
    return service({
        url: 'Shelf/getDetail',
        method: 'POST',
        data
    })
}

export function ShelfPrint(data) {
    return service({
        url: 'Shelf/Print',
        method: 'POST',
        data
    })
}

export function orderCheckMaterialSteelNetEnter(data) {
    return service({
        url: 'order/CheckMaterialSteelNetEnter',
        method: 'POST',
        data
    })
}

export function SupplierOrderQuestionAdd(data) {
    return service({
        url: 'SupplierOrder/QuestionAdd',
        method: 'POST',
        data
    })
}

export function SupplierOrderQuestionDel(data) {
    return service({
        url: 'SupplierOrder/QuestionDel',
        method: 'POST',
        data
    })
}

export function SupplierOrderQuestionReply(data) {
    return service({
        url: 'SupplierOrder/QuestionReply',
        method: 'POST',
        data
    })
}

export function SupplierOrderQuestionUpdate(data) {
    return service({
        url: 'SupplierOrder/QuestionUpdate',
        method: 'POST',
        data
    })
}

export function SupplierOrderSendGet(data) {
    return service({
        url: 'SupplierOrder/SendGet',
        method: 'POST',
        data
    })
}

export function SupplierOrderSendAdd(data) {
    return service({
        url: 'SupplierOrder/SendAdd',
        method: 'POST',
        data
    })
}

export function SupplierSteelNetUpdate(data) {
    return service({
        url: 'Supplier/SteelNetUpdate',
        method: 'POST',
        data
    })
}

export function SupplierOrderChangeSupplier(data) {
    return service({
        url: 'SupplierOrder/ChangeSupplier',
        method: 'POST',
        data
    })
}

export function MessageNoticeCount(data) {
    return service({
        url: 'MessageNotice/Count',
        method: 'POST',
        data
    })
}

export function PhysicalOperatorKeepAlive(data) {
    return service({
        url: 'PhysicalOperator/KeepAlive',
        method: 'POST',
        data
    })
}

export function MessageNoticeGet(data) {
    return service({
        url: 'MessageNotice/Get',
        method: 'POST',
        data
    })
}

export function MessageNoticeRead(data) {
    return service({
        url: 'MessageNotice/Read',
        method: 'POST',
        data
    })
}

export function orderDispatchPrepareMaterialGet(data) {
    return service({
        url: 'order/DispatchPrepareMaterialGet',
        method: 'POST',
        data
    })
}

export function orderPrepareMaterialCheck(data) {
    return service({
        url: 'Order/PrepareMaterialCheck',
        method: 'POST',
        data
    })
}

export function orderDispatchGet(data) {
    return service({
        url: 'order/DispatchGet',
        method: 'POST',
        data
    })
}

export function orderUrgent(data) {
    return service({
        url: 'order/Urgent',
        method: 'POST',
        data
    })
}

export function orderUrgentCancel(data) {
    return service({
        url: 'order/UrgentCancel',
        method: 'POST',
        data
    })
}

export function orderSmtFirstCheckGet(data) {
    return service({
        url: 'order/SmtFirstCheckGet',
        method: 'POST',
        data
    })
}

export function orderSmtAoiCheckGet(data) {
    return service({
        url: 'order/SmtAoiCheckGet',
        method: 'POST',
        data
    })
}

export function AbsMachineGet(data) {
    return service({
        url: 'AbsMachine/Get',
        method: 'POST',
        data
    })
}

export function AbsMachineDetailGet(data) {
    return service({
        url: 'AbsMachine/DetailGet',
        method: 'POST',
        data
    })
}

export function AbsMachineDel(data) {
    return service({
        url: 'AbsMachine/Del',
        method: 'POST',
        data
    })
}

export function AbsMachineAdd(data) {
    return service({
        url: 'AbsMachine/Add',
        method: 'POST',
        data
    })
}

export function AbsMachineSaveConfig(data) {
    return service({
        url: 'AbsMachine/SaveConfig',
        method: 'POST',
        data
    })
}


export function AbsMachineFixedExampleGet(data) {
    return service({
        url: 'AbsMachine/FixedExampleGet',
        method: 'POST',
        data
    })
}

export function OperatorGet(data) {
    return service({
        url: 'Operator/Get',
        method: 'POST',
        data
    })
}

export function OperatorDel(data) {
    return service({
        url: 'Operator/Del',
        method: 'POST',
        data
    })
}

export function OperatorAdd(data) {
    return service({
        url: 'Operator/Add',
        method: 'POST',
        data
    })
}

export function OperatorUpdate(data) {
    return service({
        url: 'Operator/Update',
        method: 'POST',
        data
    })
}

export function WorkShopGet(data) {
    return service({
        url: 'WorkShop/Get',
        method: 'POST',
        data
    })
}

export function WorkShopDel(data) {
    return service({
        url: 'WorkShop/Del',
        method: 'POST',
        data
    })
}

export function WorkShopAdd(data) {
    return service({
        url: 'WorkShop/Add',
        method: 'POST',
        data
    })
}

export function WorkShopAddConfig(data) {
    return service({
        url: 'WorkShop/AddConfig',
        method: 'POST',
        data
    })
}

export function WorkShopGetConfig(data) {
    return service({
        url: 'WorkShop/GetConfig',
        method: 'POST',
        data
    })
}

export function WorkShopDelConfig(data) {
    return service({
        url: 'WorkShop/DelConfig',
        method: 'POST',
        data
    })
}

export function WorkShopUpdate(data) {
    return service({
        url: 'WorkShop/Update',
        method: 'POST',
        data
    })
}

export function PhysicalOperatorGet(data) {
    return service({
        url: 'PhysicalOperator/Get',
        method: 'POST',
        data
    })
}

export function PhysicalOperatorAdd(data) {
    return service({
        url: 'PhysicalOperator/Add',
        method: 'POST',
        data
    })
}

export function PhysicalOperatorDel(data) {
    return service({
        url: 'PhysicalOperator/Del',
        method: 'POST',
        data
    })
}

export function orderPrepareMaterialCenterGet(data) {
    return service({
        url: 'order/PrepareMaterialCenterGet',
        method: 'POST',
        data
    })
}

export function orderPrepareMaterialPickUpOrder(data) {
    return service({
        url: 'order/PrepareMaterialPickUpOrder',
        method: 'POST',
        data
    })
}

export function orderPrepareMaterialRevertOrder(data) {
    return service({
        url: 'order/PrepareMaterialRevertOrder',
        method: 'POST',
        data
    })
}

export function orderPrepareMaterialInsertOrder(data) {
    return service({
        url: 'order/PrepareMaterialInsertOrder',
        method: 'POST',
        data
    })
}

export function orderPrepareMaterialInsertOrderV2(data) {
    return service({
        url: 'order/PrepareMaterialInsertOrderV2',
        method: 'POST',
        data
    })
}

export function orderUrgentSendMessage(data) {
    return service({
        url: 'order/UrgentSendMessage',
        method: 'POST',
        data
    })
}

export function orderDispatchGetV2(data) {
    return service({
        url: 'order/DispatchGetV2',
        method: 'POST',
        data
    })
}

export function orderListOrderGet(data) {
    return service({
        url: 'order/ListOrderGet',
        method: 'POST',
        data
    })
}

export function orderListExpressGet(data) {
    return service({
        url: 'order/ListExpressGet',
        method: 'POST',
        data
    })
}

export function orderListExpressInsert(data) {
    return service({
        url: 'order/ListExpressInsert',
        method: 'POST',
        data
    })
}

export function orderListExpressPickup(data) {
    return service({
        url: 'order/ListExpressPickup',
        method: 'POST',
        data
    })
}

export function orderListExpressRevert(data) {
    return service({
        url: 'order/ListExpressRevert',
        method: 'POST',
        data
    })
}

export function orderListExpressChangeClient(data) {
    return service({
        url: 'order/ListExpressChangeClient',
        method: 'POST',
        data
    })
}

export function orderListStatistics(data) {
    return service({
        url: 'order/ListStatistics',
        method: 'POST',
        data
    })
}

export function orderListMaterialSend(data) {
    return service({
        url: 'order/ListMaterialSend',
        method: 'POST',
        data
    })
}

export function orderListMaterialDone(data) {
    return service({
        url: 'order/ListMaterialDone',
        method: 'POST',
        data
    })
}

export function orderCheckMaterialDone(data) {
    return service({
        url: 'order/CheckMaterialDone',
        method: 'POST',
        data
    })
}

export function orderListCheckMaterialGet(data) {
    return service({
        url: 'order/ListCheckMaterialGet',
        method: 'POST',
        data
    })
}

export function orderSearch(data) {
    return service({
        url: 'order/Search',
        method: 'POST',
        data
    })
}

export function orderListPcbSend(data) {
    return service({
        url: 'order/ListPcbSend',
        method: 'POST',
        data
    })
}

export function orderCheckPlanGet(data) {
    return service({
        url: 'order/CheckPlanGet',
        method: 'POST',
        data
    })
}

export function orderCheckPlanPickUpOrder(data) {
    return service({
        url: 'order/CheckPlanPickUpOrder',
        method: 'POST',
        data
    })
}

export function orderCheckPlanRevertOrder(data) {
    return service({
        url: 'order/CheckPlanRevertOrder',
        method: 'POST',
        data
    })
}

export function orderCheckPlanInsertOrder(data) {
    return service({
        url: 'order/CheckPlanInsertOrder',
        method: 'POST',
        data
    })
}

export function orderCheckPlanOverdueSendMessage(data) {
    return service({
        url: 'order/CheckPlanOverdueSendMessage',
        method: 'POST',
        data
    })
}

export function orderCheckPlanOverdueEnter(data) {
    return service({
        url: 'order/CheckPlanOverdueEnter',
        method: 'POST',
        data
    })
}

export function SystemCpuMemoryGet(data) {
    return service({
        url: 'System/CpuMemoryGet',
        method: 'POST',
        data
    })
}

export function SystemInfoGet(data) {
    return service({
        url: 'System/InfoGet',
        method: 'POST',
        data
    })
}

export function orderListComeMaterial(data) {
    return service({
        url: 'order/ListComeMaterial',
        method: 'POST',
        data
    })
}

export function orderListComeMaterialListStart(data) {
    return service({
        url: 'order/ListComeMaterialListStart',
        method: 'POST',
        data
    })
}

export function orderListComeMaterialListCancel(data) {
    return service({
        url: 'order/ListComeMaterialListCancel',
        method: 'POST',
        data
    })
}

export function orderListComeMaterialListDone(data) {
    return service({
        url: 'order/ListComeMaterialListDone',
        method: 'POST',
        data
    })
}

export function orderExpressExceptionGet(data) {
    return service({
        url: 'order/ExpressExceptionGet',
        method: 'POST',
        data
    })
}

export function orderExpressExceptionIdentify(data) {
    return service({
        url: 'order/ExpressExceptionIdentify',
        method: 'POST',
        data
    })
}

export function orderListMaterialStart(data) {
    return service({
        url: 'order/ListMaterialStart',
        method: 'POST',
        data
    })
}

export function orderListMaterialCancel(data) {
    return service({
        url: 'order/ListMaterialCancel',
        method: 'POST',
        data
    })
}

export function orderInsertVerify(data) {
    return service({
        url: 'order/InsertVerify',
        method: 'POST',
        data
    })
}

export function orderInsertSendMessage(data) {
    return service({
        url: 'order/InsertSendMessage',
        method: 'POST',
        data
    })
}

export function SystemAuthorizedCodeGet(data) {
    return service({
        url: 'System/AuthorizedCodeGet',
        method: 'POST',
        data
    })
}

export function orderListExpressWriteOff(data) {
    return service({
        url: 'order/ListExpressWriteOff',
        method: 'POST',
        data
    })
}

export function orderListExpressSignInGet(data) {
    return service({
        url: 'order/ListExpressSignInGet',
        method: 'POST',
        data
    })
}

export function orderListOverdueSendMessage(data) {
    return service({
        url: 'order/ListOverdueSendMessage',
        method: 'POST',
        data
    })
}

export function orderListOverdueEnter(data) {
    return service({
        url: 'order/ListOverdueEnter',
        method: 'POST',
        data
    })
}

export function orderListExpressOtherEnter(data) {
    return service({
        url: 'order/ListExpressOtherEnter',
        method: 'POST',
        data
    })
}

export function orderSmtPastePresentQc(data) {
    return service({
        url: 'order/SmtPastePresentQc',
        method: 'POST',
        data
    })
}

export function orderSmtPastePresentClean(data) {
    return service({
        url: 'order/SmtPastePresentClean',
        method: 'POST',
        data
    })
}

export function orderSmtProgramBackFeeder(data) {
    return service({
        url: 'order/SmtProgramBackFeeder',
        method: 'POST',
        data
    })
}

export function orderSmtProgramDone(data) {
    return service({
        url: 'order/SmtProgramDone',
        method: 'POST',
        data
    })
}

export function orderStatisticsReport(data) {
    return service({
        url: 'order/StatisticsReportV2',
        method: 'POST',
        data
    })
}

export function orderStatisticsReportSync(data) {
    return service({
        url: 'order/StatisticsReportSync',
        method: 'POST',
        data
    })
}

export function orderSmtPasteDashboardGet(data) {
    return service({
        url: 'order/SmtPasteDashboardGet',
        method: 'POST',
        data
    })
}

export function orderSmtPasteNextOrder(data) {
    return service({
        url: 'order/SmtPasteNextOrder',
        method: 'POST',
        data
    })
}

export function orderSmtPasteRevertOrder(data) {
    return service({
        url: 'order/SmtPasteRevertOrder',
        method: 'POST',
        data
    })
}

export function orderSmtPasteInsertOrder(data) {
    return service({
        url: 'order/SmtPasteInsertOrder',
        method: 'POST',
        data
    })
}

export function orderSmtPasteOperatorGet(data) {
    return service({
        url: 'order/SmtPasteOperatorGet',
        method: 'POST',
        data
    })
}

export function orderSmtPasteOperatorBindWorkShop(data) {
    return service({
        url: 'order/SmtPasteOperatorBindWorkShop',
        method: 'POST',
        data
    })
}

export function orderSmtPasteOperatorCancelBindWorkShop(data) {
    return service({
        url: 'order/SmtPasteOperatorCancelBindWorkShop',
        method: 'POST',
        data
    })
}

export function orderCheckShareClientDashboard(data) {
    return service({
        url: 'order/CheckShareClientDashboard',
        method: 'POST',
        data
    })
}

export function orderListShareClientDashboard(data) {
    return service({
        url: 'order/ListShareClientDashboard',
        method: 'POST',
        data
    })
}

export function orderSmtProgramGet(data) {
    return service({
        url: 'order/SmtProgramGet',
        method: 'POST',
        data
    })
}

export function ClientInitAccount(data) {
    return service({
        url: 'Client/InitAccount',
        method: 'POST',
        data
    })
}

export function orderPrepareMaterialCenterGetV2(data) {
    return service({
        url: 'order/PrepareMaterialCenterGetV2',
        method: 'POST',
        data
    })
}

export function orderPrepareReceiveBackFeeder(data) {
    return service({
        url: 'order/PrepareReceiveBackFeeder',
        method: 'POST',
        data
    })
}

export function orderPrepareMaterialPickUpOrderV2(data) {
    return service({
        url: 'order/PrepareMaterialPickUpOrderV2',
        method: 'POST',
        data
    })
}

export function orderPrepareMaterialPickUpOrderV3(data) {
    return service({
        url: 'order/PrepareMaterialPickUpOrderV3',
        method: 'POST',
        data
    })
}

export function orderPrepareMaterialReceive(data) {
    return service({
        url: 'order/PrepareMaterialReceive',
        method: 'POST',
        data
    })
}

export function orderPrepareMaterialReceiveScan(data) {
    return service({
        url: 'order/PrepareMaterialReceiveScan',
        method: 'POST',
        data
    })
}

export function orderPrepareMaterialPlanOverdueSendMessage(data) {
    return service({
        url: 'order/PrepareMaterialPlanOverdueSendMessage',
        method: 'POST',
        data
    })
}

export function orderPrepareMaterialPlanOverdueEnter(data) {
    return service({
        url: 'order/PrepareMaterialPlanOverdueEnter',
        method: 'POST',
        data
    })
}

export function orderPrepareMaterialDone(data) {
    return service({
        url: 'Order/PrepareMaterialDone',
        method: 'POST',
        data
    })
}

export function orderSmtPasteChangeOperatorGet(data) {
    return service({
        url: 'order/SmtPasteChangeOperatorGet',
        method: 'POST',
        data
    })
}

export function orderSmtPasteChangeOperatorSendMessage(data) {
    return service({
        url: 'order/SmtPasteChangeOperatorSendMessage',
        method: 'POST',
        data
    })
}

export function orderSmtPasteChangeOperator(data) {
    return service({
        url: 'order/SmtPasteChangeOperator',
        method: 'POST',
        data
    })
}

export function HumanRegister(data) {
    return service({
        url: 'Human/Register',
        method: 'POST',
        data
    })
}

export function OrderFileUpload(data) {
    return uploadFile({
        url:  'order/FileUpload',
        method: 'POST',
        data
    })
}

export function fileUpload(data) {
    return uploadFile({
        url: 'File/Upload',
        method: 'POST',
        data
    })
}

function uploadFile(api) {
    let param = new FormData()
    if (api.data) {
        for (let property in api.data) {
            console.log(api.data[property])
            param.append(property, api.data[property])
        }
    }
    return service({
        url: api.url,
        method: api.method,
        headers: { 'Content-Type': 'multipart/form-data' },
        data: param
    })
}
/**
 * 更新工艺段
 * @param {*} data 
 * @returns 
 */
export function updateWorkStageApi(data){
    return service({
        url: 'order/MaterialWorkStageChange',
        method: 'POST',
        data
    })
}

/**
 * 更新ErpCODE
 * @param {*} data 
 * @returns 
 */
export function updateClientErpCodeApi(data){
    return service({
        url: 'order/MaterialClientErpCodeUpdate',
        method: 'POST',
        data
    })
}

export function factoryConfigGet(data){
    return service({
        url: 'factory/ConfigGet',
        method: 'POST',
        data
    })
}

export function factoryConfigUpdate(data){
    return service({
        url: 'factory/ConfigUpdate',
        method: 'POST',
        data
    })
}

export function AuthorizedHumanUpdate(data){
    return service({
        url: 'AuthorizedHuman/Update',
        method: 'POST',
        data
    })
}

export function AuthorizedHumanDel(data){
    return service({
        url: 'AuthorizedHuman/Del',
        method: 'POST',
        data
    })
}

export function AuthorizedHumanAdd(data){
    return service({
        url: 'AuthorizedHuman/Add',
        method: 'POST',
        data
    })
}

export function AuthorizedHumanGet(data){
    return service({
        url: 'AuthorizedHuman/Get',
        method: 'POST',
        data
    })
}

export function SteelNetFixtureAdd(data){
    return service({
        url: 'SteelNetFixture/Add',
        method: 'POST',
        data
    })
}


const DIRECT_USE_RECOMMEND_REPLACE_MATERIAL = {
  code: '使用推荐的替代料',
  displayTxt: '使用推荐方式处理',
  isDirect: true,
  isImmediatelyPublish: true
}

const DIRECT_USE_V2 = {
  code: '可以使用（直接使用）',
  displayTxt: '强制使用原物料',
  message: '焊盘封装与物料封装不符，容易产生空焊、虚焊等质量，需客户自行返修！！！',
  isDirect: true,
  recommends: [
    { label: DIRECT_USE_RECOMMEND_REPLACE_MATERIAL.code, code: DIRECT_USE_RECOMMEND_REPLACE_MATERIAL },
  ],
  isImmediatelyPublish: true,
}

const NC_PACK_DESIGNATOR_ERROR = {
  code: '空贴焊盘封装不符位置',
  refs: 'ncPackDesignatorErrorDialog'
}

const CHANGE_MATERIAL = {
  code: '更换物料',
  refs: 'changeMaterialDialog'
}

const NC_PART_DESIGNATOR = {
  code: '空贴部分位号',
  refs: 'emptyMaterialDialog'
}

const USE_FACTORY_MATERIAL = {
  code: '厂供料替代',
  refs: 'choiceFactoryMaterialDialog',
  isImmediatelyPublish: false
}

const NC_MATERIAL = {
  code: '空贴物料',
  isDirect: true,
  isImmediatelyPublish: true
}

const NONE_POLARITY = {
  code: '无极性',
  isDirect: true,
  isImmediatelyPublish: true
}

const Feed = {
  code: '客户补料',
  isDirect: true,
  isImmediatelyPublish: true
}

const DIRECT_USE = {
  code: '可以使用（直接使用）',
  message: '使用与BOM或焊盘不符的物料，产生的质量问题（空焊等），需客户自行返修！！！',
  isDirect: true,
  recommends: [
    { label: '补料', code: Feed },
    { label: '厂供料替代', code: USE_FACTORY_MATERIAL, },
  ],
  isImmediatelyPublish: true,
  // publishCheckMessage: '是否更新物料描述为，客户实发物料描述？',
  // publishOkRefs: 'changeMaterialDialog',
}

const OTHER_PROCESS = {
  code: '其它',
  isDirect: true,
  isImmediatelyPublish: true
}

const Correct = {
  code: '正确',
  isDirect: true,
  isImmediatelyPublish: true
}

const Not_Correct = {
  code: '不正确(需反180°)',
  isDirect: true,
  isImmediatelyPublish: true
}

const SPECIAL_MATERIAL_POLARITY = {
  code: '指定物料极性',
  refs: 'specialPolarityComponent',
}

const SPECIAL_MATERIAL_PAD_POLARITY = {
  code: '指定物料焊盘极性',
  refs: 'specialPolarityComponent',
}


export default {

  STATE_WAIT: { code: 'WAIT' },
  STATE_OK: { code: 'OK' },
  STATE_NG: { code: 'NG' },
  STATE_WAIT_CHECK: { code: '待工厂确认' },
  STATE_WAIT_QC_CHECK: { code: '待QC检' },
  STATE_WAIT_PUBLISH: { code: '待发布' },
  STATE_PUBLISHED: { code: '已发布' },
  STATE_IGNORE: { code: '忽略' },

  Test_Item_Type_Action: { code: '动作' },

  TEST_TARGET_MATERIAL: { code: '物料' },
  TEST_TARGET_ORDER: { code: '订单' },
  TEST_TARGET_DESIGNATOR: { code: '位号' },

  RESULT_TYPE_RECORD: { code: '仅记录' },
  RESULT_TYPE_CR_REPLACE_MATERIAL: { code: 'CR替代料' },
  RESULT_TYPE_CR_NC: { code: 'CR空贴' },

  TEST_TYPE_CLIENT_ERROR_MATERIAL: { code: '物料与BM需求不符' },
  TEST_TYPE_SPECIAL_PROCESS: { code: '特殊工艺问题' },
  TEST_TYPE_DESIGNATOR_PACK: { code: '位置封装问题' },
  TEST_TYPE_MATERIAL_PACK: { code: '物料封装问题' },

  SpecialMarkCodeGroup: { code: '特殊标记组' },
  SpecialMarkCode: { code: '特殊标记' },
  Source_Test_Item: { code: '检查项' },
  Source_Question: { code: '问题' },
  Source_Test_Item_Result: { code: '检查项结果' },

  Target_System_Match: { code: '系统匹配' },
  Target_Extent_Attribute: { code: '扩展属性' },
  Target_Form_Item: { code: '表单项' },
  Target_Rule_Group: { code: '匹配规则组' },
  Target_Test_Item: { code: '检查项' },
  Target_Mark_Code: { code: '特殊标记' },
  Target_Question: { code: '问题' },
  Target_Material: { code: '物料' },
  Target_PCB: { code: 'PCB' },
  TARGET_STEEL_NET: { code: '钢网' },
  TARGET_FIXTURE: { code: '治具' },
  TARGET_COORDINATE: { code: '坐标' },

  WorkStage: { code: '工艺段' },
  MaterialCategory: { code: '资材类别ID' },
  MaterialPackage: { code: '封装ID' },
  RegularExpression: { code: '正则表达式' },

  ProcessPort_WeldForm: { code: '插件预成型' },
  ProcessPort_WeldCraft: { code: '插件工艺' },

  QUESTION_PROCESS_TYPE: {
    DIRECT_USE,
    USE_FACTORY_MATERIAL,
    SPECIAL_MATERIAL_POLARITY,
    SPECIAL_MATERIAL_PAD_POLARITY,
    NC_MATERIAL,
    NC_PART_DESIGNATOR,
    Correct,
    Not_Correct,
    NONE_POLARITY,
    Feed,
    CHANGE_MATERIAL,
    OTHER_PROCESS,
    NC_PACK_DESIGNATOR_ERROR,
    DIRECT_USE_RECOMMEND_REPLACE_MATERIAL,
    DIRECT_USE_V2,
  }
}

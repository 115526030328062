<template>
    <div class="BSearcher">
        <!-- 遍历搜索项目 -->
        <div class="searcherItem" v-for="(item, itemIndex) of searcher.items" :key="itemIndex">
            <div class="searcherLabel normalBtn">{{item.label}}：</div>
            <div class="searcherInput">
                <el-input v-if="item.type === ElementType().INPUT"
                          v-model="searchData[item.prop]"
                          :placeholder="item.placeholder || ('请输入' + item.label + '...')"
                          @keydown.enter.native.stop="search"
                          size="mini" clearable></el-input>
                <el-select v-if="item.type === ElementType().Select"
                           :multiple="item.multiple||false"
                           :filterable="item.filterable||false"
                           v-model="searchData[item.prop]"
                           :placeholder="item.placeholder || ('请选择' + item.label + '...')"
                           @keydown.enter.native.stop="search"
                           @change="change(item)"
                           :disabled="item.disabled||false"
                           size="mini" clearable>
                    <el-option v-for="(option, optionIndex) of item.options" :key="optionIndex" :value="option.value" :label="option.label" v-html="option.label"></el-option>
                </el-select>
                <el-date-picker
                        v-if="item.type === ElementType().DateTime"
                        v-model="searchData[item.prop]"
                        clearable
                        align="right" type="date" size="mini"
                        style="width: 175px;"
                        :placeholder="'请选择' + item.label + '...'">
                </el-date-picker>
                <div v-if="item.type === ElementType().DateTimeRange">
                    <el-date-picker
                            v-model="searchData[item.prop + 'Start']"
                            clearable
                            align="right" type="date" size="mini"
                            style="width: 175px;"
                            :placeholder="'请选择开始时间...'">
                    </el-date-picker>
                    ~
                    <el-date-picker
                            v-model="searchData[item.prop + 'End']"
                            clearable
                            align="right" type="date" size="mini"
                            style="width: 175px;"
                            :placeholder="'请选择结束时间...'">
                    </el-date-picker>
                </div>
            </div>
        </div>
        <div class="searcherItem">
            <el-button-group>
                <el-button type="primary" size="mini" icon="el-icon-search" @click="search">查询</el-button>
                <el-button v-if="!searcher.clearDisable" type="info" size="mini" icon="el-icon-delete-solid" @click="clear">清空</el-button>
            </el-button-group>
        </div>
    </div>
</template>

<script>
    import AbsComponent from "@/components/genericComponents/AbsComponent";
    import { ElementType } from "@/components/genericComponents/enum/BElementEnum";

    export default {
        name: "BSearcher",
        extends: AbsComponent,
        props: {
            searcher: {
                type: Object,
                default: () => {
                    return {
                        items: []
                    }
                }
            },
            defaultData: {
                type: Object,
                default: () => {
                    return null
                }
            }
        },
        data() {
            return {
                searchData: {}
            }
        },
        mounted() {
            this.init()
        },
        methods: {
            /*
             * 功能：初始化
             * 作者：潘溢林
             * 日期：23/02/24 10:55
             */
            init() {
                if (!this.defaultData) return
                this.searchData = this.defaultData
                this.$forceUpdate()
            },
            change(item) {
                if (item.change) item.change()
                if (item.callSearch) this.search()
            },
            /*
             * 功能：搜索
             * Author：潘溢林
             */
            search() {
                this.$emit('search', this.searchData)
            },
            /*
             * 功能：清空
             * Author：潘溢林
             */
            clear() {
                for (let key in this.searchData) {
                    this.searchData[key] = null
                }

                this.search()
            },
            /*
             * 功能：元素类型
             * Author：潘溢林
             */
            ElementType() {
                return ElementType
            }
        },
        watch: {
            searchData: function() {
                this.$forceUpdate()
            },
            defaultData: function() {
                this.init()
            },
        }
    }
</script>

<style scoped>
    .BSearcher {
        /* margin-bottom: 10px; */
    }

    .searcherItem {
        display: inline-block;
        margin-right: 10px;
        margin-bottom: 10px;
    }

    .searcherLabel {
        font-size: 15px;
        display: inline-block;
    }

    .searcherInput {
        display: inline-block;
    }
</style>

<template>
    <div class="BTableContainer">
        <el-table ref="BTable"
                  :data="argument.dataSource"
                  :border="argument.border !== false"
                  :stripe="argument.stripe !== false"
                  :size="argument.size || 'mini'"
                  :span-method="argument.spanMethod"
                  :empty-text="argument.emptyText"
                  v-loading="argument.loading"
                  :height="argument.height"
                  @selection-change="multipleSelectChange"
                  :style="getTableStyle()"
                  :row-class-name="argument.rowClassName"
                  :row-style="argument.rowStyle"
                  :cell-style="argument.cellStyle"
                  cell-class-name="cellClass"
                  :header-row-style="{ 'background-color': 'red' }"
                  :highlight-current-row="argument.highlightCurrentRow !== false">
            <el-table-column
                    v-if="argument.isMultipleCheck"
                    :fixed="argument.selectColumnFixed !== false"
                    :selectable="selectable"
                    type="selection"
                    width="40">
            </el-table-column>
            <el-table-column
                    v-for="(item, index) in argument.columns"
                    :key="index"
                    :prop="item.prop"
                    :label="item.label"
                    :min-width="item.minWidth"
                    :align="item.align ? item.align : 'left'"
                    :show-overflow-tooltip="item.showOverflowTooltip !== false && item.customizeShowOverflowTooltip !== true"
                    :header-align="item.headerAlign ? item.headerAlign : 'center'"
                    :width="item.width">
                <template slot-scope="scope">
                    <!-- 序号 -->
                    <span v-if="item.type === elementType().Sequence"> {{ scope.$index + 1 }} </span>
                    <!-- 序号 -->
                    <span v-if="item.type === elementType().DateTime"> {{ formatterTime(scope.row[item.prop]) }} </span>
                    <!-- 输入框 -->
                    <span v-if="item.type === elementType().INPUT">
                        <el-input v-model="scope.row[item.prop]" :placeholder="item.label" size="mini"
                                  @change="item.change({ row: scope.row })"/>
                    </span>
                    <!-- 数字框 -->
                    <span v-if="item.type === elementType().Number">
                        <el-input-number style="width: 100%;" v-model="scope.row[item.prop]"
                                         :placeholder="item.label" size="mini" :controls="false"
                                         @change="item.change({ row: scope.row })"/>
                    </span>
                    <!-- 按钮 -->
                    <span v-if="item.type === elementType().Button">
                        <el-button :type="item.btnType || 'primary'" :plain="item.btnPlain || true" :size="item.btnSize || 'mini'"
                                   :icon="item.btnIcon" @click="item.click(scope)">
                            <span v-html="typeof item.btnText === 'function' ? item.btnText(scope) : item.btnText"></span>
                        </el-button>
                    </span>
                    <!-- 图片 -->
                    <span v-if="item.type === elementType().NewUploadImage">
                        <b-image-group :images="scope.row[item.prop]" label-show/>
                    </span>
                    <!-- 渲染 -->
                    <span v-if="!item.type || item.type === elementType().RENDER">
                    <el-tooltip v-if="item.customizeShowOverflowTooltip"
                              class="item"
                              effect="dark"
                              placement="top-start">
                        <pre slot="content" v-html="item.formatter ? item.formatter({ value: scope.row[item.prop], index: scope.$index, row: scope.row }) : scope.row[item.prop]"></pre>
                        <span class="pan_ignore" v-html="item.formatter ? item.formatter({ value: scope.row[item.prop], index: scope.$index, row: scope.row }) : scope.row[item.prop]"></span>
                    </el-tooltip>
                  <span v-else v-html="item.formatter ? item.formatter({ value: scope.row[item.prop], index: scope.$index, row: scope.row }) : scope.row[item.prop]"
                        :class="item.class" @click="item.click ? item.click(scope) : null"></span>
                </span>
                </template>
            </el-table-column>
            <el-table-column
                    v-if="argument.footerOperation && !isEmptyList(argument.footerOperation.buttons)"
                    :label="argument.footerOperation.label || '操作'"
                    :min-width="argument.footerOperation.minWidth"
                    :align="argument.footerOperation.align ? argument.footerOperation.align : 'left'"
                    :show-overflow-tooltip="argument.footerOperation.showOverflowTooltip !== false && argument.footerOperation.customizeShowOverflowTooltip !== true"
                    :header-align="argument.footerOperation.headerAlign ? argument.footerOperation.headerAlign : 'center'"
                    :width="argument.footerOperation.width || '120px'"
                    :fixed="argument.footerOperation.fixed">
                <template slot-scope="scope">
                    <!-- 如果仅有一个按钮 -->
                    <div v-if="argument.footerOperation.buttons.length === 1">
                        <el-button :type="argument.footerOperation.buttons[0].type || 'primary'"
                                   :plain="argument.footerOperation.buttons[0].plain || true"
                                   :size="argument.footerOperation.buttons[0].size || 'mini'"
                                   :icon="argument.footerOperation.buttons[0].icon"
                                   :disabled="typeof argument.footerOperation.buttons[0].disabled === 'function' ? argument.footerOperation.buttons[0].disabled(scope) : argument.footerOperation.buttons[0].disabled === true"
                                   @click="clickBtn(scope, argument.footerOperation.buttons[0])">
                            <span v-html="argument.footerOperation.buttons[0].txt"></span>
                        </el-button>
                    </div>
                    <div v-else>
                        <el-dropdown>
                            <el-button type="primary" size="mini">
                                更多操作 <i class="el-icon-arrow-down el-icon--right"></i>
                            </el-button>
                            <el-dropdown-menu slot="dropdown">
                                <el-dropdown-item v-for="(button, buttonIndex) of argument.footerOperation.buttons"
                                                  :key="buttonIndex"
                                                  :disabled="typeof button.disabled === 'function' ? button.disabled(scope) : button.disabled === true"
                                                  @click.native="clickBtn(scope, button)">
                                    <div :class="button.class">
                                        <i :class="button.icon"></i>
                                        <span v-html="button.txt"></span>
                                    </div>
                                </el-dropdown-item>
                            </el-dropdown-menu>
                        </el-dropdown>
                    </div>
                </template>
            </el-table-column>
        </el-table>

        <div v-if="argument.remoteArgument && argument.remoteArgument.isSplitPage" class="BTablePagination">
            <el-pagination
                    background
                    @current-change="handleCurrentChange"
                    layout="total, prev, pager, next, jumper"
                    :page-size="argument.remoteArgument.pagesize || 15"
                    :current-page="argument.remoteArgument.voObject ? argument.remoteArgument.voObject.pageNum : 1"
                    :total="argument.dataTotal">
            </el-pagination>
        </div>

        <div style="clear: both;"></div>
    </div>
</template>

<script>
    import { ElementType } from "@/components/genericComponents/enum/BElementEnum";
    import AbsComponent from "@/components/genericComponents/AbsComponent";
    import BImageGroup from "@/components/genericComponents/element/BImageGroup";

    export default {
        name: "BTable",
        extends: AbsComponent,
        components: {BImageGroup},
        props: {
            argument: {
                type: [Object],
                default: () => {
                    return {
                        dataTotal: null,
                        dataSource: [],
                        columns: [],
                        stripe: true,
                        border: true,
                        loading: false,
                        spanMethod: null,
                        footerOperation: {
                            buttons: []
                        },
                        remoteArgument: {
                            isSplitPage: false,
                            api: null,
                            voObject: { },
                            refresh: 0,
                            getMessage: null,
                            invokeSuccess: (response) => {},
                            invokeError: (response) => {},
                        },
                    }
                }
            }
        },
        created() {
            /* 刷新 */
            this.refresh()
            console.log("this.argument.columns")
            console.log(this.argument.columns)
        },
        methods: {
            /*
             * 功能：刷新
             * Author：潘溢林
             */
            refresh() {
                this.invokeGetApi()
            },
            /*
             * 功能：调用获取数据APi
             * Author：潘溢林
             */
            invokeGetApi() {
                if (!this.argument.remoteArgument || !this.argument.remoteArgument.api) return

                /* 获取数据 */
                this.loadingStart({ message: this.argument.remoteArgument.getMessage || '获取数据中...' })
                return this.argument.remoteArgument.api(this.argument.remoteArgument.voObject).then(res => {
                    /* 获取数据成功 */
                    if (this.argument.remoteArgument.invokeSuccess) return this.argument.remoteArgument.invokeSuccess(res)
                    /* 默认数据成功处理 */
                    if (res.totalCount !== undefined || res.total !== undefined) {
                        this.argument.dataSource = res.data
                        this.argument.dataTotal = res.totalCount || res.total
                    } else {
                        this.argument.dataSource = res
                    }
                    // 调用数据获取回调事件
                    if (this.argument.remoteArgument.invokeSuccessCallback) return this.argument.remoteArgument.invokeSuccessCallback(res)
                }).catch(res => {
                    if (!this.argument.remoteArgument.invokeError) return
                    return this.argument.remoteArgument.invokeError(res)
                })
            },
            /*
             * 功能：是否可选
             * Author：潘溢林
             */
            multipleSelectChange(data) {
                if (this.argument.multipleSelectChange && typeof this.argument.multipleSelectChange === 'function')
                    return this.argument.multipleSelectChange(data)
            },
            /*
             * 功能：获取表格的样式
             * Author：潘溢林
             */
            getTableStyle() {
                return {
                    width: this.argument.width || '100%'
                }
            },
            /*
             * 功能：是否可选
             * Author：潘溢林
             */
            selectable(data) {
                if (this.argument.selectable && typeof this.argument.selectable === 'function')
                    return this.argument.selectable(data)
                return true
            },
            /*
             * 功能：元素类型
             * Author：潘溢林
             */
            elementType() {
                return ElementType
            },
            /*
             * 功能：点击按钮
             * Author：潘溢林
             */
            clickBtn(scope, btn) {
                if (btn.click) return btn.click(scope)
            },
            /*
             * 功能：监听当前页改变事件
             * Author：潘溢林
             */
            handleCurrentChange(page) {
                this.argument.remoteArgument.voObject.pageindex = page - 1
                this.argument.remoteArgument.voObject.pagesize = this.argument.remoteArgument.pagesize || 15
                this.argument.remoteArgument.voObject.pageNum = page
                this.argument.remoteArgument.voObject.pageSize = this.argument.remoteArgument.voObject.pagesize
                this.argument.remoteArgument.refresh++
            },
        },
        watch: {
            'argument.remoteArgument.refresh': function () {
                this.refresh()
            }
        }
    }
</script>

<style scoped>
    .BTablePagination {
        margin-top: 10px;
        float: right;
    }

    .BTablePagination:after {
        clear: both;
    }

</style>
